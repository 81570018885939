import IconEmail from '@apollo/icons/default/IconEmail.svg';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@apollo/orbit';
import React, { useState } from 'react';

import { ClickableText } from 'src/components/common/clickableText/ClickableText';
import { Form } from 'src/components/common/form/Form';
import { BackendRouter } from 'src/lib/routers';

interface Props {
  initialState?: Partial<State>;
  returnToUrl: string;
  setLoggingInSSOV2?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ValidationErrors {
  email?: string;
}

interface State {
  email: string;
  errors: ValidationErrors;
}

export const SSOPage = ({
  initialState,
  returnToUrl,
  setLoggingInSSOV2,
}: Props) => {
  const state = {
    email: '',
    errors: {},
    // This is so we can test state in storybook
    ...(initialState || {}),
  };
  const [loggingInSSO, setLoggingInSSO] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(state.email);
  const [errors, setErrors] = useState<ValidationErrors>(state.errors);

  const handleSignInSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    setLoggingInSSO(true);

    try {
      const { destination, errorMessage } = await doLogin(email, returnToUrl);
      if (destination) {
        redirect(destination);
        return;
      }
      // Otherwise, show the error message
      setErrors((oldErrors) => ({
        ...oldErrors,
        email: errorMessage || USER_FACING_ERROR_MESSAGES.UNEXPECTED_ERROR,
      }));
    } catch (e) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        email: USER_FACING_ERROR_MESSAGES.UNEXPECTED_ERROR,
      }));
    }

    setLoggingInSSO(false);
  };

  return (
    <Form
      onSubmit={handleSignInSubmit}
      noValidate
      className="flex flex-col gap-4"
    >
      <FormControl isInvalid={!!errors.email}>
        <FormLabel hidden>Email</FormLabel>
        <Input
          type="email"
          name="email"
          placeholder="Email address"
          value={email}
          className="w-full"
          onChange={(event) => {
            setEmail(event.target.value);
            setErrors((oldErrors) => ({
              ...oldErrors,
              email: undefined,
            }));
          }}
          leftElement={<IconEmail />}
        />
        <FormErrorMessage>{errors.email}</FormErrorMessage>
      </FormControl>
      <div>
        <Button
          type="submit"
          isDisabled={loggingInSSO}
          isLoading={loggingInSSO}
          size="lg"
          className="w-full"
          variant="primary"
        >
          Continue
        </Button>
      </div>
      <div className="text-center text-sm text-secondary">
        Not using SSO?{' '}
        <ClickableText
          className="text-link underline"
          onClick={() => {
            if (setLoggingInSSOV2) setLoggingInSSOV2(false);
          }}
        >
          Click here
        </ClickableText>
      </div>
    </Form>
  );
};

const USER_FACING_ERROR_MESSAGES = {
  TOO_MANY_CONNECTIONS: 'Please sign in through your identity provider.',
  INVALID_DOMAIN: `SSO is unavailable for this email. Please check the spelling and try again.`,
  UNEXPECTED_ERROR: 'An unexpected error occurred. Please try again.',
};

function toActionableErrorMessage(code: string) {
  const codeToMessage: Record<string, string> = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    too_many_connections: USER_FACING_ERROR_MESSAGES.TOO_MANY_CONNECTIONS,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    invalid_domain: USER_FACING_ERROR_MESSAGES.INVALID_DOMAIN,
    unexpected: USER_FACING_ERROR_MESSAGES.UNEXPECTED_ERROR,
  };

  return codeToMessage[code] || codeToMessage.unexpected;
}

type HandleLoginResponseResult = {
  destination?: string;
  errorMessage?: string;
};

export const doLogin = async (
  email: string,
  returnToUrl: string,
): Promise<HandleLoginResponseResult> => {
  const path = BackendRouter.path('SSOLogin', {
    email,
    returnToUrl,
  });

  const response = await fetch(path, {
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
      'sec-fetch-mode': 'cors',
    },
    method: 'GET',
    mode: 'cors',
  });

  if (response.ok) {
    // If we get back a success, we expect the SSO redirect URL to be returned in the response body
    const responseJson = await response.json();
    const destination = responseJson.redirectUrl;

    if (!destination) {
      return {
        errorMessage: USER_FACING_ERROR_MESSAGES.UNEXPECTED_ERROR,
      };
    }

    return {
      destination,
    };
  } else {
    // If the result is an error, we should show the error message
    // that is returned as json in the response body
    const responseJson = await response.json();
    const message = toActionableErrorMessage(responseJson.code);
    return {
      errorMessage: message,
    };
  }
};

const redirect = (destination: string) => {
  window.location.href = destination;
};
