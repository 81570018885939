import { defineStyle } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

const hoverAndFocusStyles = {
  outline: 'none',
  backgroundColor: customColors.btn['secondary-hover'],
};

const disabledStyles = {
  pointerEvents: 'none',
  color: customColors.text.disabled,
  backgroundColor: customColors.bg.disabled,
};

const activeStyles = {
  color: customColors.text.primary,
  backgroundColor: customColors.bg.neutral,
  fontWeight: 600,
};

export const MenuItemContainerStyles = defineStyle({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
});

export const MenuItemContentStyles = defineStyle({
  display: 'flex',
  alignItems: 'center',
});

export const MenuItemIconStyles = defineStyle({
  mr: 3,
  display: 'inline-flex',
  alignItems: 'center',
});

export const MenuItemChildrenTruncatedStyles = defineStyle({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

export const MenuItemRightIconStyles = defineStyle({
  ml: 'auto',
  display: 'inline-flex',
  alignItems: 'center',
});

export const MenuItemDescriptionStyles = defineStyle({
  fontSize: '0.875rem',
  marginLeft: '1.75rem',
  fontWeight: '400',
  color: customColors.text.secondary,
});

export const MenuItemStyles = defineStyle({
  borderRadius: '0.375rem',
  lineHeight: '1.5rem',
  px: 4,
  py: 1,
  fontWeight: 400,
  minHeight: '2.5rem',
  display: 'flex',
  alignItems: 'center',
  color: customColors.text.primary,
  backgroundColor: customColors.bg.primary,
  _disabled: disabledStyles,
  _focus: hoverAndFocusStyles,
  _active: {
    ...activeStyles,
    _hover: activeStyles,
  },
  _hover: {
    ...hoverAndFocusStyles,
    _active: activeStyles,
    _disabled: disabledStyles,
  },
});
