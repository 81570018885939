import { anatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

import type { StatusBadgeProps } from './StatusBadge';

const statusBadgeAnatomy = anatomy('statusBadge').parts('container', 'icon');

const { border, icon, text, bg, btn } = customColors;
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(statusBadgeAnatomy.keys);

type ColorScheme = Exclude<StatusBadgeProps['colorScheme'], undefined>;

const COLORS = {
  yellow: icon.change,
  gray: icon.secondary,
  green: icon.success,
  purple: icon.beta,
  blue: icon.info,
  red: icon.error,
  navy: icon.disabled,
} satisfies Record<ColorScheme, string>;

const baseStyle = definePartsStyle(({ colorScheme }) => ({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: 2,
    color: text.primary,
    fontFamily: 'body',
    rounded: '100px',
    h: 8,
    textWrap: 'nowrap',
    _groupHover: {
      bg: btn['secondary-hover'], // Hover background color
    },
  },
  icon: {
    display: 'inline-flex',
    placeItems: 'center',
    color: COLORS[colorScheme as ColorScheme],
    h: 4,
    minW: 4,
    '> svg': {
      display: 'block',
      width: 4,
      height: 4,
    },
  },
}));

const variants = {
  hidden: definePartsStyle({
    container: {
      py: 0,
      px: 0,
    },
  }),
  rounded: definePartsStyle({
    container: {
      border: '1px solid',
      borderColor: border.primary,
      backgroundColor: bg.primary,
      py: 1,
      px: 3,
    },
  }),
};

export const StatusBadgeStyles = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: 'rounded',
  },
});
