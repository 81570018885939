import {
  ApolloError,
  gql,
  makeVar,
  useApolloClient,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  Alert,
  Button,
  ButtonGroup,
  Code,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
} from '@apollo/orbit';
import React, { useEffect, useState } from 'react';

import { Form } from 'src/components/common/form/Form';
import { ampli } from 'src/lib/analytics/amplitude/vendor';
import { GraphQLTypes } from 'src/lib/graphqlTypes';

interface UserApiKeysModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CURRENT_USER_QUERY = gql`
  query GetCurrentUser {
    me {
      id
    }
  }
`;

export const API_KEY_FRAGMENT = gql`
  fragment ApiKeyFragment on UserApiKey {
    id
    token
    keyName
  }
`;

// Variables to track API key generation status and modal state
export const apiKeyGeneratedVar = makeVar(false);
export const apiKeyModalOpenVar = makeVar(false);

export const UserApiKeysModal = ({
  isOpen,
  onClose,
}: UserApiKeysModalProps) => {
  const client = useApolloClient();
  const [newKey, setNewKey] = useState<GraphQLTypes.UserApiKey>();
  const [error, setError] = useState<ApolloError | null>(null);

  // Update the modal open state when the isOpen prop changes
  useEffect(() => {
    apiKeyModalOpenVar(isOpen);
  }, [isOpen]);

  // Get the current user's ID
  const { data: userData, loading: userLoading } = useQuery<
    GraphQLTypes.GetCurrentUser,
    GraphQLTypes.GetCurrentUserVariables
  >(CURRENT_USER_QUERY);
  const userId = userData?.me?.id;

  const [addKeyMutation, { loading: mutationLoading }] = useMutation<
    GraphQLTypes.GenerateUserAPIKeyMutation,
    GraphQLTypes.GenerateUserAPIKeyMutationVariables
  >(
    gql`
      mutation GenerateUserAPIKey($userId: ID!, $keyName: String!) {
        user(id: $userId) {
          user {
            id
          }
          newKey(keyName: $keyName) {
            id
            ...ApiKeyFragment
          }
        }
      }
      ${API_KEY_FRAGMENT}
    `,
    {
      onCompleted: (data) => {
        client.cache.evict({
          id: client.cache.identify({
            __typename: data.user?.user.__typename,
            id: data.user?.user.id,
          }),
          fieldName: 'apiKeys',
        });
        setNewKey(data.user?.newKey);

        // Set the reactive variable to true to indicate success
        apiKeyGeneratedVar(true);
      },
      onError: (e) => setError(e),
    },
  );

  const handleClose = () => {
    // Analytics: Record closing of modal
    // This appears as the event `"Generate API Key" Closed` in Amplitude.
    // TODO: Merge this event with similar-named event in amplitude through a Transformation.
    // See https://apollographql.atlassian.net/browse/ONB-635
    ampli.modalClosed({
      ModalName: 'User API Key',
    });
    onClose();
    setNewKey(undefined);
    setError(null);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!userId) {
      setError(
        new ApolloError({ errorMessage: 'User information not available' }),
      );
      return;
    }

    const formData = new FormData(event.currentTarget);
    const keyName = formData.get('keyName');

    if (typeof keyName !== 'string') {
      setError(
        new ApolloError({
          errorMessage: 'Could not get `name` field from form',
        }),
      );
      return;
    }

    try {
      await addKeyMutation({
        variables: { userId, keyName },
      });
      ampli.userApiKeyGenerated();
    } catch (e) {
      // Error is handled in onError callback
    }
  };

  const loading = userLoading || mutationLoading;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <Form onSubmit={handleSubmit}>
        <ModalContent>
          <ModalHeader>
            <ModalTitle>New API key</ModalTitle>
          </ModalHeader>
          <ModalBody>
            {error && (
              <Alert status="error" className="mb-4">
                {error.message}
              </Alert>
            )}
            {newKey ? (
              <>
                <Alert status="info" className="mb-4">
                  Make sure to copy your API key now. You won't be able to see
                  it again.
                </Alert>
                <Code
                  canCopy
                  onClick={() => {
                    ampli.userApiKeyCopied();
                  }}
                  className="flex h-full justify-between rounded p-3"
                >
                  {newKey.token}
                </Code>
              </>
            ) : (
              <FormControl>
                <FormLabel>Key name</FormLabel>
                <Input name="keyName" />
              </FormControl>
            )}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              {newKey ? (
                <Button variant="primary" onClick={handleClose}>
                  Close
                </Button>
              ) : (
                <>
                  <Button
                    variant="secondary"
                    type="button"
                    isDisabled={loading}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    isLoading={loading}
                    loadingText="Creating"
                    type="submit"
                  >
                    Create
                  </Button>
                </>
              )}
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Form>
    </Modal>
  );
};
