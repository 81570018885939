/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull studio-ui'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 54
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/implementation/studio-ui)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'staging' | 'prod';

export const ApiKey: Record<Environment, string> = {
  staging: '18fda9ac22786af09a50e662103d4680',
  prod: '4affa0e46338ec483067c1c778e76e05'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '54',
    branch: 'main',
    source: 'studio-ui',
    versionId: '86cdf770-1d4c-4fd3-aa72-d5b378b9fbdb'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * The currently active org when this user is taking actions
   */
  "Active Org Id"?: string;
  /**
   * User's email address
   */
  Email?: string;
  /**
   * The date this user verified their email.
   */
  "Email Verified"?: boolean;
  /**
   * User's name
   */
  "Full Name"?: string;
}

export interface CancelConnectApiClickedProperties {
  /**
   * Defines the type of API that is being connected to a new supergraph during the onboarding process.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | GraphQL, REST |
   */
  "API Type": "GraphQL" | "REST";
  /**
   * To distinguish between different onboarding flows for testing purposes
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Serverless, GraphOS-Trial, Enterprise-Trial, Free, Other |
   */
  OnboardingVariant?: "Serverless" | "GraphOS-Trial" | "Enterprise-Trial" | "Free" | "Other";
}

export interface CancelClickedProperties {
  /**
   * Defines the type of API that is being connected to a new supergraph during the onboarding process.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | GraphQL, REST |
   */
  "API Type": "GraphQL" | "REST";
  /**
   * To distinguish between different onboarding flows for testing purposes
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Serverless, GraphOS-Trial, Enterprise-Trial, Free, Other |
   */
  OnboardingVariant?: "Serverless" | "GraphOS-Trial" | "Enterprise-Trial" | "Free" | "Other";
}

export interface ChangeLogL1ViewedProperties {
  /**
   * Is the application running in 'studio' or 'embedded' mode?
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | studio, embedded-sandbox, embedded-explorer, embedded |
   */
  "Application Mode": "studio" | "embedded-sandbox" | "embedded-explorer" | "embedded";
  graphRef?: string;
}

export interface ChecksL1ViewedProperties {
  /**
   * Is the application running in 'studio' or 'embedded' mode?
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | studio, embedded-sandbox, embedded-explorer, embedded |
   */
  "Application Mode": "studio" | "embedded-sandbox" | "embedded-explorer" | "embedded";
  /**
   * I have used a non-standard (for Amplitude) spelling and capitalization here because this is such a standard token in Studio, and easier to pass without reformatting.
   */
  graphRef: string;
}

export interface ClientsL1ViewedProperties {
  /**
   * Is the application running in 'studio' or 'embedded' mode?
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | studio, embedded-sandbox, embedded-explorer, embedded |
   */
  "Application Mode": "studio" | "embedded-sandbox" | "embedded-explorer" | "embedded";
  /**
   * I have used a non-standard (for Amplitude) spelling and capitalization here because this is such a standard token in Studio, and easier to pass without reformatting.
   */
  graphRef: string;
}

export interface CloseClickedProperties {
  /**
   * Defines the type of API that is being connected to a new supergraph during the onboarding process.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | GraphQL, REST |
   */
  "API Type": "GraphQL" | "REST";
  /**
   * To distinguish between different onboarding flows for testing purposes
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Serverless, GraphOS-Trial, Enterprise-Trial |
   */
  OnboardingVariant?: "Serverless" | "GraphOS-Trial" | "Enterprise-Trial";
}

export interface CloudRouterL1ViewedProperties {
  /**
   * Is the application running in 'studio' or 'embedded' mode?
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | studio, embedded-sandbox, embedded-explorer, embedded |
   */
  "Application Mode": "studio" | "embedded-sandbox" | "embedded-explorer" | "embedded";
  /**
   * I have used a non-standard (for Amplitude) spelling and capitalization here because this is such a standard token in Studio, and easier to pass without reformatting.
   */
  graphRef: string;
}

export interface ConnectApiClickedProperties {
  /**
   * Defines the type of API that is being connected to a new supergraph during the onboarding process.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | GraphQL, REST |
   */
  "API Type": "GraphQL" | "REST";
  /**
   * To distinguish between different onboarding flows for testing purposes
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Serverless, GraphOS-Trial, Enterprise-Trial, Free, Other |
   */
  OnboardingVariant?: "Serverless" | "GraphOS-Trial" | "Enterprise-Trial" | "Free" | "Other";
}

export interface ConnectorsQuickstartClickedProperties {
  /**
   * Defines the type of API that is being connected to a new supergraph during the onboarding process.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | GraphQL, REST |
   */
  "API Type": "GraphQL" | "REST";
  /**
   * To distinguish between different onboarding flows for testing purposes
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Serverless, GraphOS-Trial, Enterprise-Trial, Free, Other |
   */
  OnboardingVariant?: "Serverless" | "GraphOS-Trial" | "Enterprise-Trial" | "Free" | "Other";
}

export interface CreateDemoGraphClickedProperties {
  /**
   * To distinguish between different onboarding flows for testing purposes
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Serverless, GraphOS-Trial, Enterprise-Trial, Free, Other |
   */
  OnboardingVariant?: "Serverless" | "GraphOS-Trial" | "Enterprise-Trial" | "Free" | "Other";
}

export interface CreateFirstGraphClickedProperties {
  /**
   * To distinguish between different onboarding flows for testing purposes
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Serverless, GraphOS-Trial, Enterprise-Trial, Free, Other |
   */
  OnboardingVariant?: "Serverless" | "GraphOS-Trial" | "Enterprise-Trial" | "Free" | "Other";
}

export interface CreateGraphClickedProperties {
  /**
   * Defines the type of API that is being connected to a new supergraph during the onboarding process.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | GraphQL, REST |
   */
  "API Type": "GraphQL" | "REST";
  /**
   * To distinguish between different onboarding flows for testing purposes
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Serverless, GraphOS-Trial, Enterprise-Trial, Free, Other |
   */
  OnboardingVariant?: "Serverless" | "GraphOS-Trial" | "Enterprise-Trial" | "Free" | "Other";
}

export interface CreatingGraphClickedProperties {
  /**
   * Defines the type of API that is being connected to a new supergraph during the onboarding process.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | GraphQL, REST |
   */
  "API Type": "GraphQL" | "REST";
  /**
   * To distinguish between different onboarding flows for testing purposes
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Serverless, GraphOS-Trial, Enterprise-Trial, Free, Other |
   */
  OnboardingVariant?: "Serverless" | "GraphOS-Trial" | "Enterprise-Trial" | "Free" | "Other";
}

export interface ExplorerOperationSubmittedProperties {
  /**
   * Is the application running in 'studio' or 'embedded' mode?
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | studio, embedded-sandbox, embedded-explorer, embedded |
   */
  "Application Mode": "studio" | "embedded-sandbox" | "embedded-explorer" | "embedded";
  /**
   * The graph ID on which the event occurred
   */
  "Graph ID"?: string;
  "Graph Operation"?: string;
  "Graph Variant"?: string;
}

export interface ExplorerViewedProperties {
  /**
   * Is the application running in 'studio' or 'embedded' mode?
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | studio, embedded-sandbox, embedded-explorer, embedded |
   */
  "Application Mode": "studio" | "embedded-sandbox" | "embedded-explorer" | "embedded";
}

export interface FinishLaterClickedProperties {
  /**
   * Defines the type of API that is being connected to a new supergraph during the onboarding process.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | GraphQL, REST |
   */
  "API Type": "GraphQL" | "REST";
  /**
   * To distinguish between different onboarding flows for testing purposes
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Serverless, GraphOS-Trial, Enterprise-Trial, Free, Other |
   */
  OnboardingVariant?: "Serverless" | "GraphOS-Trial" | "Enterprise-Trial" | "Free" | "Other";
}

export interface GoToGraphClickedProperties {
  /**
   * Defines the type of API that is being connected to a new supergraph during the onboarding process.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | GraphQL, REST |
   */
  "API Type": "GraphQL" | "REST";
  /**
   * To distinguish between different onboarding flows for testing purposes
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Serverless, GraphOS-Trial, Enterprise-Trial, Free, Other |
   */
  OnboardingVariant?: "Serverless" | "GraphOS-Trial" | "Enterprise-Trial" | "Free" | "Other";
}

export interface GoToStudioCtaProperties {
  /**
   * User email
   */
  Email?: string;
}

export interface GraphSettingsL1ViewedProperties {
  /**
   * Is the application running in 'studio' or 'embedded' mode?
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | studio, embedded-sandbox, embedded-explorer, embedded |
   */
  "Application Mode": "studio" | "embedded-sandbox" | "embedded-explorer" | "embedded";
  /**
   * I have used a non-standard (for Amplitude) spelling and capitalization here because this is such a standard token in Studio, and easier to pass without reformatting.
   */
  graphRef: string;
}

export interface LaunchesL1ViewedProperties {
  /**
   * Is the application running in 'studio' or 'embedded' mode?
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | studio, embedded-sandbox, embedded-explorer, embedded |
   */
  "Application Mode": "studio" | "embedded-sandbox" | "embedded-explorer" | "embedded";
  /**
   * I have used a non-standard (for Amplitude) spelling and capitalization here because this is such a standard token in Studio, and easier to pass without reformatting.
   */
  graphRef: string;
}

export interface OnboardingZeroStateVisitedProperties {
  /**
   * To distinguish between different onboarding flows for testing purposes
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Serverless, GraphOS-Trial, Enterprise-Trial, Free, Other |
   */
  OnboardingVariant?: "Serverless" | "GraphOS-Trial" | "Enterprise-Trial" | "Free" | "Other";
}

export interface OperationsL1ViewedProperties {
  /**
   * Is the application running in 'studio' or 'embedded' mode?
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | studio, embedded-sandbox, embedded-explorer, embedded |
   */
  "Application Mode": "studio" | "embedded-sandbox" | "embedded-explorer" | "embedded";
  /**
   * I have used a non-standard (for Amplitude) spelling and capitalization here because this is such a standard token in Studio, and easier to pass without reformatting.
   */
  graphRef: string;
}

export interface PricingDetailsClickedProperties {
  /**
   * To distinguish between different onboarding flows for testing purposes
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Serverless, GraphOS-Trial, Enterprise-Trial, Free, Other |
   */
  OnboardingVariant?: "Serverless" | "GraphOS-Trial" | "Enterprise-Trial" | "Free" | "Other";
}

export interface ProceedToPublishSchemaClickedProperties {
  /**
   * Defines the type of API that is being connected to a new supergraph during the onboarding process.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | GraphQL, REST |
   */
  "API Type": "GraphQL" | "REST";
  /**
   * To distinguish between different onboarding flows for testing purposes
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Serverless, GraphOS-Trial, Enterprise-Trial, Free, Other |
   */
  OnboardingVariant?: "Serverless" | "GraphOS-Trial" | "Enterprise-Trial" | "Free" | "Other";
}

export interface SchemaL1ViewedProperties {
  /**
   * Is the application running in 'studio' or 'embedded' mode?
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | studio, embedded-sandbox, embedded-explorer, embedded |
   */
  "Application Mode": "studio" | "embedded-sandbox" | "embedded-explorer" | "embedded";
  /**
   * I have used a non-standard (for Amplitude) spelling and capitalization here because this is such a standard token in Studio, and easier to pass without reformatting.
   */
  graphRef: string;
}

export interface SchemaPublishedProperties {
  /**
   * Defines the type of API that is being connected to a new supergraph during the onboarding process.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | GraphQL, REST |
   */
  "API Type": "GraphQL" | "REST";
  /**
   * To distinguish between different onboarding flows for testing purposes
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Serverless, GraphOS-Trial, Enterprise-Trial, Free, Other |
   */
  OnboardingVariant?: "Serverless" | "GraphOS-Trial" | "Enterprise-Trial" | "Free" | "Other";
}

export interface SeeSchemaClickedProperties {
  /**
   * Defines the type of API that is being connected to a new supergraph during the onboarding process.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | GraphQL, REST |
   */
  "API Type": "GraphQL" | "REST";
  /**
   * To distinguish between different onboarding flows for testing purposes
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Serverless, GraphOS-Trial, Enterprise-Trial, Free, Other |
   */
  OnboardingVariant?: "Serverless" | "GraphOS-Trial" | "Enterprise-Trial" | "Free" | "Other";
}

export interface SettingsL1ViewedProperties {
  /**
   * Is the application running in 'studio' or 'embedded' mode?
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | studio, embedded-sandbox, embedded-explorer, embedded |
   */
  "Application Mode": "studio" | "embedded-sandbox" | "embedded-explorer" | "embedded";
  /**
   * I have used a non-standard (for Amplitude) spelling and capitalization here because this is such a standard token in Studio, and easier to pass without reformatting.
   */
  graphRef: string;
}

export interface SubgraphsL1ViewedProperties {
  /**
   * Is the application running in 'studio' or 'embedded' mode?
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | studio, embedded-sandbox, embedded-explorer, embedded |
   */
  "Application Mode": "studio" | "embedded-sandbox" | "embedded-explorer" | "embedded";
  /**
   * I have used a non-standard (for Amplitude) spelling and capitalization here because this is such a standard token in Studio, and easier to pass without reformatting.
   */
  graphRef: string;
}

export interface VariantsHomeL1ViewedProperties {
  /**
   * Is the application running in 'studio' or 'embedded' mode?
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | studio, embedded-sandbox, embedded-explorer, embedded |
   */
  "Application Mode": "studio" | "embedded-sandbox" | "embedded-explorer" | "embedded";
  /**
   * I have used a non-standard (for Amplitude) spelling and capitalization here because this is such a standard token in Studio, and easier to pass without reformatting.
   */
  graphRef: string;
}

export interface ZeroStateVisitedProperties {
  /**
   * To distinguish between different onboarding flows for testing purposes
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Serverless, GraphOS-Trial, Enterprise-Trial, Free, Other |
   */
  OnboardingVariant?: "Serverless" | "GraphOS-Trial" | "Enterprise-Trial" | "Free" | "Other";
}

export interface ClickedButtonProperties {
  /**
   * LEGACY: This optional string field added for back compat with our GA tracking methods.
   *
   *
   * It's preferable to add a new ENUM value to Product Context, for categorizing links, or creating all new events with a proper amplitude category.
   */
  Category?: string;
  /**
   * A friendly description for this link's intention.
   */
  Description: string;
  /**
   * The new URL a Link is directing to.
   */
  "New URL"?: string;
  "Previous URL"?: string;
  /**
   * A rough category/area of the product this event occured in. This and be useful when classifying  'General Tracking' events into specific product areas.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | New Org/User Onboarding, Graph Onboarding V1 |
   */
  "Product Context"?: "New Org/User Onboarding" | "Graph Onboarding V1";
  /**
   * Link target, if set
   */
  Target?: string;
}

export interface ClickedLinkProperties {
  /**
   * LEGACY: This optional string field added for back compat with our GA tracking methods.
   *
   *
   * It's preferable to add a new ENUM value to Product Context, for categorizing links, or creating all new events with a proper amplitude category.
   */
  Category?: string;
  /**
   * A friendly description for this link's intention.
   */
  Description: string;
  /**
   * The new URL a Link is directing to.
   */
  "New URL"?: string;
  "Previous URL"?: string;
  /**
   * A rough category/area of the product this event occured in. This and be useful when classifying  'General Tracking' events into specific product areas.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | New Org/User Onboarding, Graph Onboarding V1 |
   */
  "Product Context"?: "New Org/User Onboarding" | "Graph Onboarding V1";
  /**
   * Link target, if set
   */
  Target?: string;
}

export interface CliCommandCopiedProperties {
  CommandContent: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Onboarding |
   */
  CommandType: "Onboarding";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | ZeroState |
   */
  Location: "ZeroState";
}

export interface ConnectGraphFlowStartedProperties {
  /**
   * Defines the type of API that is being connected to a new supergraph during the onboarding process.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | GraphQL, REST |
   */
  "API Type": "GraphQL" | "REST";
  /**
   * To distinguish between different onboarding flows for testing purposes
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Serverless, GraphOS-Trial, Enterprise-Trial, Free, Other |
   */
  OnboardingVariant?: "Serverless" | "GraphOS-Trial" | "Enterprise-Trial" | "Free" | "Other";
}

export interface ContactSalesRequestSubmittedProperties {
  /**
   * User email
   */
  Email?: string;
  /**
   * The Org (account) associated with the event.
   */
  "Org Id": string;
}

export interface CopyOfCloseClickedProperties {
  /**
   * Defines the type of API that is being connected to a new supergraph during the onboarding process.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | GraphQL, REST |
   */
  "API Type": "GraphQL" | "REST";
  /**
   * To distinguish between different onboarding flows for testing purposes
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Serverless, GraphOS-Trial, Enterprise-Trial |
   */
  OnboardingVariant?: "Serverless" | "GraphOS-Trial" | "Enterprise-Trial";
}

export interface EndpointIntrospectionErrorProperties {
  /**
   * Is the application running in 'studio' or 'embedded' mode?
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | studio, embedded-sandbox, embedded-explorer, embedded |
   */
  "Application Mode": "studio" | "embedded-sandbox" | "embedded-explorer" | "embedded";
  /**
   * Additional details regarding the specific error that occured
   */
  "Error Details"?: string;
  /**
   * Top-level error message
   */
  "Error Message"?: string;
  /**
   * Can be used to help group reported errors
   */
  "Error Type"?: string;
  "Graph URI"?: string;
  graphRef?: string;
}

export interface EndpointIntrospectionSuccessProperties {
  /**
   * Is the application running in 'studio' or 'embedded' mode?
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | studio, embedded-sandbox, embedded-explorer, embedded |
   */
  "Application Mode": "studio" | "embedded-sandbox" | "embedded-explorer" | "embedded";
  "Graph URI"?: string;
  graphRef?: string;
}

export interface GraphCreatedProperties {
  /**
   * Defines the type of API that is being connected to a new supergraph during the onboarding process.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | GraphQL, REST |
   */
  "API Type": "GraphQL" | "REST";
  "Next Onboarding Step": string;
  /**
   * To distinguish between different onboarding flows for testing purposes
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Serverless, GraphOS-Trial, Enterprise-Trial, Free, Other |
   */
  OnboardingVariant?: "Serverless" | "GraphOS-Trial" | "Enterprise-Trial" | "Free" | "Other";
}

export interface HomePageViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Deployed Graphs": number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Total Graphs": number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Unconfigured Graphs": number;
}

export interface ModalClosedProperties {
  ModalName?: string;
}

export interface ModalOpenedProperties {
  ModalName?: string;
}

export interface NewCloudServerlessCloudRouterCreatedProperties {
  "Org Id": string;
}

export interface NewOrgCreatedProperties {
  /**
   * The Org (account) associated with the event.
   */
  "Org Id": string;
  /**
   * The user that owns the org that was created.
   */
  "Owning User Id": string;
  "Subscription Kind": string;
}

export interface OnboardingCompletedProperties {
  /**
   * Defines the type of API that is being connected to a new supergraph during the onboarding process.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | GraphQL, REST |
   */
  "API Type": "GraphQL" | "REST";
  /**
   * To distinguish between different onboarding flows for testing purposes
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Serverless, GraphOS-Trial, Enterprise-Trial, Free, Other |
   */
  OnboardingVariant?: "Serverless" | "GraphOS-Trial" | "Enterprise-Trial" | "Free" | "Other";
}

export interface OnboardingPlanSelectedProperties {
  /**
   * User email
   */
  Email?: string;
  /**
   * Name of the page
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Onboarding Landing |
   */
  "Page Name": "Onboarding Landing";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Enterprise Trial, Cloud - Serverless Free |
   */
  "Plan Type": "Enterprise Trial" | "Cloud - Serverless Free";
}

export interface OnboardingStepReachedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Endpoint Selection, Overview, Router Settings, Summary, Endpoint Details, Graph Details |
   */
  Step: "Endpoint Selection" | "Overview" | "Router Settings" | "Summary" | "Endpoint Details" | "Graph Details";
}

export interface OnboardingSurveyCompletedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | user_profile_survey, unified_onboarding_survey, user_profile_survey__new_organization |
   */
  "Survey Name": "user_profile_survey" | "unified_onboarding_survey" | "user_profile_survey__new_organization";
  Version: string;
}

export interface OnboardingSurveyStartedProperties {
  /**
   * User email
   */
  Email?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | user_profile_survey, unified_onboarding_survey, user_profile_survey__new_organization |
   */
  "Survey Name": "user_profile_survey" | "unified_onboarding_survey" | "user_profile_survey__new_organization";
  Version: string;
}

export interface RegistrationAuthMethodSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sso, existing, email, github, other |
   */
  AuthorizationMethod: "sso" | "existing" | "email" | "github" | "other";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | www, studio-direct |
   */
  RegistrationVariant?: "www" | "studio-direct";
}

export interface RegistrationEmailVerifiedProperties {
  /**
   * User email
   */
  Email?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | www, studio-direct |
   */
  RegistrationVariant?: "www" | "studio-direct";
}

export interface RegistrationErrorProperties {
  /**
   * User email
   */
  Email?: string;
  /**
   * Additional details regarding the specific error that occured
   */
  "Error Details"?: string;
  /**
   * Top-level error message
   */
  "Error Message"?: string;
  /**
   * Can be used to help group reported errors
   */
  "Error Type"?: string;
  OrgId?: string;
  /**
   * Did this user start registration at the enterpirse-trial signup flow? or using the front door of the application?
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | frontdoor-signup, invite-signup, graphos-trial-signup, enterprise-trial-signup |
   */
  RegistrationPath: "frontdoor-signup" | "invite-signup" | "graphos-trial-signup" | "enterprise-trial-signup";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | www, studio-direct |
   */
  RegistrationVariant?: "www" | "studio-direct";
}

export interface RegistrationLoggedInProperties {
  /**
   * User email
   */
  Email?: string;
}

export interface RegistrationOpenedProperties {
  /**
   * Did this user start registration at the enterpirse-trial signup flow? or using the front door of the application?
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | frontdoor-signup, invite-signup, graphos-trial-signup, enterprise-trial-signup |
   */
  RegistrationPath: "frontdoor-signup" | "invite-signup" | "graphos-trial-signup" | "enterprise-trial-signup";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | www, studio-direct |
   */
  RegistrationVariant?: "www" | "studio-direct";
}

export interface RegistrationSuccessProperties {
  /**
   * User email
   */
  Email?: string;
  OrgId?: string;
  /**
   * Did this user start registration at the enterpirse-trial signup flow? or using the front door of the application?
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | frontdoor-signup, invite-signup, graphos-trial-signup, enterprise-trial-signup |
   */
  RegistrationPath: "frontdoor-signup" | "invite-signup" | "graphos-trial-signup" | "enterprise-trial-signup";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | www, studio-direct |
   */
  RegistrationVariant?: "www" | "studio-direct";
}

export interface SchemaProvidedProperties {
  /**
   * The source of a schema provided during onboarding.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Manual Upload, Graph Introspection With Deprecated Input Fields, Graph Introspection Without Deprecated Input Fields, Subgraph Introspection, Unknown Introspection |
   */
  "Schema Source":
    | "Manual Upload"
    | "Graph Introspection With Deprecated Input Fields"
    | "Graph Introspection Without Deprecated Input Fields"
    | "Subgraph Introspection"
    | "Unknown Introspection";
}

export interface SignInErrorProperties {
  /**
   * User email
   */
  Email?: string;
  /**
   * Additional details regarding the specific error that occured
   */
  "Error Details"?: string;
  /**
   * Top-level error message
   */
  "Error Message"?: string;
  /**
   * Can be used to help group reported errors
   */
  "Error Type"?: string;
}

export interface SignInPromptProperties {
  /**
   * User email
   */
  Email?: string;
}

export interface SurveyStepCompletedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | user_profile_survey, unified_onboarding_survey, user_profile_survey__new_organization |
   */
  "Survey Name": "user_profile_survey" | "unified_onboarding_survey" | "user_profile_survey__new_organization";
  Version: string;
}

export interface SurveyStepSkippedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | user_profile_survey, unified_onboarding_survey, user_profile_survey__new_organization |
   */
  "Survey Name": "user_profile_survey" | "unified_onboarding_survey" | "user_profile_survey__new_organization";
}

export interface UnifiedOnboardingPlanSelectionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Enterprise Trial, Cloud - Serverless Free |
   */
  "Plan Type": "Enterprise Trial" | "Cloud - Serverless Free";
}

export interface UnifiedOnboardingPrimaryGoalProperties {
  /**
   * Answer text from unified onboarding flow
   */
  "Answer Text": string;
  /**
   * Text of the question in the unified onboarding flow
   */
  "Question Text": string;
}

export interface UnifiedOnboardingUseCaseProperties {
  /**
   * Answer text from unified onboarding flow
   */
  "Answer Text": string;
  /**
   * Text of the question in the unified onboarding flow
   */
  "Question Text": string;
}

export interface UnifiedOnboardingUserResearchProperties {
  /**
   * User opted in to research group communications
   */
  "Consented to Contact": boolean;
  Email?: string;
  /**
   * Name of the user
   */
  "Full Name"?: string;
}

export interface UpgradeOverlayViewedProperties {
  /**
   * The current page/URL that the event took place
   */
  "Page URL": string;
  /**
   * The plan the user's org is on.
   */
  "Plan Name": string;
}

export interface UserDropoffProperties {
  "Current Onboarding Step": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | escapeKey, modalClickOutside, closeButton |
   */
  "Dropoff Method": "escapeKey" | "modalClickOutside" | "closeButton";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Onboarding Step": number;
}

export interface ValidationErrorProperties {
  /**
   * Additional details regarding the specific error that occured
   */
  "Error Details"?: string;
  /**
   * Top-level error message
   */
  "Error Message"?: string;
  /**
   * Can be used to help group reported errors
   */
  "Error Type"?: string;
  /**
   * A rough category/area of the product this event occured in. This and be useful when classifying  'General Tracking' events into specific product areas.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | New Org/User Onboarding, Graph Onboarding V1 |
   */
  "Product Context"?: "New Org/User Onboarding" | "Graph Onboarding V1";
  /**
   * Classification for the type of Validation Error that occured.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Endpoint Introspection Error |
   */
  "Validation Error Kind": "Endpoint Introspection Error";
}

export interface ViewedPageProperties {
  /**
   * Name of the page
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Onboarding Landing |
   */
  "Page Name": "Onboarding Landing";
  /**
   * The current page/URL that the event took place
   */
  "Page URL": string;
}

export interface ViewedTooltipProperties {
  /**
   * A friendly description for this link's intention.
   */
  Description: string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CancelConnectApiClicked implements BaseEvent {
  event_type = '"Cancel ConnectAPI" Clicked';

  constructor(
    public event_properties: CancelConnectApiClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CancelClicked implements BaseEvent {
  event_type = '"Cancel" clicked';

  constructor(
    public event_properties: CancelClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangeLogL1Viewed implements BaseEvent {
  event_type = '"Change Log L1" Viewed';

  constructor(
    public event_properties: ChangeLogL1ViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChecksL1Viewed implements BaseEvent {
  event_type = '"Checks L1" Viewed';

  constructor(
    public event_properties: ChecksL1ViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClientsL1Viewed implements BaseEvent {
  event_type = '"Clients L1" Viewed';

  constructor(
    public event_properties: ClientsL1ViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CloneTemplateClicked implements BaseEvent {
  event_type = '"Clone Template" Clicked';
}

export class CloseClicked implements BaseEvent {
  event_type = '"Close" clicked';

  constructor(
    public event_properties: CloseClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CloudRouterL1Viewed implements BaseEvent {
  event_type = '"Cloud Router L1" Viewed';

  constructor(
    public event_properties: CloudRouterL1ViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ConnectApiClicked implements BaseEvent {
  event_type = '"Connect API" Clicked';

  constructor(
    public event_properties: ConnectApiClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ConnectorsQuickstartClicked implements BaseEvent {
  event_type = '"Connectors Quickstart" Clicked';

  constructor(
    public event_properties: ConnectorsQuickstartClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContinueToGraphDetailsClicked implements BaseEvent {
  event_type = '"Continue to Graph Details" Clicked';
}

export class CreateDemoGraphClicked implements BaseEvent {
  event_type = '"Create Demo Graph" Clicked';

  constructor(
    public event_properties?: CreateDemoGraphClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreateFirstGraphClicked implements BaseEvent {
  event_type = '"Create First Graph" Clicked';

  constructor(
    public event_properties?: CreateFirstGraphClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreateGraphClicked implements BaseEvent {
  event_type = '"Create Graph" Clicked';

  constructor(
    public event_properties: CreateGraphClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreateSupergraphClicked implements BaseEvent {
  event_type = '"Create Supergraph" Clicked';
}

export class CreatingGraphClicked implements BaseEvent {
  event_type = '"Creating Graph" Clicked';

  constructor(
    public event_properties: CreatingGraphClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EditSchemaClicked implements BaseEvent {
  event_type = '"Edit Schema" Clicked';
}

export class ExplorerOperationSubmitted implements BaseEvent {
  event_type = '"Explorer Operation" Submitted';

  constructor(
    public event_properties: ExplorerOperationSubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExplorerViewed implements BaseEvent {
  event_type = '"Explorer" Viewed';

  constructor(
    public event_properties: ExplorerViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FinishLaterClicked implements BaseEvent {
  event_type = '"Finish Later" Clicked';

  constructor(
    public event_properties: FinishLaterClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GoToGraphClicked implements BaseEvent {
  event_type = '"Go to Graph" Clicked';

  constructor(
    public event_properties: GoToGraphClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GoToOdysseyClicked implements BaseEvent {
  event_type = '"Go to Odyssey" Clicked';
}

export class GoToStudioCta implements BaseEvent {
  event_type = '"Go To Studio" CTA';

  constructor(
    public event_properties?: GoToStudioCtaProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GraphSettingsL1Viewed implements BaseEvent {
  event_type = '"Graph Settings L1" Viewed';

  constructor(
    public event_properties: GraphSettingsL1ViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HelpAndSupportClicked implements BaseEvent {
  event_type = '"Help and Support" Clicked';
}

export class LaunchesL1Viewed implements BaseEvent {
  event_type = '"Launches L1" Viewed';

  constructor(
    public event_properties: LaunchesL1ViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OnboardingZeroStateVisited implements BaseEvent {
  event_type = '"Onboarding Zero State" Visited';

  constructor(
    public event_properties?: OnboardingZeroStateVisitedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OperationsL1Viewed implements BaseEvent {
  event_type = '"Operations L1" Viewed';

  constructor(
    public event_properties: OperationsL1ViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PricingDetailsClicked implements BaseEvent {
  event_type = '"Pricing Details" Clicked';

  constructor(
    public event_properties?: PricingDetailsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProceedToPublishSchemaClicked implements BaseEvent {
  event_type = '"Proceed to Publish Schema" Clicked';

  constructor(
    public event_properties: ProceedToPublishSchemaClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProvideHeadersClicked implements BaseEvent {
  event_type = '"Provide Headers" Clicked';
}

export class RoverInstallationMethodsClicked implements BaseEvent {
  event_type = '"Rover Installation Methods" Clicked';
}

export class SchemaL1Viewed implements BaseEvent {
  event_type = '"Schema L1" Viewed';

  constructor(
    public event_properties: SchemaL1ViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SchemaPublished implements BaseEvent {
  event_type = '"Schema Published"';

  constructor(
    public event_properties: SchemaPublishedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SeeSchemaClicked implements BaseEvent {
  event_type = '"See Schema" clicked';

  constructor(
    public event_properties: SeeSchemaClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SettingsL1Viewed implements BaseEvent {
  event_type = '"Settings L1" Viewed';

  constructor(
    public event_properties: SettingsL1ViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StartCloudOnboardingClicked implements BaseEvent {
  event_type = '"Start Cloud Onboarding" Clicked';
}

export class StudioDirectSignupClicked implements BaseEvent {
  event_type = '"Studio Direct Signup" Clicked';
}

export class StudioDirectSignupRedirected implements BaseEvent {
  event_type = '"Studio Direct Signup" Redirected';
}

export class SubgraphsL1Viewed implements BaseEvent {
  event_type = '"Subgraphs L1" Viewed';

  constructor(
    public event_properties: SubgraphsL1ViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UploadSchemaClicked implements BaseEvent {
  event_type = '"Upload Schema" Clicked';
}

export class UseRestApiClicked implements BaseEvent {
  event_type = '"Use REST API" Clicked';
}

export class VariantsHomeL1Viewed implements BaseEvent {
  event_type = '"Variants Home L1" Viewed';

  constructor(
    public event_properties: VariantsHomeL1ViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewDemoGraphClicked implements BaseEvent {
  event_type = '"View Demo Graph" Clicked';
}

export class ZeroStateVisited implements BaseEvent {
  event_type = '"Zero State" Visited';

  constructor(
    public event_properties?: ZeroStateVisitedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickedButton implements BaseEvent {
  event_type = 'Clicked Button';

  constructor(
    public event_properties: ClickedButtonProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickedLink implements BaseEvent {
  event_type = 'Clicked Link';

  constructor(
    public event_properties: ClickedLinkProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CliCommandCopied implements BaseEvent {
  event_type = 'cliCommandCopied';

  constructor(
    public event_properties: CliCommandCopiedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CompletedOnboarding implements BaseEvent {
  event_type = 'Completed Onboarding';
}

export class ConnectGraphFlowStarted implements BaseEvent {
  event_type = 'Connect Graph Flow Started';

  constructor(
    public event_properties: ConnectGraphFlowStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContactSalesRequestSubmitted implements BaseEvent {
  event_type = 'Contact Sales Request Submitted';

  constructor(
    public event_properties: ContactSalesRequestSubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CopyOfCloseClicked implements BaseEvent {
  event_type = 'Copy of "Close" clicked';

  constructor(
    public event_properties: CopyOfCloseClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EndpointDetailsViewed implements BaseEvent {
  event_type = 'Endpoint Details Viewed';
}

export class EndpointIntrospectionError implements BaseEvent {
  event_type = 'Endpoint Introspection Error';

  constructor(
    public event_properties: EndpointIntrospectionErrorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EndpointIntrospectionSuccess implements BaseEvent {
  event_type = 'Endpoint Introspection Success';

  constructor(
    public event_properties: EndpointIntrospectionSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EndpointSelectionViewed implements BaseEvent {
  event_type = 'Endpoint Selection Viewed';
}

export class GraphCreated implements BaseEvent {
  event_type = 'Graph created';

  constructor(
    public event_properties: GraphCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GraphDetailsViewed implements BaseEvent {
  event_type = 'Graph Details Viewed';
}

export class HomePageViewed implements BaseEvent {
  event_type = 'Home Page Viewed';

  constructor(
    public event_properties: HomePageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ModalClosed implements BaseEvent {
  event_type = 'modalClosed';

  constructor(
    public event_properties?: ModalClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ModalOpened implements BaseEvent {
  event_type = 'modalOpened';

  constructor(
    public event_properties?: ModalOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NewCloudServerlessCloudRouterCreated implements BaseEvent {
  event_type = 'New Cloud Serverless Cloud Router Created';

  constructor(
    public event_properties: NewCloudServerlessCloudRouterCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NewOrgCreated implements BaseEvent {
  event_type = 'New Org Created';

  constructor(
    public event_properties: NewOrgCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OnboardingCompleted implements BaseEvent {
  event_type = 'Onboarding completed';

  constructor(
    public event_properties: OnboardingCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OnboardingOverviewViewed implements BaseEvent {
  event_type = 'Onboarding Overview Viewed';
}

export class OnboardingPlanSelected implements BaseEvent {
  event_type = 'Onboarding Plan Selected';

  constructor(
    public event_properties: OnboardingPlanSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OnboardingStepReached implements BaseEvent {
  event_type = 'Onboarding Step Reached';

  constructor(
    public event_properties: OnboardingStepReachedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OnboardingSummaryViewed implements BaseEvent {
  event_type = 'Onboarding Summary Viewed';
}

export class OnboardingSurveyCompleted implements BaseEvent {
  event_type = 'Onboarding Survey Completed';

  constructor(
    public event_properties: OnboardingSurveyCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OnboardingSurveyStarted implements BaseEvent {
  event_type = 'Onboarding Survey Started';

  constructor(
    public event_properties: OnboardingSurveyStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RegistrationAuthMethodSelected implements BaseEvent {
  event_type = 'Registration Auth Method Selected';

  constructor(
    public event_properties: RegistrationAuthMethodSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RegistrationEmailVerified implements BaseEvent {
  event_type = 'Registration Email Verified';

  constructor(
    public event_properties?: RegistrationEmailVerifiedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RegistrationError implements BaseEvent {
  event_type = 'Registration Error';

  constructor(
    public event_properties: RegistrationErrorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RegistrationLoggedIn implements BaseEvent {
  event_type = 'Registration Logged In';

  constructor(
    public event_properties?: RegistrationLoggedInProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RegistrationOpened implements BaseEvent {
  event_type = 'Registration Opened';

  constructor(
    public event_properties: RegistrationOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RegistrationSuccess implements BaseEvent {
  event_type = 'Registration Success';

  constructor(
    public event_properties: RegistrationSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RouterSettingsViewed implements BaseEvent {
  event_type = 'Router Settings Viewed';
}

export class SchemaProvided implements BaseEvent {
  event_type = 'Schema Provided';

  constructor(
    public event_properties: SchemaProvidedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SetupSubroutesSubmitted implements BaseEvent {
  event_type = 'Setup Subroutes Submitted';
}

export class SignInError implements BaseEvent {
  event_type = 'Sign In Error';

  constructor(
    public event_properties?: SignInErrorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SignInPrompt implements BaseEvent {
  event_type = 'Sign In Prompt';

  constructor(
    public event_properties?: SignInPromptProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SurveyStepCompleted implements BaseEvent {
  event_type = 'Survey Step Completed';

  constructor(
    public event_properties: SurveyStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SurveyStepSkipped implements BaseEvent {
  event_type = 'Survey Step Skipped';

  constructor(
    public event_properties: SurveyStepSkippedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UnifiedOnboardingPlanSelection implements BaseEvent {
  event_type = 'Unified Onboarding - Plan Selection';

  constructor(
    public event_properties: UnifiedOnboardingPlanSelectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UnifiedOnboardingPrimaryGoal implements BaseEvent {
  event_type = 'Unified Onboarding - Primary Goal';

  constructor(
    public event_properties: UnifiedOnboardingPrimaryGoalProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UnifiedOnboardingUseCase implements BaseEvent {
  event_type = 'Unified Onboarding - Use Case';

  constructor(
    public event_properties: UnifiedOnboardingUseCaseProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UnifiedOnboardingUserResearch implements BaseEvent {
  event_type = 'Unified Onboarding - User Research';

  constructor(
    public event_properties: UnifiedOnboardingUserResearchProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UpgradeOverlayViewed implements BaseEvent {
  event_type = 'Upgrade Overlay Viewed';

  constructor(
    public event_properties: UpgradeOverlayViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserDropoff implements BaseEvent {
  event_type = 'User Dropoff';

  constructor(
    public event_properties: UserDropoffProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserApiKeyCopied implements BaseEvent {
  event_type = 'userApiKeyCopied';
}

export class UserApiKeyGenerated implements BaseEvent {
  event_type = 'userApiKeyGenerated';
}

export class ValidationError implements BaseEvent {
  event_type = 'Validation Error';

  constructor(
    public event_properties: ValidationErrorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPage implements BaseEvent {
  event_type = 'Viewed Page';

  constructor(
    public event_properties: ViewedPageProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPlans implements BaseEvent {
  event_type = 'Viewed Plans';
}

export class ViewedTooltip implements BaseEvent {
  event_type = 'Viewed Tooltip';

  constructor(
    public event_properties: ViewedTooltipProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * "Cancel ConnectAPI" Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Cancel%20ConnectAPI%22%20Clicked)
   *
   * Event to track when users click to cancel connecting a GraphQL or REST API during GraphOS Trial Onboarding.
   *
   * @param properties The event's properties (e.g. API Type)
   * @param options Amplitude event options.
   */
  cancelConnectApiClicked(
    properties: CancelConnectApiClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CancelConnectApiClicked(properties), options);
  }

  /**
   * "Cancel" clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Cancel%22%20clicked)
   *
   * Event to track when users click on the "Cancel" button during the GraphOS Trial Onboarding process.
   *
   * @param properties The event's properties (e.g. API Type)
   * @param options Amplitude event options.
   */
  cancelClicked(
    properties: CancelClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CancelClicked(properties), options);
  }

  /**
   * "Change Log L1" Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Change%20Log%20L1%22%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Application Mode)
   * @param options Amplitude event options.
   */
  changeLogL1Viewed(
    properties: ChangeLogL1ViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeLogL1Viewed(properties), options);
  }

  /**
   * "Checks L1" Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Checks%20L1%22%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Application Mode)
   * @param options Amplitude event options.
   */
  checksL1Viewed(
    properties: ChecksL1ViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChecksL1Viewed(properties), options);
  }

  /**
   * "Clients L1" Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Clients%20L1%22%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Application Mode)
   * @param options Amplitude event options.
   */
  clientsL1Viewed(
    properties: ClientsL1ViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClientsL1Viewed(properties), options);
  }

  /**
   * "Clone Template" Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Clone%20Template%22%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  cloneTemplateClicked(
    options?: EventOptions,
  ) {
    return this.track(new CloneTemplateClicked(), options);
  }

  /**
   * "Close" clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Close%22%20clicked)
   *
   * Event to track when users click on the "Cancel" button during the GraphOS Trial Onboarding process.
   *
   * @param properties The event's properties (e.g. API Type)
   * @param options Amplitude event options.
   */
  closeClicked(
    properties: CloseClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CloseClicked(properties), options);
  }

  /**
   * "Cloud Router L1" Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Cloud%20Router%20L1%22%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Application Mode)
   * @param options Amplitude event options.
   */
  cloudRouterL1Viewed(
    properties: CloudRouterL1ViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CloudRouterL1Viewed(properties), options);
  }

  /**
   * "Connect API" Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Connect%20API%22%20Clicked)
   *
   * Event triggered when a user clicks on either the "Connect a GraphQL API" or "Connect a REST API" call-to-action while at the "zero-state" (landing page) during the onboarding process for a GraphOS Trial.
   *
   * @param properties The event's properties (e.g. API Type)
   * @param options Amplitude event options.
   */
  connectApiClicked(
    properties: ConnectApiClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ConnectApiClicked(properties), options);
  }

  /**
   * "Connectors Quickstart" Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Connectors%20Quickstart%22%20Clicked)
   *
   * Event to track when users click on a link to visit the Connectors Quickstart docs.
   *
   * @param properties The event's properties (e.g. API Type)
   * @param options Amplitude event options.
   */
  connectorsQuickstartClicked(
    properties: ConnectorsQuickstartClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ConnectorsQuickstartClicked(properties), options);
  }

  /**
   * "Continue to Graph Details" Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Continue%20to%20Graph%20Details%22%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  continueToGraphDetailsClicked(
    options?: EventOptions,
  ) {
    return this.track(new ContinueToGraphDetailsClicked(), options);
  }

  /**
   * "Create Demo Graph" Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Create%20Demo%20Graph%22%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. OnboardingVariant)
   * @param options Amplitude event options.
   */
  createDemoGraphClicked(
    properties?: CreateDemoGraphClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreateDemoGraphClicked(properties), options);
  }

  /**
   * "Create First Graph" Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Create%20First%20Graph%22%20Clicked)
   *
   * A user signing up for Enterprise Trial has clicked "Create First Graph".
   *
   * @param properties The event's properties (e.g. OnboardingVariant)
   * @param options Amplitude event options.
   */
  createFirstGraphClicked(
    properties?: CreateFirstGraphClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreateFirstGraphClicked(properties), options);
  }

  /**
   * "Create Graph" Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Create%20Graph%22%20Clicked)
   *
   * Event to track when users click on the "Create Graph" button during the GraphOS Trial Onboarding process.
   *
   * @param properties The event's properties (e.g. API Type)
   * @param options Amplitude event options.
   */
  createGraphClicked(
    properties: CreateGraphClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreateGraphClicked(properties), options);
  }

  /**
   * "Create Supergraph" Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Create%20Supergraph%22%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  createSupergraphClicked(
    options?: EventOptions,
  ) {
    return this.track(new CreateSupergraphClicked(), options);
  }

  /**
   * "Creating Graph" Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Creating%20Graph%22%20Clicked)
   *
   * Tracks when users click to proceed to the next step in building a Connector-based graph during the GraphOS Trial onboarding process.
   *
   * @param properties The event's properties (e.g. API Type)
   * @param options Amplitude event options.
   */
  creatingGraphClicked(
    properties: CreatingGraphClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreatingGraphClicked(properties), options);
  }

  /**
   * "Edit Schema" Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Edit%20Schema%22%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  editSchemaClicked(
    options?: EventOptions,
  ) {
    return this.track(new EditSchemaClicked(), options);
  }

  /**
   * "Explorer Operation" Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Explorer%20Operation%22%20Submitted)
   *
   * This captures when a user has attempted a query or mutation in Explorer.
   *
   * @param properties The event's properties (e.g. Application Mode)
   * @param options Amplitude event options.
   */
  explorerOperationSubmitted(
    properties: ExplorerOperationSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExplorerOperationSubmitted(properties), options);
  }

  /**
   * "Explorer" Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Explorer%22%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Application Mode)
   * @param options Amplitude event options.
   */
  explorerViewed(
    properties: ExplorerViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExplorerViewed(properties), options);
  }

  /**
   * "Finish Later" Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Finish%20Later%22%20Clicked)
   *
   * A user has gotten to the Schema Publish page, and clicked 'Finish later"
   *
   * @param properties The event's properties (e.g. API Type)
   * @param options Amplitude event options.
   */
  finishLaterClicked(
    properties: FinishLaterClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FinishLaterClicked(properties), options);
  }

  /**
   * "Go to Graph" Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Go%20to%20Graph%22%20Clicked)
   *
   * User clicked on the call-to-action button to proceed to the Graph page during the GraphOS Trial signup process.
   *
   * @param properties The event's properties (e.g. API Type)
   * @param options Amplitude event options.
   */
  goToGraphClicked(
    properties: GoToGraphClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GoToGraphClicked(properties), options);
  }

  /**
   * "Go to Odyssey" Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Go%20to%20Odyssey%22%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  goToOdysseyClicked(
    options?: EventOptions,
  ) {
    return this.track(new GoToOdysseyClicked(), options);
  }

  /**
   * "Go To Studio" CTA
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Go%20To%20Studio%22%20CTA)
   *
   * This event occurs when a user is on a "Thanks for Validating Your Email" screen, and is invited to click through to Studio.
   *
   * @param properties The event's properties (e.g. Email)
   * @param options Amplitude event options.
   */
  goToStudioCta(
    properties?: GoToStudioCtaProperties,
    options?: EventOptions,
  ) {
    return this.track(new GoToStudioCta(properties), options);
  }

  /**
   * "Graph Settings L1" Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Graph%20Settings%20L1%22%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Application Mode)
   * @param options Amplitude event options.
   */
  graphSettingsL1Viewed(
    properties: GraphSettingsL1ViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GraphSettingsL1Viewed(properties), options);
  }

  /**
   * "Help and Support" Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Help%20and%20Support%22%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  helpAndSupportClicked(
    options?: EventOptions,
  ) {
    return this.track(new HelpAndSupportClicked(), options);
  }

  /**
   * "Launches L1" Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Launches%20L1%22%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Application Mode)
   * @param options Amplitude event options.
   */
  launchesL1Viewed(
    properties: LaunchesL1ViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LaunchesL1Viewed(properties), options);
  }

  /**
   * "Onboarding Zero State" Visited
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Onboarding%20Zero%20State%22%20Visited)
   *
   * A user has arrived at the top of the Onboarding path
   *
   * @param properties The event's properties (e.g. OnboardingVariant)
   * @param options Amplitude event options.
   */
  onboardingZeroStateVisited(
    properties?: OnboardingZeroStateVisitedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingZeroStateVisited(properties), options);
  }

  /**
   * "Operations L1" Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Operations%20L1%22%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Application Mode)
   * @param options Amplitude event options.
   */
  operationsL1Viewed(
    properties: OperationsL1ViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OperationsL1Viewed(properties), options);
  }

  /**
   * "Pricing Details" Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Pricing%20Details%22%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. OnboardingVariant)
   * @param options Amplitude event options.
   */
  pricingDetailsClicked(
    properties?: PricingDetailsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PricingDetailsClicked(properties), options);
  }

  /**
   * "Proceed to Publish Schema" Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Proceed%20to%20Publish%20Schema%22%20Clicked)
   *
   * A user has filled out the Graph Details Modal and clicked "Next".
   *
   * @param properties The event's properties (e.g. API Type)
   * @param options Amplitude event options.
   */
  proceedToPublishSchemaClicked(
    properties: ProceedToPublishSchemaClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProceedToPublishSchemaClicked(properties), options);
  }

  /**
   * "Provide Headers" Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Provide%20Headers%22%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  provideHeadersClicked(
    options?: EventOptions,
  ) {
    return this.track(new ProvideHeadersClicked(), options);
  }

  /**
   * "Rover Installation Methods" Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Rover%20Installation%20Methods%22%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  roverInstallationMethodsClicked(
    options?: EventOptions,
  ) {
    return this.track(new RoverInstallationMethodsClicked(), options);
  }

  /**
   * "Schema L1" Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Schema%20L1%22%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Application Mode)
   * @param options Amplitude event options.
   */
  schemaL1Viewed(
    properties: SchemaL1ViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SchemaL1Viewed(properties), options);
  }

  /**
   * "Schema Published"
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Schema%20Published%22)
   *
   * Schema has successfully published.
   *
   * @param properties The event's properties (e.g. API Type)
   * @param options Amplitude event options.
   */
  schemaPublished(
    properties: SchemaPublishedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SchemaPublished(properties), options);
  }

  /**
   * "See Schema" clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22See%20Schema%22%20clicked)
   *
   * Event to track when users click on "See Schema" button during the GraphOS Trial Onboarding process.
   *
   * @param properties The event's properties (e.g. API Type)
   * @param options Amplitude event options.
   */
  seeSchemaClicked(
    properties: SeeSchemaClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SeeSchemaClicked(properties), options);
  }

  /**
   * "Settings L1" Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Settings%20L1%22%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Application Mode)
   * @param options Amplitude event options.
   */
  settingsL1Viewed(
    properties: SettingsL1ViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SettingsL1Viewed(properties), options);
  }

  /**
   * "Start Cloud Onboarding" Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Start%20Cloud%20Onboarding%22%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  startCloudOnboardingClicked(
    options?: EventOptions,
  ) {
    return this.track(new StartCloudOnboardingClicked(), options);
  }

  /**
   * "Studio Direct Signup" Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Studio%20Direct%20Signup%22%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  studioDirectSignupClicked(
    options?: EventOptions,
  ) {
    return this.track(new StudioDirectSignupClicked(), options);
  }

  /**
   * "Studio Direct Signup" Redirected
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Studio%20Direct%20Signup%22%20Redirected)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  studioDirectSignupRedirected(
    options?: EventOptions,
  ) {
    return this.track(new StudioDirectSignupRedirected(), options);
  }

  /**
   * "Subgraphs L1" Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Subgraphs%20L1%22%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Application Mode)
   * @param options Amplitude event options.
   */
  subgraphsL1Viewed(
    properties: SubgraphsL1ViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubgraphsL1Viewed(properties), options);
  }

  /**
   * "Upload Schema" Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Upload%20Schema%22%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  uploadSchemaClicked(
    options?: EventOptions,
  ) {
    return this.track(new UploadSchemaClicked(), options);
  }

  /**
   * "Use REST API" Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Use%20REST%20API%22%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  useRestApiClicked(
    options?: EventOptions,
  ) {
    return this.track(new UseRestApiClicked(), options);
  }

  /**
   * "Variants Home L1" Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Variants%20Home%20L1%22%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Application Mode)
   * @param options Amplitude event options.
   */
  variantsHomeL1Viewed(
    properties: VariantsHomeL1ViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new VariantsHomeL1Viewed(properties), options);
  }

  /**
   * "View Demo Graph" Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22View%20Demo%20Graph%22%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  viewDemoGraphClicked(
    options?: EventOptions,
  ) {
    return this.track(new ViewDemoGraphClicked(), options);
  }

  /**
   * "Zero State" Visited
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/%22Zero%20State%22%20Visited)
   *
   * Event indicating when a user visits the "Zero State" page during a GraphOS Trial session.
   *
   * @param properties The event's properties (e.g. OnboardingVariant)
   * @param options Amplitude event options.
   */
  zeroStateVisited(
    properties?: ZeroStateVisitedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ZeroStateVisited(properties), options);
  }

  /**
   * Clicked Button
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Clicked%20Button)
   *
   * This event tracks when a user clicks on a button
   *
   * @param properties The event's properties (e.g. Category)
   * @param options Amplitude event options.
   */
  clickedButton(
    properties: ClickedButtonProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickedButton(properties), options);
  }

  /**
   * Clicked Link
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Clicked%20Link)
   *
   * Clicked This event tracks when a user clicks on a link within studio
   *
   * @param properties The event's properties (e.g. Category)
   * @param options Amplitude event options.
   */
  clickedLink(
    properties: ClickedLinkProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickedLink(properties), options);
  }

  /**
   * cliCommandCopied
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/cliCommandCopied)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. CommandContent)
   * @param options Amplitude event options.
   */
  cliCommandCopied(
    properties: CliCommandCopiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CliCommandCopied(properties), options);
  }

  /**
   * Completed Onboarding
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Completed%20Onboarding)
   *
   * This event tracks when a user has answerd all the questions in the onboarding survey and successfully completed it
   *
   * @param options Amplitude event options.
   */
  completedOnboarding(
    options?: EventOptions,
  ) {
    return this.track(new CompletedOnboarding(), options);
  }

  /**
   * Connect Graph Flow Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Connect%20Graph%20Flow%20Started)
   *
   * Track the initiation of the graph flow connection process.
   *
   * @param properties The event's properties (e.g. API Type)
   * @param options Amplitude event options.
   */
  connectGraphFlowStarted(
    properties: ConnectGraphFlowStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ConnectGraphFlowStarted(properties), options);
  }

  /**
   * Contact Sales Request Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Contact%20Sales%20Request%20Submitted)
   *
   * Event tracks when a user clicks on the "submit contact request" button in the upgrade modal.
   *
   * @param properties The event's properties (e.g. Email)
   * @param options Amplitude event options.
   */
  contactSalesRequestSubmitted(
    properties: ContactSalesRequestSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContactSalesRequestSubmitted(properties), options);
  }

  /**
   * Copy of "Close" clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Copy%20of%20%22Close%22%20clicked)
   *
   * Event to track when users click on the "Cancel" button during the GraphOS Trial Onboarding process.
   *
   * @param properties The event's properties (e.g. API Type)
   * @param options Amplitude event options.
   */
  copyOfCloseClicked(
    properties: CopyOfCloseClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyOfCloseClicked(properties), options);
  }

  /**
   * Endpoint Details Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Endpoint%20Details%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  endpointDetailsViewed(
    options?: EventOptions,
  ) {
    return this.track(new EndpointDetailsViewed(), options);
  }

  /**
   * Endpoint Introspection Error
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Endpoint%20Introspection%20Error)
   *
   * This is intended to superceed  
   *  \`ampli.validationError({
   *
   * 'Validation Error Kind': 'Endpoint Introspection Error',
   *
   * });\` 
   *
   *  with the intent of creating a more explicit label for high-level graphs
   *
   * @param properties The event's properties (e.g. Application Mode)
   * @param options Amplitude event options.
   */
  endpointIntrospectionError(
    properties: EndpointIntrospectionErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new EndpointIntrospectionError(properties), options);
  }

  /**
   * Endpoint Introspection Success
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Endpoint%20Introspection%20Success)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Application Mode)
   * @param options Amplitude event options.
   */
  endpointIntrospectionSuccess(
    properties: EndpointIntrospectionSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new EndpointIntrospectionSuccess(properties), options);
  }

  /**
   * Endpoint Selection Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Endpoint%20Selection%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  endpointSelectionViewed(
    options?: EventOptions,
  ) {
    return this.track(new EndpointSelectionViewed(), options);
  }

  /**
   * Graph created
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Graph%20created)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. API Type)
   * @param options Amplitude event options.
   */
  graphCreated(
    properties: GraphCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GraphCreated(properties), options);
  }

  /**
   * Graph Details Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Graph%20Details%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  graphDetailsViewed(
    options?: EventOptions,
  ) {
    return this.track(new GraphDetailsViewed(), options);
  }

  /**
   * Home Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Home%20Page%20Viewed)
   *
   * Event to track when a user views the organization's home page of Studio UI.
   *
   * @param properties The event's properties (e.g. Deployed Graphs)
   * @param options Amplitude event options.
   */
  homePageViewed(
    properties: HomePageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HomePageViewed(properties), options);
  }

  /**
   * modalClosed
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/modalClosed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. ModalName)
   * @param options Amplitude event options.
   */
  modalClosed(
    properties?: ModalClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ModalClosed(properties), options);
  }

  /**
   * modalOpened
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/modalOpened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. ModalName)
   * @param options Amplitude event options.
   */
  modalOpened(
    properties?: ModalOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ModalOpened(properties), options);
  }

  /**
   * New Cloud Serverless Cloud Router Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/New%20Cloud%20Serverless%20Cloud%20Router%20Created)
   *
   * This event tracks the creation and launch of a new cloud supergraph.
   *
   * @param properties The event's properties (e.g. Org Id)
   * @param options Amplitude event options.
   */
  newCloudServerlessCloudRouterCreated(
    properties: NewCloudServerlessCloudRouterCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NewCloudServerlessCloudRouterCreated(properties), options);
  }

  /**
   * New Org Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/New%20Org%20Created)
   *
   * This event is triggered when a new organization is created in the system. It captures the essential information about the organization, such as its name, ID, and creation timestamp. This event is useful for tracking the growth and adoption of the platform, as well as analyzing patterns and trends related to new organizations
   *
   * Owner: michael.jenkins@apollographql.com
   *
   * @param properties The event's properties (e.g. Org Id)
   * @param options Amplitude event options.
   */
  newOrgCreated(
    properties: NewOrgCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NewOrgCreated(properties), options);
  }

  /**
   * Onboarding completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Onboarding%20completed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. API Type)
   * @param options Amplitude event options.
   */
  onboardingCompleted(
    properties: OnboardingCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingCompleted(properties), options);
  }

  /**
   * Onboarding Overview Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Onboarding%20Overview%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  onboardingOverviewViewed(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingOverviewViewed(), options);
  }

  /**
   * Onboarding Plan Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Onboarding%20Plan%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Email)
   * @param options Amplitude event options.
   */
  onboardingPlanSelected(
    properties: OnboardingPlanSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingPlanSelected(properties), options);
  }

  /**
   * Onboarding Step Reached
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Onboarding%20Step%20Reached)
   *
   * This event tracks when a user views a question in the onboarding survey
   *
   * @param properties The event's properties (e.g. Step)
   * @param options Amplitude event options.
   */
  onboardingStepReached(
    properties: OnboardingStepReachedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingStepReached(properties), options);
  }

  /**
   * Onboarding Summary Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Onboarding%20Summary%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  onboardingSummaryViewed(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingSummaryViewed(), options);
  }

  /**
   * Onboarding Survey Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Onboarding%20Survey%20Completed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Survey Name)
   * @param options Amplitude event options.
   */
  onboardingSurveyCompleted(
    properties: OnboardingSurveyCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingSurveyCompleted(properties), options);
  }

  /**
   * Onboarding Survey Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Onboarding%20Survey%20Started)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Email)
   * @param options Amplitude event options.
   */
  onboardingSurveyStarted(
    properties: OnboardingSurveyStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingSurveyStarted(properties), options);
  }

  /**
   * Registration Auth Method Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Registration%20Auth%20Method%20Selected)
   *
   * The user has selected to register via email or github.
   *
   * @param properties The event's properties (e.g. AuthorizationMethod)
   * @param options Amplitude event options.
   */
  registrationAuthMethodSelected(
    properties: RegistrationAuthMethodSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegistrationAuthMethodSelected(properties), options);
  }

  /**
   * Registration Email Verified
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Registration%20Email%20Verified)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Email)
   * @param options Amplitude event options.
   */
  registrationEmailVerified(
    properties?: RegistrationEmailVerifiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegistrationEmailVerified(properties), options);
  }

  /**
   * Registration Error
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Registration%20Error)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Email)
   * @param options Amplitude event options.
   */
  registrationError(
    properties: RegistrationErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegistrationError(properties), options);
  }

  /**
   * Registration Logged In
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Registration%20Logged%20In)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Email)
   * @param options Amplitude event options.
   */
  registrationLoggedIn(
    properties?: RegistrationLoggedInProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegistrationLoggedIn(properties), options);
  }

  /**
   * Registration Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Registration%20Opened)
   *
   * This event represents when the user first arrives at the signup page and before they do any actions.
   *
   * @param properties The event's properties (e.g. RegistrationPath)
   * @param options Amplitude event options.
   */
  registrationOpened(
    properties: RegistrationOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegistrationOpened(properties), options);
  }

  /**
   * Registration Success
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Registration%20Success)
   *
   * The user has succeed in creating a new account.
   *
   * @param properties The event's properties (e.g. Email)
   * @param options Amplitude event options.
   */
  registrationSuccess(
    properties: RegistrationSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegistrationSuccess(properties), options);
  }

  /**
   * Router Settings Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Router%20Settings%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  routerSettingsViewed(
    options?: EventOptions,
  ) {
    return this.track(new RouterSettingsViewed(), options);
  }

  /**
   * Schema Provided
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Schema%20Provided)
   *
   * Information about the schema provided during onboarding.
   *
   * @param properties The event's properties (e.g. Schema Source)
   * @param options Amplitude event options.
   */
  schemaProvided(
    properties: SchemaProvidedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SchemaProvided(properties), options);
  }

  /**
   * Setup Subroutes Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Setup%20Subroutes%20Submitted)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  setupSubroutesSubmitted(
    options?: EventOptions,
  ) {
    return this.track(new SetupSubroutesSubmitted(), options);
  }

  /**
   * Sign In Error
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Sign%20In%20Error)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Email)
   * @param options Amplitude event options.
   */
  signInError(
    properties?: SignInErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new SignInError(properties), options);
  }

  /**
   * Sign In Prompt
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Sign%20In%20Prompt)
   *
   * User is prompted to sign in
   *
   * @param properties The event's properties (e.g. Email)
   * @param options Amplitude event options.
   */
  signInPrompt(
    properties?: SignInPromptProperties,
    options?: EventOptions,
  ) {
    return this.track(new SignInPrompt(properties), options);
  }

  /**
   * Survey Step Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Survey%20Step%20Completed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Survey Name)
   * @param options Amplitude event options.
   */
  surveyStepCompleted(
    properties: SurveyStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SurveyStepCompleted(properties), options);
  }

  /**
   * Survey Step Skipped
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Survey%20Step%20Skipped)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Survey Name)
   * @param options Amplitude event options.
   */
  surveyStepSkipped(
    properties: SurveyStepSkippedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SurveyStepSkipped(properties), options);
  }

  /**
   * Unified Onboarding - Plan Selection
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Unified%20Onboarding%20-%20Plan%20Selection)
   *
   * Plan selected in unified onboarding flow
   *
   * @param properties The event's properties (e.g. Plan Type)
   * @param options Amplitude event options.
   */
  unifiedOnboardingPlanSelection(
    properties: UnifiedOnboardingPlanSelectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new UnifiedOnboardingPlanSelection(properties), options);
  }

  /**
   * Unified Onboarding - Primary Goal
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Unified%20Onboarding%20-%20Primary%20Goal)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Answer Text)
   * @param options Amplitude event options.
   */
  unifiedOnboardingPrimaryGoal(
    properties: UnifiedOnboardingPrimaryGoalProperties,
    options?: EventOptions,
  ) {
    return this.track(new UnifiedOnboardingPrimaryGoal(properties), options);
  }

  /**
   * Unified Onboarding - Use Case
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Unified%20Onboarding%20-%20Use%20Case)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Answer Text)
   * @param options Amplitude event options.
   */
  unifiedOnboardingUseCase(
    properties: UnifiedOnboardingUseCaseProperties,
    options?: EventOptions,
  ) {
    return this.track(new UnifiedOnboardingUseCase(properties), options);
  }

  /**
   * Unified Onboarding - User Research
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Unified%20Onboarding%20-%20User%20Research)
   *
   * This event tracks users' response for if they consent to being contacted for usesr research opportunties.
   *
   * @param properties The event's properties (e.g. Consented to Contact)
   * @param options Amplitude event options.
   */
  unifiedOnboardingUserResearch(
    properties: UnifiedOnboardingUserResearchProperties,
    options?: EventOptions,
  ) {
    return this.track(new UnifiedOnboardingUserResearch(properties), options);
  }

  /**
   * Upgrade Overlay Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Upgrade%20Overlay%20Viewed)
   *
   * Event to track when users view the upgrade overlay prompting them to upgrade their plan.
   *
   * @param properties The event's properties (e.g. Page URL)
   * @param options Amplitude event options.
   */
  upgradeOverlayViewed(
    properties: UpgradeOverlayViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UpgradeOverlayViewed(properties), options);
  }

  /**
   * User Dropoff
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/User%20Dropoff)
   *
   * Event to track users who drop off while building a supergraph during the GraphOS Trial process.
   *
   * @param properties The event's properties (e.g. Current Onboarding Step)
   * @param options Amplitude event options.
   */
  userDropoff(
    properties: UserDropoffProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserDropoff(properties), options);
  }

  /**
   * userApiKeyCopied
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/userApiKeyCopied)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  userApiKeyCopied(
    options?: EventOptions,
  ) {
    return this.track(new UserApiKeyCopied(), options);
  }

  /**
   * userApiKeyGenerated
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/userApiKeyGenerated)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  userApiKeyGenerated(
    options?: EventOptions,
  ) {
    return this.track(new UserApiKeyGenerated(), options);
  }

  /**
   * Validation Error
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Validation%20Error)
   *
   * This event tracks critical user validation errors, that may imply friction during product usages.
   *
   * @param properties The event's properties (e.g. Error Details)
   * @param options Amplitude event options.
   */
  validationError(
    properties: ValidationErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new ValidationError(properties), options);
  }

  /**
   * Viewed Page
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Viewed%20Page)
   *
   * This event tracks when a user views a page on the website
   *
   * @param properties The event's properties (e.g. Page Name)
   * @param options Amplitude event options.
   */
  viewedPage(
    properties: ViewedPageProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPage(properties), options);
  }

  /**
   * Viewed Plans
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Viewed%20Plans)
   *
   * Event captures when a user clicks on "Review Plans" in the Upgrade modal.
   *
   * @param options Amplitude event options.
   */
  viewedPlans(
    options?: EventOptions,
  ) {
    return this.track(new ViewedPlans(), options);
  }

  /**
   * Viewed Tooltip
   *
   * [View in Tracking Plan](https://data.amplitude.com/apollo-graphql/Apollo%20GraphQL/events/main/latest/Viewed%20Tooltip)
   *
   * This event tracks when a user views a tooltip on the website
   *
   * @param properties The event's properties (e.g. Description)
   * @param options Amplitude event options.
   */
  viewedTooltip(
    properties: ViewedTooltipProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedTooltip(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
