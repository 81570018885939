import { gql, useQuery } from '@apollo/client';
import IconEdit from '@apollo/icons/default/IconEdit.svg';
import IconPlus from '@apollo/icons/default/IconPlus.svg';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
  Select,
} from '@apollo/orbit';
import * as Sentry from '@sentry/react';
import { Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

import {
  PROGRAM_ID_STUDIO_HIGHER_LIMIT_REQUEST,
  useMarketo,
} from 'src/app/onboarding/hooks/useMarketo';
import { Form } from 'src/components/common/form/Form';
import { ErrorMessage } from 'src/components/errorMessage/ErrorMessage';
import { PermissionWarning } from 'src/components/permissionGuards/permissionWarning/PermissionWarning';
import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';
import { getTrackingCookies } from 'src/lib/analytics';
import {
  BillingPlanTier,
  HigherLimitRequestModalQuery,
  HigherLimitRequestModalQueryVariables,
  HigherLimitRequestModalQuery_me_User_memberships,
  UserPermission,
} from 'src/lib/graphqlTypes/types';
import { planNameMapper } from 'src/lib/planMappers';

import { ContactSupportLink } from '../ContactSupportCTAs';

const HigherLimitRequestModalCommonFragment = gql`
  fragment HigherLimitRequestModalCommonFragment on Identity {
    id
    ... on User {
      id
      email
      fullName
      memberships {
        role: permission
        account {
          id
          name
          companyUrl
          services {
            id
            title
          }
        }
        user {
          id
        }
      }
    }
  }
`;

const higherLimitRequestModalQuery = gql<
  HigherLimitRequestModalQuery,
  HigherLimitRequestModalQueryVariables
>`
  query HigherLimitRequestModalQuery {
    me {
      id
      ... on User {
        fullName
        memberships {
          account {
            id
            currentPlan {
              id
              tier
              name
              kind
            }
          }
          user {
            id
          }
        }
      }
      ...HigherLimitRequestModalCommonFragment
    }
  }
  ${HigherLimitRequestModalCommonFragment}
`;

const splitEmails = (emailsString: string) =>
  emailsString.trim().replace(/\s+/g, '').split(',').filter(Boolean);

function emailList(this: yup.StringSchema, message: string) {
  return this.test({
    name: 'emailList',
    message,
    test: (value: string) => {
      const emails = splitEmails(value);
      const schema = yup.string().email();
      return emails.every((email: string) => schema.isValidSync(email));
    },
  });
}

yup.addMethod(yup.string, 'emailList', emailList);

const validationSchema = yup.object().shape({
  accountId: yup
    .string()
    .required('Please select the account you need to upgrade.'),
  companyDomain: yup.string(),
  name: yup.string().required('Please enter your name.'),
  email: yup
    .string()
    .email('You must provide a valid email.')
    .required('Please enter an email.'),
  additionalCCs: yup.string().emailList('You must provide valid emails.'),
});

const ACEPHEI_ID = 'acephei-corp';

export interface Props {
  onClose: () => void;
  isOpen: boolean;
}

export const HigherLimitRequest = ({ onClose, isOpen }: Props) => {
  const [currentAccountId] = useCurrentAccountId();
  const { data, loading, error } = useQuery<
    HigherLimitRequestModalQuery,
    HigherLimitRequestModalQueryVariables
  >(higherLimitRequestModalQuery);
  const me = data?.me?.__typename === 'User' ? data.me : undefined;

  const orgsOnUsagePlans = useMemo(() => {
    const memberships: Array<HigherLimitRequestModalQuery_me_User_memberships> =
      me?.memberships || [];
    const acepheiMembership = memberships.find(
      ({ account }) => account.id === ACEPHEI_ID,
    );
    const isAcepheiAdmin =
      acepheiMembership && UserPermission.ORG_ADMIN === acepheiMembership.role;

    return me?.memberships
      .map(({ account }) => account)
      .filter(
        ({ id, currentPlan }) =>
          id !== ACEPHEI_ID ||
          isAcepheiAdmin ||
          currentPlan?.tier !== BillingPlanTier.USAGE_BASED,
      );
  }, [me]);

  const [showEmail, setShowEmail] = useState<boolean>(false);
  const [showAdditionalCCs, setShowAdditionalCCs] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<string>('');

  const pushMarketoLead = useMarketo();

  useEffect(() => {
    if (me && !me.email) {
      setShowEmail(true);
    }
  }, [me]);

  useEffect(() => {
    if (!isOpen && showSuccess) {
      setShowSuccess(false);
    }
  }, [isOpen, showSuccess]);

  const handleClose = () => {
    if (showSuccess) {
      setShowSuccess(false);
    }

    onClose();
  };

  return (
    <Modal
      onClose={handleClose}
      isOpen={isOpen}
      size={showSuccess ? 'xl' : '2xl'}
    >
      <ModalOverlay />
      <ModalContent>
        <>
          {showSuccess ? (
            <>
              <ModalHeader>
                <ModalTitle>You’re all set! 🎉</ModalTitle>
              </ModalHeader>
              <ModalBody>
                We’ll be in touch very soon. If you have any questions in the
                meantime, don’t hesitate to{' '}
                <ContactSupportLink>contact us</ContactSupportLink> in support.
              </ModalBody>
              <ModalFooter>
                <Button onClick={handleClose}>Close</Button>
              </ModalFooter>
            </>
          ) : (
            <Formik
              validationSchema={validationSchema}
              initialValues={{
                email: me?.email || '',
                additionalCCs: [],
                accountId: currentAccountId || '',
                companyDomain:
                  orgsOnUsagePlans?.find(({ id }) => id === currentAccountId)
                    ?.companyUrl || '',
                name: me?.fullName || '',
              }}
              enableReinitialize
              onSubmit={async (values) => {
                if (requestError) {
                  setRequestError('');
                }
                const { referrerCookie: referrer } = getTrackingCookies();
                const currentPlan =
                  orgsOnUsagePlans?.find(({ id }) => id === values.accountId)
                    ?.currentPlan?.name || '';

                pushMarketoLead({
                  lead: {
                    programId: PROGRAM_ID_STUDIO_HIGHER_LIMIT_REQUEST,
                    source: 'Upgrade Inquiry',
                    sourceDetail: `Upgrade Inquiry - ${currentPlan}`,
                  },
                  userId: me?.id,
                  fullName: values.name,
                  email: values.email,
                  utm: {
                    referrer,
                    utmSource: 'Apollo',
                    utmCampaign: 'Product - upgrade inquiry',
                    utmMedium: 'Product',
                  },
                  companyUrl: values.companyDomain,
                  orgId: values.accountId,
                })
                  .then((wasSuccessful) => {
                    if (wasSuccessful) {
                      setShowSuccess(true);
                    } else {
                      setRequestError('Failed to submit request.');
                    }
                  })
                  .catch((err) => {
                    Sentry.captureException(err);
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                isSubmitting,
                isValid,
                handleChange,
                handleBlur,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {(requestError || error?.message) && (
                    <ErrorMessage>
                      {requestError || error?.message}
                    </ErrorMessage>
                  )}
                  <ModalHeader>
                    <ModalTitle>Request a higher usage limit</ModalTitle>
                  </ModalHeader>
                  <ModalBody className="flex flex-col gap-4">
                    <fieldset className="flex flex-row gap-4">
                      <legend className="sr-only">Organization Details</legend>
                      <FormControl className="basis-1/2" isRequired>
                        <FormLabel>
                          Which account do you need to upgrade?
                        </FormLabel>
                        <Select
                          data-testid="select-org"
                          className="w-full"
                          name="accountId"
                          value={values.accountId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {orgsOnUsagePlans?.map((org) => (
                            <option
                              key={org.id}
                              value={org.id}
                              data-testid="select-org-option"
                            >
                              {org.name} -{' '}
                              {planNameMapper(
                                org.currentPlan.name,
                                org.currentPlan.tier,
                                org.currentPlan.kind,
                              )}
                            </option>
                          ))}
                        </Select>
                        {errors.accountId && touched.accountId && (
                          <FormErrorMessage>
                            <>{errors.accountId}</>
                          </FormErrorMessage>
                        )}
                      </FormControl>
                      <FormControl
                        className="basis-1/2"
                        isInvalid={
                          !!errors.companyDomain && !!touched.companyDomain
                        }
                      >
                        <FormLabel>Your company's domain</FormLabel>
                        <Input
                          type="text"
                          name="companyDomain"
                          value={values.companyDomain}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="https://example.com"
                        />
                        <FormErrorMessage>
                          {errors.companyDomain}
                        </FormErrorMessage>
                      </FormControl>
                    </fieldset>
                    <fieldset className="flex flex-col gap-4">
                      <legend className="sr-only">User Details</legend>
                      <FormControl
                        isRequired
                        isInvalid={!!errors.name && !!touched.name}
                      >
                        <FormLabel>Your name</FormLabel>
                        <Input
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <FormErrorMessage>{errors.name}</FormErrorMessage>
                      </FormControl>
                      {showEmail && (
                        <FormControl
                          className="basis-full"
                          isRequired
                          isInvalid={!!errors.email && !!touched.email}
                        >
                          <FormLabel>Email address</FormLabel>
                          <Input
                            type="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="example@test.com"
                          />
                          <FormErrorMessage>{errors.email}</FormErrorMessage>
                        </FormControl>
                      )}
                    </fieldset>
                    {showAdditionalCCs && (
                      <fieldset>
                        <legend className="sr-only">Additional Emails</legend>
                        <FormControl
                          isInvalid={
                            !!errors.additionalCCs && !!touched.additionalCCs
                          }
                        >
                          <FormLabel>CCs</FormLabel>
                          <Input
                            type="email"
                            name="additionalCCs"
                            value={values.additionalCCs}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="example@test.com"
                          />
                          <FormErrorMessage>
                            {errors.additionalCCs}
                          </FormErrorMessage>
                          {!errors.additionalCCs && !touched.additionalCCs && (
                            <FormHelperText>
                              Add as many as you’d like, just separate them with
                              commas.
                            </FormHelperText>
                          )}
                        </FormControl>
                      </fieldset>
                    )}
                    {!!values.email && (
                      <div className="mt-4 text-secondary">
                        We will follow up with you via email at{' '}
                        <span className="font-semibold text-black">
                          {values.email}
                        </span>
                        .
                      </div>
                    )}
                    {(!showEmail || !showAdditionalCCs) && (
                      <ButtonGroup className="mt-2">
                        {!showEmail && (
                          <Button
                            variant="hidden"
                            onClick={() => setShowEmail(true)}
                            size="sm"
                            leftIcon={<IconEdit />}
                          >
                            Change email
                          </Button>
                        )}

                        {!showAdditionalCCs && (
                          <Button
                            variant="hidden"
                            onClick={() => setShowAdditionalCCs(true)}
                            size="sm"
                            leftIcon={<IconPlus />}
                          >
                            Add a CC email
                          </Button>
                        )}
                      </ButtonGroup>
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <ButtonGroup>
                      <Button
                        type="button"
                        variant="secondary"
                        onClick={handleClose}
                        isDisabled={isSubmitting}
                      >
                        Cancel
                      </Button>
                      <PermissionWarning
                        accountId={values.accountId}
                        accountPermissions="canUpdateBillingInfo"
                        childRenderer={(hasPermission) => (
                          <Button
                            type="submit"
                            isLoading={loading || isSubmitting}
                            loadingText="Submitting"
                            isDisabled={!hasPermission || !isValid || !me}
                          >
                            Submit
                          </Button>
                        )}
                      />
                    </ButtonGroup>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          )}
        </>
      </ModalContent>
    </Modal>
  );
};
