import { graphs } from 'src/app/account/routes';
import { RouteConfig } from 'src/lib/routeConfig/RouteConfig';

export const graphOnboarding = graphs.extend(
  new RouteConfig({
    owners: ['growth'],
    definition: '/onboarding',
  }),
);

export const cloudGraphOnboarding = graphOnboarding.extend(
  new RouteConfig({
    owners: ['growth'],
    definition: '/cloud',
  }),
);

export const endpointDetails = cloudGraphOnboarding.extend(
  new RouteConfig({
    owners: ['growth'],
    definition: '/endpoint-details',
  }),
);

export const graphDetails = cloudGraphOnboarding.extend(
  new RouteConfig({
    owners: ['growth'],
    definition: '/graph-details',
  }),
);

export const routerSetupRoutes = [
  endpointDetails.definition,
  graphDetails.definition,
];

export const cloudOnboardingRoutes = [
  cloudGraphOnboarding.definition,
  ...routerSetupRoutes,
];

export const graphOnboardingRoutes = [
  graphOnboarding.definition,
  ...cloudOnboardingRoutes,
];
