import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom-v5-compat';

import { useInternalGraphLinking } from 'src/app/graph/hooks/useInternalGraphLinking';
import {
  CheckTaskType,
  checkTasksRouteConfig,
  legacyBuildCheckDetailsRoute,
  legacyCustomCheckDetailsRoute,
  legacyDownstreamCheckDetailsRoute,
  legacyFilterBuildCheckDetailsRoute,
  legacyLintCheckDetailsRoute,
  legacyOperationsCheckDetailsRoute,
  legacyProposalBuildCheckDetailsRoute,
  legacyProposalCheckDetailsRoute,
  legacyProposalCustomCheckDetailsRoute,
  legacyProposalDownstreamCheckDetailsRoute,
  legacyProposalFilterBuildCheckDetailsRoute,
  legacyProposalLintCheckDetailsRoute,
  legacyProposalOperationsCheckDetailsRoute,
  legacyProposalProposalCheckDetailsRoute,
  proposalCheckTasksRouteConfig,
} from 'src/app/graph/routes';
import { NonPublicGraphArea } from 'src/app/graph/variantLayout/NonPublicGraphArea';
import { Loading } from 'src/components/common/loading/Loading';
import { NotFound } from 'src/components/common/notFound/NotFound';
import { ErrorMessage } from 'src/components/errorMessage/ErrorMessage';
import { PermissionBlockCard } from 'src/components/permissionGuards/permissionBlockCard/PermissionBlockCard';
import { PermissionGuard } from 'src/components/permissionGuards/permissionGuard/PermissionGuard';
import { useRouteParams } from 'src/hooks/useRouteParams';
import { ignorePermissionsErrors } from 'src/lib/apollo/catchErrors';
import { appLinkContext } from 'src/lib/apollo/link';
import { GraphQLTypes } from 'src/lib/graphqlTypes';
import { AnyRouteConfig } from 'src/lib/routeConfig/RouteConfig';

/**
 * The old check routes had the task id in the url, and we have migrated to having the workflow in the URL.
 * This means to be able to redirect, we need to first do all the same plan and permission checks.
 * So this nests the direct as a wrapper for the route content we can apply inside all those checks.
 */
function LegacyCheckRedirect({
  taskType,
  fromRouteConfig,
}: {
  taskType: CheckTaskType;
  fromRouteConfig: AnyRouteConfig;
}) {
  const location = useLocation();
  const { isProposalRoute } = useInternalGraphLinking();
  const redirectToRouteConfig = isProposalRoute
    ? proposalCheckTasksRouteConfig
    : checkTasksRouteConfig;

  const { graphId, taskId, graphVisibilityType } = useRouteParams(
    legacyBuildCheckDetailsRoute,
    legacyProposalBuildCheckDetailsRoute,
    legacyCustomCheckDetailsRoute,
    legacyProposalCustomCheckDetailsRoute,
    legacyDownstreamCheckDetailsRoute,
    legacyProposalDownstreamCheckDetailsRoute,
    legacyFilterBuildCheckDetailsRoute,
    legacyProposalFilterBuildCheckDetailsRoute,
    legacyLintCheckDetailsRoute,
    legacyProposalLintCheckDetailsRoute,
    legacyOperationsCheckDetailsRoute,
    legacyProposalOperationsCheckDetailsRoute,
    legacyProposalCheckDetailsRoute,
    legacyProposalProposalCheckDetailsRoute,
  );
  const { loading, data, error } = useQuery<
    GraphQLTypes.LegacyBuildTaskRedirectQuery,
    GraphQLTypes.LegacyBuildTaskRedirectQueryVariables
  >(
    gql`
      query LegacyBuildTaskRedirectQuery($graphId: ID!, $taskId: ID!) {
        service(id: $graphId) {
          id
          checkWorkflowTask(id: $taskId) {
            id
            workflow {
              id
              baseVariant {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      variables: { graphId, taskId },
      context: appLinkContext({ catchErrors: [ignorePermissionsErrors] }),
    },
  );
  const service = data?.service;
  const workflow = service?.checkWorkflowTask?.workflow;

  if (loading) {
    return <Loading />;
  } else if (error) {
    return <ErrorMessage error={error} />;
  } else if (
    !service ||
    (!workflow && isProposalRoute && graphVisibilityType === 'public')
  ) {
    return <NotFound />;
  } else if (!workflow) {
    return (
      <PermissionGuard
        graphId={graphId}
        graphPermissions={['canCheckSchemas', 'canQueryStats']}
        fallback={PermissionBlockCard}
      >
        <NotFound />
      </PermissionGuard>
    );
  } else {
    return (
      <Navigate
        replace={true}
        to={redirectToRouteConfig.locationFrom({
          location,
          patch: {
            taskType,
            checkId: workflow.id,
            graphVariant: workflow.baseVariant?.name,
          },
          fromRouteConfig,
        })}
      />
    );
  }
}

function legacyCheckTaskTypeRedirect(
  route: AnyRouteConfig,
  taskType: CheckTaskType,
  useNonPulbicGraphAreaBlock: boolean,
) {
  return route.patchV5DefinitionsForV6(false).map((path) => (
    <Route
      key={path}
      path={path}
      element={
        useNonPulbicGraphAreaBlock ? (
          <NonPublicGraphArea>
            <LegacyCheckRedirect taskType={taskType} fromRouteConfig={route} />
          </NonPublicGraphArea>
        ) : (
          <LegacyCheckRedirect taskType={taskType} fromRouteConfig={route} />
        )
      }
    />
  ));
}

export function legacyCheckRedirects() {
  return (
    <>
      {/* proposals */}
      {legacyCheckTaskTypeRedirect(
        legacyProposalBuildCheckDetailsRoute,
        'build',
        false,
      )}
      {legacyCheckTaskTypeRedirect(
        legacyProposalFilterBuildCheckDetailsRoute,
        'filter',
        false,
      )}
      {legacyCheckTaskTypeRedirect(
        legacyProposalOperationsCheckDetailsRoute,
        'operations',
        false,
      )}
      {legacyCheckTaskTypeRedirect(
        legacyProposalDownstreamCheckDetailsRoute,
        'downstream',
        false,
      )}
      {legacyCheckTaskTypeRedirect(
        legacyProposalCustomCheckDetailsRoute,
        'custom',
        false,
      )}
      {legacyCheckTaskTypeRedirect(
        legacyProposalLintCheckDetailsRoute,
        'linter',
        false,
      )}

      {/* regular */}
      {legacyCheckTaskTypeRedirect(legacyBuildCheckDetailsRoute, 'build', true)}
      {legacyCheckTaskTypeRedirect(
        legacyFilterBuildCheckDetailsRoute,
        'filter',
        true,
      )}
      {legacyCheckTaskTypeRedirect(
        legacyOperationsCheckDetailsRoute,
        'operations',
        true,
      )}
      {legacyCheckTaskTypeRedirect(
        legacyDownstreamCheckDetailsRoute,
        'downstream',
        true,
      )}
      {legacyCheckTaskTypeRedirect(
        legacyCustomCheckDetailsRoute,
        'custom',
        true,
      )}
      {legacyCheckTaskTypeRedirect(legacyLintCheckDetailsRoute, 'linter', true)}
      {legacyCheckTaskTypeRedirect(
        legacyProposalCheckDetailsRoute,
        'proposals',
        true,
      )}
    </>
  );
}
