import { gql, useQuery } from '@apollo/client';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
  Text,
} from '@apollo/orbit';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import * as orgRouteConfig from 'src/app/account/routes';
import { ContactRequestSentModal } from 'src/components/marketoUpgradeButton/ContactRequestSentModal';
import { MarketoUpgradeButton } from 'src/components/marketoUpgradeButton/MarketoUpgradeButton';
import { PermissionGuard } from 'src/components/permissionGuards/permissionGuard/PermissionGuard';
import { PricingLink } from 'src/components/pricingLink/PricingLink';
import { useCurrentPlan } from 'src/hooks/currentPlanV2Migration';
import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';
import { ampli } from 'src/lib/analytics/amplitude/vendor';
import { GraphQLTypes } from 'src/lib/graphqlTypes';

export const upgradeModalQuery = gql`
  query UpgradeModalQuery($accountId: ID!) {
    me {
      id
      ... on User {
        email
        fullName
      }
    }
    account(id: $accountId) {
      id
      internalID
    }
  }
`;

interface Props {
  onClose: () => void;
  isOpen: boolean;
  isDismissible?: boolean;
}

export const UpgradeModal = ({
  isOpen,
  onClose,
  isDismissible = true,
}: Props) => {
  const orgId = useCurrentAccountId()[0] || '';

  const { data } = useQuery<
    GraphQLTypes.UpgradeModalQuery,
    GraphQLTypes.UpgradeModalQueryVariables
  >(upgradeModalQuery, {
    variables: {
      accountId: orgId,
    },
    skip: !orgId,
  });

  const { currentPlan } = useCurrentPlan({
    accountId: orgId as string,
    skip: !orgId,
  });
  const location = useLocation();

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const homeLink = orgId ? orgRouteConfig.org.path({ orgId }) : '/';

  const me = data?.me;
  const userId = me?.__typename === 'User' ? me.id : '';
  const userEmail = me?.__typename === 'User' && me.email ? me.email : '';
  const userFullName = me?.__typename === 'User' ? me.fullName : '';

  if (currentPlan && isOpen) {
    ampli.upgradeOverlayViewed({
      'Page URL': location.pathname,
      'Plan Name': currentPlan.name,
    });
  }

  return orgId ? (
    <>
      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <PermissionGuard
            accountId={orgId}
            accountPermissions={['canSetConstrainedPlan']}
            fallback={
              <>
                <ModalHeader>
                  <ModalTitle>Restricted area</ModalTitle>
                </ModalHeader>
                <ModalBody>
                  <Text>
                    The permissions of your role do not include access to this
                    area. To obtain access, please contact your org admin.
                  </Text>
                </ModalBody>
                <ModalFooter>
                  <ButtonGroup>
                    <Button as={Link} variant="primary" to={homeLink}>
                      Back to Home
                    </Button>
                  </ButtonGroup>
                </ModalFooter>
              </>
            }
          >
            <>
              <ModalHeader>
                <ModalTitle>Unlock unlimited access to GraphOS</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Text>
                  Lift restrictions for your organization and allow your team
                  access to additional features of GraphOS.
                </Text>
                <Text>
                  Submitting this request will prompt the Apollo team to reach
                  out via email with more information about upgrade options.
                </Text>
              </ModalBody>
              <ModalFooter>
                <Button variant="secondary">
                  <PricingLink onClick={() => ampli.viewedPlans()}>
                    Review plans
                  </PricingLink>
                </Button>
                <ButtonGroup>
                  {isDismissible && (
                    <Button variant="secondary" type="button" onClick={onClose}>
                      Close
                    </Button>
                  )}
                  <MarketoUpgradeButton
                    onSuccess={() => {
                      setShowSuccessModal(true);
                      onClose();
                    }}
                    accountId={orgId}
                    internalOrgId={data?.account?.internalID ?? ''}
                    userId={userId}
                    userEmail={userEmail}
                    userFullName={userFullName}
                  >
                    Submit contact request
                  </MarketoUpgradeButton>
                </ButtonGroup>
              </ModalFooter>
            </>
          </PermissionGuard>
        </ModalContent>
      </Modal>
      <ContactRequestSentModal
        isOpen={showSuccessModal}
        onClose={() => {
          setShowSuccessModal(false);
        }}
      />
    </>
  ) : null;
};
