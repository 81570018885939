/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/core";
export const Restricted = React.forwardRef<
  SVGSVGElement,
  Omit<React.SVGProps<SVGSVGElement>, "css">
>((props, ref) => (
  <svg
    viewBox="0 0 370 220"
    ref={ref}
    css={{
      color: "#F4F6F8",
      height: "220px",
      width: "370px",
    }}
    {...props}
  >
    <defs>
      <ellipse id="a" cx={185} cy={110} rx={185} ry={110} />
      <ellipse id="c" cx={185} cy={110} rx={185} ry={110} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <ellipse cx={185} cy={110} fill="#EBEEF2" rx={185} ry={110} />
      <mask id="b" fill="white">
        <use xlinkHref="#a" />
      </mask>
      <g mask="url(#b)">
        <g transform="translate(11 13)">
          <path
            fill="#CAD0D8"
            d="M290 33c.581 0 1.053.509 1.053 1.137l-.001 1.81h1.812c.627 0 1.136.471 1.136 1.053 0 .581-.509 1.053-1.136 1.053l-1.812-.001v1.812c0 .627-.47 1.136-1.052 1.136-.581 0-1.053-.509-1.053-1.136v-1.812h-1.81c-.628 0-1.137-.47-1.137-1.052 0-.582.509-1.053 1.136-1.053h1.811v-1.81c0-.628.472-1.137 1.053-1.137zM80 15c.581 0 1.053.509 1.053 1.137l-.001 1.81h1.812c.627 0 1.136.471 1.136 1.053 0 .581-.509 1.053-1.136 1.053l-1.812-.001v1.812c0 .627-.47 1.136-1.052 1.136-.581 0-1.053-.509-1.053-1.136v-1.812h-1.81c-.628 0-1.137-.47-1.137-1.052 0-.582.509-1.053 1.136-1.053h1.811v-1.81c0-.628.472-1.137 1.053-1.137zM242 106c.581 0 1.053.509 1.053 1.137l-.001 1.81h1.812c.627 0 1.136.471 1.136 1.053 0 .581-.509 1.053-1.136 1.053l-1.812-.001v1.812c0 .627-.47 1.136-1.052 1.136-.581 0-1.053-.509-1.053-1.136v-1.812h-1.81c-.628 0-1.137-.47-1.137-1.052 0-.582.509-1.053 1.136-1.053h1.811v-1.81c0-.628.472-1.137 1.053-1.137zM336 97c.581 0 1.053.509 1.053 1.137l-.001 1.81h1.812c.627 0 1.136.471 1.136 1.053 0 .581-.509 1.053-1.136 1.053l-1.812-.001v1.812c0 .627-.47 1.136-1.052 1.136-.581 0-1.053-.509-1.053-1.136v-1.812h-1.81c-.628 0-1.137-.47-1.137-1.052 0-.582.509-1.053 1.136-1.053h1.811v-1.81c0-.628.472-1.137 1.053-1.137z"
          />
          <circle cx={33.5} cy={64.5} r={2.5} fill="#CAD0D8" />
          <circle cx={33.5} cy={64.5} r={1} fill="#EBEEF2" />
          <circle cx={158.5} cy={2.5} r={2.5} fill="#CAD0D8" />
          <circle cx={158.5} cy={2.5} r={1} fill="#EBEEF2" />
          <circle cx={256.5} cy={81.5} r={2.5} fill="#CAD0D8" />
          <circle cx={256.5} cy={81.5} r={1} fill="#EBEEF2" />
          <circle cx={309.5} cy={145.5} r={2.5} fill="#CAD0D8" />
          <circle cx={309.5} cy={145.5} r={1} fill="#EBEEF2" />
          <circle cx={84.5} cy={52.5} r={2.5} fill="#CAD0D8" />
          <circle cx={84.5} cy={52.5} r={1} fill="#EBEEF2" />
          <circle cx={18.5} cy={103.5} r={2.5} fill="#CAD0D8" />
          <circle cx={18.5} cy={103.5} r={1} fill="#EBEEF2" />
          <circle cx={54.5} cy={41.5} r={1.5} fill="#CAD0D8" />
          <circle cx={96.5} cy={89.5} r={1.5} fill="#CAD0D8" />
          <circle cx={57.5} cy={72.5} r={1.5} fill="#CAD0D8" />
          <circle cx={1.5} cy={72.5} r={1.5} fill="#CAD0D8" />
          <circle cx={175.5} cy={23.5} r={1.5} fill="#CAD0D8" />
          <circle cx={248.5} cy={6.5} r={1.5} fill="#CAD0D8" />
          <circle cx={241.5} cy={55.5} r={1.5} fill="#CAD0D8" />
          <circle cx={286.5} cy={64.5} r={1.5} fill="#CAD0D8" />
          <circle cx={161.5} cy={95.5} r={1.5} fill="#CAD0D8" />
          <circle cx={81.5} cy={136.5} r={1.5} fill="#CAD0D8" />
          <circle cx={29.5} cy={144.5} r={1.5} fill="#CAD0D8" />
          <circle cx={263.5} cy={161.5} r={1.5} fill="#CAD0D8" />
          <circle cx={240.5} cy={144.5} r={1.5} fill="#CAD0D8" />
          <circle cx={109.5} cy={19.5} r={1.5} fill="#CAD0D8" />
          <circle cx={320.5} cy={48.5} r={1.5} fill="#CAD0D8" />
          <circle cx={310.5} cy={82.5} r={1.5} fill="#CAD0D8" />
        </g>
        <g stroke="#CAD0D8" strokeLinejoin="round" strokeWidth={4}>
          <path strokeLinecap="round" d="M140 19v16" />
          <path fill="#CAD0D8" d="M140 35.039L148.5 47h-17z" />
          <path fill="#CAD0D8" d="M140 36.647l16 44.334h-32z" />
          <path fill="#CAD0D8" d="M140 76l25 67h-50zM131 48h18v12h-18z" />
          <path fill="#CAD0D8" d="M124 82h32v42h-32zM115 144h50v86h-50z" />
        </g>
        <g stroke="#CAD0D8" transform="translate(150 11.5)">
          <rect width={38} height={8} x={10} y={36.5} strokeWidth={3} rx={2} />
          <rect width={31} height={8} x={17} y={72.5} strokeWidth={3} rx={2} />
          <rect width={27} height={8} x={21} y={108.5} strokeWidth={3} rx={2} />
          <rect width={22} height={8} x={26} y={136.5} strokeWidth={3} rx={2} />
          <rect width={22} height={8} x={26} y={180.5} strokeWidth={3} rx={2} />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={3}
            d="M37.5.5h54M4 36.5h9M12 108.5h12M20 136.5h12M20 180.5h12M11 72.5h9"
          />
          <path
            strokeLinejoin="round"
            strokeWidth={3}
            d="M47.5 18.5h40M47.5 36.5h40M47.5 54.5h40M47.5 72.5h40M47.5 90.5h40M47.5 108.5h40M47.5 126.5h40M47.5 144.5h40M47.5 162.5h40M47.5 180.5h40M47.5 198.5h40"
          />
          <rect
            width={40}
            height={218}
            x={48}
            y={0.5}
            strokeLinejoin="round"
            strokeWidth={3}
            rx={2}
          />
          <path
            strokeLinejoin="round"
            strokeWidth={3}
            d="M68 .5l20 18H48zM68 18.5l20 18H48zM68 36.5l20 18H48zM68 54.5l20 18H48zM68 72.5l20 18H48zM68 90.5l20 18H48zM68 108.5l20 18H48zM68 126.5l20 18H48zM68 144.5l20 18H48zM68 162.5l20 18H48zM68 180.5l20 18H48zM68 198.5l20 18H48z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={3}
            d="M79.5 1v213"
          />
          <rect
            width={65}
            height={7}
            x={48}
            y={29.5}
            fill="#EBEEF2"
            strokeWidth={3}
            rx={2}
          />
          <circle cx={111} cy={32.5} r={6.5} fill="#EBEEF2" strokeWidth={3} />
          <path
            strokeLinejoin="round"
            strokeWidth={3}
            d="M21 36.5l8 8H13zM56 29.5l8 7H48zM72 29.5l8 7H64zM88 29.5l8 7H80zM104.017 36.5H96l8-7M28 72.5l8 8H20z"
          />
          <path
            strokeLinejoin="round"
            strokeWidth={3}
            d="M47.718 80.5H35l8-8 4.727 4.727M32 108.5l8 8H24zM37 136.5l8 8H29zM37 180.5l8 8H29z"
          />
          <path
            strokeLinejoin="round"
            strokeWidth={3}
            d="M48.059 116.5H39l8-8M36 36.5l8 8H28zM48 44.5h-4l4.009-4.009"
          />
          <g strokeWidth={2}>
            <path d="M0 37c0 1.933 1.12 3.5 2.5 3.5S5 38.933 5 37M7 72.5C7 74.433 8.12 76 9.5 76s2.5-1.567 2.5-3.5M16 181.5c0 1.933 1.12 3.5 2.5 3.5s2.5-1.567 2.5-3.5M7 108.5c0 1.933 1.343 3.5 3 3.5s3-1.567 3-3.5" />
          </g>
        </g>
      </g>
      <g>
        <mask id="d" fill="white">
          <use xlinkHref="#c" />
        </mask>
        <g strokeWidth={4} mask="url(#d)">
          <g stroke="#2F353F">
            <path d="M73 113c0 19.882 49.92 36 111.5 36S296 132.882 296 113M296 113c0 19.882 54.397 36 121.5 36 67.103 0 121.5-16.118 121.5-36M-170 113c0 19.882 54.397 36 121.5 36C18.603 149 73 132.882 73 113" />
          </g>
          <g
            fill="#DEE2E7"
            stroke="#2F353F"
            strokeLinejoin="round"
            transform="translate(63 110)"
          >
            <rect width={20} height={110} rx={4} />
            <rect width={20} height={110} x={223} rx={4} />
          </g>
          <g transform="translate(153 140)">
            <rect
              width={63}
              height={42}
              fill="#F18686"
              stroke="#2F353F"
              strokeLinejoin="round"
              rx={4}
            />
            <g stroke="#FFFFFF" transform="translate(19 9)">
              <circle cx={12} cy={12} r={12} />
              <path strokeLinecap="round" d="M20.132 3.868L3.868 20.132" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
));
