/* eslint-disable @typescript-eslint/naming-convention */
/**
 * This file is generated, to regenerate it, run npm run generate-feature-flags
 */
export const LDFlagDefaults = {
  'admin-app-enable-query-batching': false,
  'astro-growth-unified-onboarding-survey': false,
  'astro-identity-custom-session-duration': false,
  'astro-identity-revoke-session-ui': true,
  'astro-identity-self-service-flow-for-net-new': true,
  'astro-identity-self-service-flow-for-reconfigure': true,
  'astro-identity-sso-validate-login': true,
  'astro-idp-claims-logging': false,
  'astro-role-assignment-from-idp': true,
  'astro-self-serve-sso': true,
  'astro-self-serve-sso-login-validation': true,
  'astro-self-serve-sso-migration-banner': true,
  'astro-user-password-studio-check': false,
  'betelgeuse-amplitude-in-embedded': true,
  'betelgeuse-custom-domains': false,
  'betelgeuse-demo-supergraph-in-enterprise-trial': true,
  'betelgeuse-serverless-aws-ui': true,
  'connectors-show-debug-pane': true,
  connectors_router_version: '2.0.0',
  'edu-summit-banner-studio-ui': false,
  fed_next_enabled: true,
  'federation-2-10-version': '2.10.0',
  'federation-next-version': '2.11.0-preview.0',
  'gov-pq-ux-sort-filter-delete': false,
  'gov-proposal-checks-descriptions-warn': true,
  'gov-upgrade-proposal-variant-fed-version': false,
  'growth-allow-plan-readable-id': false,
  'growth-connectors-onboarding-fed-version': '2.10.0',
  'growth-default-dx-schema-for-connectors-onboarding': true,
  'growth-default-studio-plan': 'graphos-free-v1',
  'growth-enable-redesigned-zero-state': true,
  'growth-hide-connectors-preview-badges': true,
  'growth-show-cc-for-stripe': false,
  'growth-show-pending-and-registered-member-page': false,
  'growth-show-rover-init-cta': false,
  'growth-show-user-profile-survey': true,
  houston_schema_publisher_migration: true,
  'insights-coordinate-usage-partition-count-override': 0,
  'insights-error-categorization-ui': false,
  'insights-remove-stored-signatures-from-kafka-reports': true,
  'insights-use-new-operation-overview-druid-queries': true,
  'insights-use-optimised-variant-lra-query': true,
  mercury_show_hide_unreachable_types_toggle: true,
  'nebula-postflight-scripts': true,
  'nebula-proposals-downstream-checks': false,
  'nebula-proposals-ga': true,
  'nebula-proposals-linter': true,
  'nebula-proposals-m3': true,
  'nebula-proposals-revision-history': true,
  'nebula-reapproval-required-on-change': true,
  'nebula-visualization-sandbox': false,
  nebula_proposals_merge_upstream: true,
  nebula_proposals_webhooks_fe: true,
  offline_license: false,
  'operations-l1-commercial-router-entitlements': true,
  ops_Uplink_UseProxiedAuroraRDSPercent: 100,
  'orion-max-schemapublisher-attempts': 50,
  'orion-show-composition-rules': true,
  orion_fed_alpha: true,
  'persisted-queries-operation-client-name-display': true,
  'pulsar-br-74-insights-ui': true,
  'pulsar-disable-stats-for-error-stats': false,
  'pulsar-disable-stats-for-field-executions': false,
  'pulsar-disable-stats-for-field-latencies': false,
  'pulsar-disable-stats-for-field-usage': false,
  'pulsar-disable-stats-for-query-stats': false,
  'pulsar-enable-fed-version-2-7': false,
  'pulsar-hide-legacy-fields-new-org': false,
  'pulsar-log-kafka-partitions': false,
  'pulsar-max-coordinate-usage-partitions-per-report': 32,
  'pulsar-min-coordinate-usage-records-per-partition': 2500,
  'pulsar-omit-realtime-data': false,
  'pulsar-partition-stats-by-graph': true,
  'pulsar-partition-stats-by-graph-spread': 4,
  'pulsar-safe-removal-of-type-from-composite': true,
  'pulsar-send-coordinate-usage': 100,
  'pulsar-send-field-usage-to-coordinate-usage': 0,
  'pulsar-send-field-usage-to-coordinate-usage-stream': 100,
  'pulsar-send-to-coordinate-usage-stream-topic': 0,
  'pulsar-send-to-report-stats-stream-topic': 0,
  'pulsar-show-downgrade-checks-config': true,
  'pulsar-show-operations-l1-subscriptions-filter': true,
  'pulsar-show-subscription-event-charts-on-olp': true,
  'pulsar-show-subscription-events-in-olp-client-usage-card': true,
  'pulsar-trace-rollup-enabled': true,
  pulsar_displayListAnalyticsLargeSchemas: true,
  pulsar_fed2Onboarding: true,
  pulsar_restrictClientVersions: true,
  'pulsar_send-defer-feature-usage-analytics': true,
  'pulsar_send-router-feature-usage-analytics': true,
  'pulsar_show-new-insights-operations-ui': true,
  pulsar_showFieldLandingPage: true,
  pulsar_skipDowngradeForStaticChecks: true,
  'pulsar_use-hourly-trace-cache': 100,
  'studio-amplitude-tracking-enabled': true,
  'studio-dark-mode': false,
  'studio-rebrand': true,
  support_jsm_ui: true,
};
