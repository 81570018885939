import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';
import { MenuContentStyles as MenuContent } from '../MenuContent/MenuContent.style';
import { MenuDividerStyles as MenuDivider } from '../MenuDivider/MenuDivider.style';
import { MenuGroupStyles } from '../MenuGroup/MenuGroup.style';
import { MenuHeaderStyles } from '../MenuHeader/MenuHeader.style';
import {
  MenuItemStyles as MenuItem,
  MenuItemChildrenTruncatedStyles,
  MenuItemContainerStyles,
  MenuItemContentStyles,
  MenuItemDescriptionStyles,
  MenuItemIconStyles,
  MenuItemRightIconStyles,
} from '../MenuItem/MenuItem.style';
import { MenuListStyles } from '../MenuList/MenuList.style';
import { MenuTitleStyles } from '../MenuTitle/MenuTitle.style';

const customKeys = [
  'body' as const,
  'header' as const,
  'title' as const,
  'label' as const,
  'labelDescription' as const,
  'labelContainer' as const,
  'itemContainer' as const,
  'itemContent' as const,
  'itemIcon' as const,
  'itemRightIcon' as const,
  'itemOptionContainer' as const,
  'itemOptionContent' as const,
  'itemDescription' as const,
  'itemOptionDescription' as const,
  'group' as const,
  'optionGroupLabel' as const,
  'optionGroupLabelDescription' as const,
  // Due to how chakra merges styles, we need to use a custom key vs `button` to make sure we don't accidentally override the button styles when we use the `as` prop
  'buttonOverride' as const,
];

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers([...menuAnatomy.keys, ...customKeys]);

const baseStyle = definePartsStyle({
  list: MenuContent,
  item: MenuItem,
  divider: MenuDivider,
  labelContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    px: 4,
  },
  label: {
    mx: 0,
    fontWeight: 600,
    color: customColors.text.heading,
  },
  labelDescription: {
    fontSize: 'sm',
    lineHeight: 'sm',
    color: customColors.text.secondary,
  },
  buttonOverride: {
    '& > span': {
      flex: 'unset',
    },
  },
  group: MenuGroupStyles,
  itemContainer: MenuItemContainerStyles,
  itemContent: MenuItemContentStyles,
  itemIcon: MenuItemIconStyles,
  itemChildrenTruncated: MenuItemChildrenTruncatedStyles,
  itemRightIcon: MenuItemRightIconStyles,
  itemDescription: MenuItemDescriptionStyles,
  header: MenuHeaderStyles,
  title: MenuTitleStyles,
  listContainer: MenuListStyles,
});

export const MenuStyles = defineMultiStyleConfig({ baseStyle });
