import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom-v5-compat';

import {
  LoggedInRoute,
  LoggedOutRoute,
  PublicRoute,
} from 'src/components/common/authenticatedRoutes/AuthenticatedRoutes';
import { LazyUrlModal } from 'src/components/common/LazyUrlModal';
import { Loading } from 'src/components/common/loading/Loading';
import Config from 'src/lib/config';

import { ChangePasswordPage } from '../account/changePasswordPage/ChangePasswordPage';
import { invite } from '../account/routes';
import { UpgradeModal } from '../account/upgradeModal/UpgradeModal';

import { OnboardingLayout } from './components';
import * as routes from './routes/routes';
import { ForgotPasswordPage } from './views/forgotPasswordPage/ForgotPasswordPage';
import { LoginPage } from './views/loginPage/LoginPage';
import { SSOConfigurationPage } from './views/ssoPage/SSOConfigurationPage';
import { SSOPage } from './views/ssoPage/SSOPage';

const EmailVerificationPage = React.lazy(async () => ({
  default: (
    await import(
      /* webpackChunkName: "EmailVerificationPage" */ './views/emailVerificationPage/EmailVerificationPage'
    )
  ).EmailVerificationPage,
}));

const CombinedSignup = React.lazy(async () => ({
  default: (
    await import(
      /* webpackChunkName: "CombinedSignup" */ './views/signup/CombinedSignup'
    )
  ).CombinedSignup,
}));

const Welcome = React.lazy(async () => ({
  default: (
    await import(/* webpackChunkName: "Welcome" */ './views/welcome/Welcome')
  ).Welcome,
}));

/**
 * Enumeration of all the routes this portion of the application is responsible
 * for. We're using react-router-dom `Switch`, which does not support nesting
 * `Route`s inside of components
 */
export const ownedRoutes: string[] = [
  routes.login.splatDefinition,
  routes.loginWithApolloSSO.splatDefinition,
  routes.ssoConfiguration.splatDefinition,
  invite.splatDefinition,
  routes.prodGraphLanding.splatDefinition,
  routes.signup.splatDefinition,
  routes.welcome.splatDefinition,
  routes.forgotPassword.splatDefinition,
  routes.verifyEmail.splatDefinition,
  routes.resetPassword.splatDefinition,
];

/**
 * # Docs
 *
 * [`/dev`](https://mermaid-js.github.io/mermaid-live-editor/#/edit/eyJjb2RlIjoiZ3JhcGggVERcbiAgICBBUlJJVkUoYXJyaXZlIGF0IC9kZXYpIC0tPiBMT0dHRURJTnthbHJlYWR5IGxvZ2dlZCBpbj99XG4gICAgTE9HR0VESU4gLS0-IHxZZXN8IE9SR1NcbiAgICBMT0dHRURJTiAtLT4gfE5vfCBTSUdOVVB7L3NpZ251cDogU2lnbiB1cCBvciBTaWduIElufVxuICAgIFNJR05VUCAtLT4gfFNpZ24gSW58IExPR0lOKExvZ2luIHdpdGggZW1haWwgb3IgR2l0SHViKVxuICAgIFNJR05VUCAtLT4gfFNpZ24gVXB8IFdFTENPTUVcbiAgICBMT0dJTiAtLT4gT1JHU3tIb3cgbWFueSBvcmdzP31cbiAgICBPUkdTIC0tPiB8MHwgV0VMQ09NRShcIi93ZWxjb21lXCIpXG4gICAgT1JHUyAtLT4gfDF8IE1PREFMXG4gICAgT1JHUyAtLT4gfD4xfCBQSUNLT05FKFBpY2sgT25lKVxuICAgIFBJQ0tPTkUgLS0-IE1PREFMXG4gICAgV0VMQ09NRSAtLT4gTU9EQUwoXCJkZXYgZ3JhcGggbW9kYWxcIikiLCJtZXJtYWlkIjp7InRoZW1lIjoiZGVmYXVsdCJ9LCJ1cGRhdGVFZGl0b3IiOmZhbHNlfQ)
 *
 */

export const Onboarding = () => {
  const location = useLocation();

  return (
    <React.Suspense fallback={<Loading />}>
      <Switch>
        <Route
          path={routes.prodGraphLanding.definition}
          exact={true}
          render={() => (
            <Navigate
              replace={true}
              to={routes.signup.locationFrom({
                location,
                fromRouteConfig: routes.prodGraphLanding,
                patch: { type: 'prod' },
              })}
            />
          )}
        />
        <Route
          path={invite.definition}
          exact={true}
          render={() => (
            <Navigate
              replace={true}
              to={routes.signup.locationFrom({
                location,
                fromRouteConfig: invite,
                patch: { type: 'invite' },
              })}
            />
          )}
        />
        <Route
          exact
          path={routes.signup.definition}
          render={() => <CombinedSignup />}
        />
        <LoggedOutRoute
          exact
          path={routes.loginWithApolloSSO.definition}
          render={() => <SSOPage returnToUrl={window.location.origin} />}
        />
        <PublicRoute
          path={routes.ssoConfiguration.definition}
          render={() => <SSOConfigurationPage />}
        />
        <OnboardingLayout>
          <React.Suspense fallback={<Loading />}>
            <Switch>
              <LoggedOutRoute
                exact
                path={routes.login.definition}
                component={LoginPage}
              />
              <LoggedOutRoute
                exact
                path={routes.forgotPassword.definition}
                component={ForgotPasswordPage}
              />
              <Route
                exact
                path={routes.verifyEmail.definition}
                render={() => (
                  <LoggedInRoute Component={EmailVerificationPage} />
                )}
              />
              <LoggedOutRoute
                exact
                path={routes.resetPassword.definition}
                component={ChangePasswordPage}
              />
              <Route
                path={routes.welcome.definition}
                render={() => (
                  <>
                    <Welcome />
                    <LazyUrlModal name={Config.modals.upgrade}>
                      {({ isOpen }) => (
                        <UpgradeModal
                          onClose={() => {}}
                          isOpen={isOpen}
                          isDismissible={false}
                        />
                      )}
                    </LazyUrlModal>
                  </>
                )}
              />
            </Switch>
          </React.Suspense>
        </OnboardingLayout>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </React.Suspense>
  );
};
