import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Button, ButtonGroup } from '@apollo/orbit';
import React, { useCallback, useMemo } from 'react';
import { Redirect } from 'react-router-dom';

import * as onboardingRoutes from 'src/app/onboarding/routes';
import { Loading } from 'src/components/common/loading/Loading';
import { ContactSupportLink } from 'src/components/ContactSupportCTAs';
import { ErrorMessage } from 'src/components/errorMessage/ErrorMessage';
import { PermissionBlock } from 'src/components/permissionGuards/permissionGuard/PermissionGuard';
import { useShowToast } from 'src/components/toast/Toast';
import { useIdentity } from 'src/hooks/useIdentity';
import { useRouteParams } from 'src/hooks/useRouteParams';
import { useTrackCustomEvent } from 'src/hooks/useTrackCustomEvent';
import { GraphQLTypes } from 'src/lib/graphqlTypes';
import {
  EMBEDDABLE_EXPLORER_URL,
  EMBEDDABLE_SANDBOX_URL,
} from 'src/lib/routers/embedded';

import { STUDIO_USER_TOKEN_FOR_EMBED } from '../graph/explorerPage/helpers/postMessageHelpers';
import { parseGraphRef } from '../graph/hooks/useGraphRef';

import { AuthorizeEmbedCard } from './AuthorizeEmbedCard';
import { EmbedAuthenticatorLayoutBackground } from './EmbedAuthenticatorLayoutBackground';

export const EmbedAuthenticator = () => {
  const { origin, embedSubdomain, graphRef, type, inviteToken, accountId } =
    useRouteParams(onboardingRoutes.embedAuthenticationRouteConfig);
  const graphId = parseGraphRef(graphRef)?.graphId;
  const graphVariant = parseGraphRef(graphRef)?.graphVariant;

  const { data, loading, error } = useQuery<
    GraphQLTypes.EmbeddedAuthenticatorQuery,
    GraphQLTypes.EmbeddedAuthenticatorQueryVariables
  >(
    gql`
      query EmbeddedAuthenticatorQuery($graphRef: ID!) {
        variant(ref: $graphRef) {
          ... on GraphVariant {
            id
            permissions {
              canQuerySchemas
            }
          }
        }
      }
    `,
    {
      variables: {
        graphRef: graphRef ?? '',
      },
      skip: !graphRef,
    },
  );

  const [provisionNewUserKey] = useMutation<
    GraphQLTypes.GenerateEmbedApiKeyMutation,
    GraphQLTypes.GenerateEmbedApiKeyMutationVariables
  >(
    gql`
      mutation GenerateEmbedApiKeyMutation($keyName: String!) {
        me {
          ... on UserMutation {
            newKey(keyName: $keyName) {
              id
              token
            }
          }
        }
      }
    `,
    {
      onCompleted: (returnData) => {
        if (returnData.me?.__typename === 'UserMutation') {
          window.opener.postMessage(
            {
              name: STUDIO_USER_TOKEN_FOR_EMBED,
              id: returnData.me.newKey.id,
              token: returnData.me.newKey.token,
              graphRef,
            },
            embedSubdomain === 'explorer'
              ? EMBEDDABLE_EXPLORER_URL
              : EMBEDDABLE_SANDBOX_URL,
          );
          window.close();
        }
      },
      onError: (e) => {
        throw e;
      },
    },
  );

  const acceptInvitationMutation = gql<
    GraphQLTypes.EmbedAcceptInvitationMutation,
    GraphQLTypes.EmbedAcceptInvitationMutationVariables
  >`
    mutation EmbedAcceptInvitationMutation($orgId: ID!, $joinToken: String!) {
      joinAccount(accountId: $orgId, joinToken: $joinToken) {
        id
      }
    }
  `;
  const apolloClient = useApolloClient();

  const [acceptInvitation, { loading: inviteLoading }] = useMutation(
    acceptInvitationMutation,
    {
      onCompleted() {
        apolloClient.cache.evict({
          id: apolloClient.cache.identify({
            __typename: me?.__typename,
            id: me?.id,
          }),
        });
        apolloClient.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'me',
        });
        apolloClient.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'variant',
        });
        apolloClient.cache.gc();
      },
    },
  );

  const { me, meLoading } = useIdentity();
  const { showToasts } = useShowToast();
  const trackCustomEvent = useTrackCustomEvent();
  const accountIds = useMemo(
    () =>
      me?.__typename === 'User'
        ? me.memberships.map((membership) => membership.account.id)
        : [],
    [me],
  );

  const acceptOrgInvitation = useCallback(async () => {
    if (accountId && !accountIds.includes(accountId) && inviteToken) {
      try {
        await acceptInvitation({
          variables: {
            orgId: accountId,
            joinToken: inviteToken,
          },
        });
      } catch (inviteError) {
        const errorMessage = (inviteError as Error).message.replace(
          /Exception while fetching data \(.*?\)[:\s]*/i,
          '',
        );
        showToasts({
          heading: 'Invitation error',
          level: 'error',
          message: (
            <>
              {errorMessage.match(/invalid token/i) ? (
                <>
                  Looks like the invite link you’re using has expired. Reach out
                  to the sender and ask for a new invite.
                </>
              ) : (
                <>
                  We received an unexpected error trying to join: "
                  {errorMessage}
                  ". Please double check the link or{' '}
                  <ContactSupportLink className="text-error">
                    contact support
                  </ContactSupportLink>
                </>
              )}
            </>
          ),
        });
      }
      // fire ga4 event
      trackCustomEvent({
        action: 'auto_invite_flow',
        category: 'Embeddable Explorer',
        label: 'Auto invite to org',
      });
    }
  }, [
    acceptInvitation,
    accountId,
    accountIds,
    inviteToken,
    trackCustomEvent,
    showToasts,
  ]);

  if (loading || meLoading || inviteLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <EmbedAuthenticatorLayoutBackground>
        <ErrorMessage error={error} />
      </EmbedAuthenticatorLayoutBackground>
    );
  }

  // User is not logged in
  if (!me) {
    return (
      <Redirect
        to={onboardingRoutes.login.path({
          from: `${window.location.pathname}${window.location.search}`,
          type,
          joinToken: inviteToken,
          orgId: accountId,
        })}
      />
    );
  }

  const canQuerySchemas =
    data?.variant?.__typename === 'GraphVariant' &&
    data.variant.permissions?.canQuerySchemas;

  const user = me.__typename === 'User' ? me : null;
  // User is not in organization with graph
  if (graphRef && !canQuerySchemas) {
    return (
      <EmbedAuthenticatorLayoutBackground>
        <AuthorizeEmbedCard user={user}>
          {inviteToken ? (
            <>
              <p className="mb-4">
                You've been invited to join{' '}
                <span className="font-semibold">{accountId}</span>
              </p>
              <Button onClick={acceptOrgInvitation}>Accept invitation</Button>
            </>
          ) : (
            <PermissionBlock />
          )}
        </AuthorizeEmbedCard>
      </EmbedAuthenticatorLayoutBackground>
    );
  }

  return (
    <EmbedAuthenticatorLayoutBackground>
      <AuthorizeEmbedCard user={user}>
        <div className="mb-10 text-2xl font-semibold">
          Authorize access to your Studio account
        </div>
        <div className="mb-6">
          <span className="font-semibold">{origin}</span> needs access to your
          Studio account{' '}
          {graphRef && (
            <>
              {' '}
              for graph{' '}
              <b>
                {graphId}@{graphVariant}
              </b>
            </>
          )}
          .
        </div>
        <ButtonGroup className="mt-16 justify-end">
          <Button
            type="button"
            size="lg"
            variant="secondary"
            onClick={() => {
              window.close();
            }}
          >
            Cancel
          </Button>
          <Button
            type="button"
            size="lg"
            onClick={() => {
              provisionNewUserKey({
                variables: {
                  keyName: `Studio generated API Key for Embedded Explorer on ${origin} ${
                    graphRef ? `for graphRef ${graphRef}.` : '.'
                  }`,
                },
              });
            }}
          >
            Authorize
          </Button>
        </ButtonGroup>
      </AuthorizeEmbedCard>
    </EmbedAuthenticatorLayoutBackground>
  );
};
