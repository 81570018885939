import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { ignorePermissionsErrors } from 'src/lib/apollo/catchErrors';
import { appLinkContext } from 'src/lib/apollo/link';
import {
  BillingPlanKind,
  UseCurrentPlanQuery,
  UseCurrentPlanQueryVariables,
} from 'src/lib/graphqlTypes/types';

interface Args extends UseCurrentPlanQueryVariables {
  skip?: boolean;
}

const isAccountTypeCloudy = (billingPlanKind: BillingPlanKind | undefined) => {
  if (billingPlanKind) {
    return [
      'DEDICATED',
      'SERVERLESS',
      'SERVERLESS_FREE',
      'SERVERLESS_PAID',
    ].includes(billingPlanKind.toString());
  } else {
    return false;
  }
};

const isAccountTypeEnterprise = (
  billingPlanKind: BillingPlanKind | undefined,
) => {
  if (billingPlanKind) {
    return [
      'ENTERPRISE_INTERNAL',
      'ENTERPRISE_PAID',
      'ENTERPRISE_PILOT',
      'ENTERPRISE_TRIAL',
    ].includes(billingPlanKind.toString());
  } else {
    return false;
  }
};

export function useCurrentPlan({ skip = false, ...variables }: Args) {
  const currentPlanResult = useQuery<
    UseCurrentPlanQuery,
    UseCurrentPlanQueryVariables
  >(
    gql<UseCurrentPlanQuery, UseCurrentPlanQueryVariables>`
      query UseCurrentPlanQuery($accountId: ID!) {
        account(id: $accountId) {
          id
          isOnExpiredTrial
          isLocked
          currentPlan {
            id
            billingModel
            billingPeriod
            clients
            clientVersions
            kind
            contracts
            datadog
            isTrial
            launches
            maxAuditInDays
            maxRangeInDays
            maxRequestsPerMonth
            maxRangeInDaysForChecks
            name
            notifications
            persistedQueries
            ranges
            tier
            traces
            userRoles
            webhooks
          }
        }
      }
    `,
    {
      variables,
      skip,
      context: appLinkContext({ catchErrors: [ignorePermissionsErrors] }),
    },
  );

  return useMemo(() => {
    const account = currentPlanResult.data?.account;
    const currentPlan = account?.currentPlan;

    return {
      currentPlan: currentPlan && {
        ...currentPlan,
        /** @deprecated use useCapabilities().capabilities?.userRoles instead */
        userRoles: currentPlan.userRoles,
        /** @deprecated use useCapabilities().capabilities?.clients instead */
        clients: currentPlan.clients,
        /** @deprecated use useCapabilities().capabilities?.clientVersions instead */
        clientVersions: currentPlan.clientVersions,
        /** @deprecated use useCapabilities().capabilities?.webhooks instead */
        webhooks: currentPlan.webhooks,
        /** @deprecated use useCapabilities().capabilities?.datadog instead */
        datadog: currentPlan.datadog,
        /** @deprecated use useCapabilities().capabilities?.notifications instead */
        notifications: currentPlan.notifications,
        /** @deprecated use useCapabilities().capabilities?.contracts instead */
        contracts: currentPlan.contracts,
        /** @deprecated use useCapabilities().capabilities?.traces instead */
        traces: currentPlan.traces,
        /** @deprecated use useCapabilities().capabilities?.persistedQueries instead */
        persistedQueries: currentPlan.persistedQueries,
        /** @deprecated use useCapabilities().capabilities?.launches instead */
        launches: currentPlan.launches,
      },
      isOnExpiredTrial: account?.isOnExpiredTrial ?? false,
      isCloudyAccountType: isAccountTypeCloudy(currentPlan?.kind),
      isEnterpriseAccount: isAccountTypeEnterprise(currentPlan?.kind),
      isLocked: account?.isLocked,
      loading: currentPlanResult.loading,
      error: currentPlanResult.error,
    };
  }, [currentPlanResult]);
}
