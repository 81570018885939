import IconCopy from '@apollo/icons/small/IconCopy.svg';
import { chakra, useMultiStyleConfig } from '@chakra-ui/react';
import _ from 'lodash';
import React, { forwardRef } from 'react';

import {
  IconButton,
  Text,
  TextProps,
  Tooltip,
  useDisclosure,
} from '../../../index';

export type CodeProps = Omit<
  TextProps<'code'>,
  'as' | 'size' | 'variant' | '__orbitStyleConfig' | 'children'
> & {
  variant?: 'mono' | 'fullColor';
  buttonRef?: React.Ref<HTMLButtonElement>;
  onCopy?: () => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
} & (
    | {
        canCopy?: false;
        children: React.ReactNode;
        text?: undefined;
      }
    | {
        canCopy: boolean;
        children: string;
        text?: undefined;
      }
    | {
        canCopy: true;
        children: Exclude<React.ReactNode, string>;
        /** If children are not a string, you must pass `text` for the copy value */
        text: string;
      }
  );

export const Code = forwardRef<HTMLElement, CodeProps>((props, ref) => {
  const { buttonRef, onCopy, onKeyDown, ...rest } = _.omit(props, [
    'children',
    'canCopy',
    'text',
  ]);
  const styles = useMultiStyleConfig('Code', rest);
  const { isOpen, onToggle, onClose } = useDisclosure({
    defaultIsOpen: false,
  });

  const copyToClipboard = async () => {
    try {
      onToggle();
      await navigator.clipboard.writeText(
        props.text ?? (props.children as string),
      );
      await asyncTimeout(() => onClose(), 2000);

      if (onCopy) {
        onCopy();
      }
    } catch (error) {
      // At the moment, do nothing, but we should handle this in the future
    }
  };

  // Handle keyboard events on the button
  const handleKeyDown = (e: React.KeyboardEvent) => {
    // Default Enter/Space handling
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      copyToClipboard();
    }

    // Forward any additional key handling to the parent's onKeyDown
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  return (
    <chakra.code {...rest} ref={ref} as="code" __css={styles.container}>
      <Text size="sm" variant="code" __orbitStyleConfig={styles.text}>
        {props.children}
      </Text>
      {props.canCopy && (
        <Tooltip isOpen={isOpen} label="Copied" placement="top">
          <IconButton
            ref={buttonRef}
            size="xs"
            icon={<IconCopy />}
            variant="hidden"
            aria-label="Copy"
            onClick={copyToClipboard}
            onKeyDown={handleKeyDown}
            __orbitStyleConfig={styles.button}
          />
        </Tooltip>
      )}
    </chakra.code>
  );
});

const asyncTimeout = (fn: () => void, ms: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      fn();
      resolve();
    }, ms);
  });
};
