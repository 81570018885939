import { gql, useMutation, useQuery } from '@apollo/client';
import * as jsoncParser from 'jsonc-parser';
import _ from 'lodash';
import mustache from 'mustache';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { useRouteMatch } from 'react-router';

import { JSONObject } from 'src/app/graph/explorerPage/helpers/types';
import {
  useLocalStorageGraphIdentifier,
  usePerGraphIdentifierLocalStorage,
} from 'src/app/graph/explorerPage/hooks/usePerGraphIdentifierLocalStorage';
import { GraphRef } from 'src/app/graph/hooks/useGraphRef';
import { sandboxGraphRouteConfig } from 'src/app/graph/routes';
import { ignorePermissionsErrors } from 'src/lib/apollo/catchErrors';
import { appLinkContext } from 'src/lib/apollo/link';
import { GraphQLTypes } from 'src/lib/graphqlTypes';

import { usePerTabLocalStorage } from '../../usePerTabLocalStorage';

import { HeaderEntry, INITIAL_HEADERS } from './shared';

const INITIAL_REMOTE_CHECKED_STATE = {};

const useHeadersManager = ({ graphRef }: { graphRef: GraphRef | null }) => {
  const { data } = useQuery<
    GraphQLTypes.UseHeadersManagerQuery,
    GraphQLTypes.UseHeadersManagerQueryVariables
  >(
    gql`
      query UseHeadersManagerQuery($serviceId: ID!, $graphVariant: String!) {
        service(id: $serviceId) {
          id
          variant(name: $graphVariant) {
            id
            sharedHeaders
          }
        }
      }
    `,
    {
      variables: {
        serviceId: graphRef?.graphId as string,
        graphVariant: graphRef?.graphVariant as string,
      },
      skip: !graphRef,
      context: appLinkContext({ catchErrors: [ignorePermissionsErrors] }),
    },
  );
  const remoteSharedHeadersString =
    data?.service?.variant?.sharedHeaders ?? undefined;
  const remoteSharedHeadersWithoutCheckedState = useMemo(
    () =>
      remoteSharedHeadersString
        ? headersArrayFromString(remoteSharedHeadersString)
        : [],
    [remoteSharedHeadersString],
  );

  const [persistSharedHeadersToGraphVariant] = useMutation<
    GraphQLTypes.PersistSharedHeadersForGraphVariant,
    GraphQLTypes.PersistSharedHeadersForGraphVariantVariables
  >(gql`
    mutation PersistSharedHeadersForGraphVariant(
      $graphId: ID!
      $graphVariant: String!
      $sharedHeadersContent: String
    ) {
      service(id: $graphId) {
        id
        variant(name: $graphVariant) {
          id
          updateSharedHeaders(sharedHeaders: $sharedHeadersContent) {
            id
            sharedHeaders
          }
        }
      }
    }
  `);

  const localStorageGraphIdentifier =
    // we don't store headers by sandbox endpoint, we just store them for the sandbox as a whole
    useLocalStorageGraphIdentifier({
      shouldNamespaceSandboxByEndpoint: false,
      shouldOnlyStoreForRegisteredGraphs: false,
    });
  const [
    tabbedCheckStateForRemoteHeaders,
    setTabbedCheckStateForRemoteHeaders,
  ] = usePerTabLocalStorage({
    key: 'tabbedCheckStateForRemoteHeaders',
    graphIdentifier: localStorageGraphIdentifier,
    stableInitialValue: INITIAL_REMOTE_CHECKED_STATE,
  });
  const remoteSharedHeaders = useMemo(
    () =>
      remoteSharedHeadersWithoutCheckedState.map((headerDefinition) => ({
        ...headerDefinition,
        checked:
          tabbedCheckStateForRemoteHeaders[headerDefinition.headerName] ?? true,
      })),
    [remoteSharedHeadersWithoutCheckedState, tabbedCheckStateForRemoteHeaders],
  );

  const setRemoteSharedHeaderDefinitions = React.useCallback(
    (
      sharedHeadersUpdate:
        | HeaderEntry[]
        | ((currentHeaders: HeaderEntry[]) => HeaderEntry[]),
    ) => {
      if (!graphRef) {
        // This case doesn't make semantic sense until we start having
        // local storage backed shared headers
        return;
      }

      const newSharedHeadersWithoutCheckedState =
        typeof sharedHeadersUpdate === 'function'
          ? sharedHeadersUpdate(remoteSharedHeadersWithoutCheckedState)
          : sharedHeadersUpdate;
      const newSharedHeadersContentWithoutCheckedState = headersStringFromArray(
        newSharedHeadersWithoutCheckedState,
      );
      if (
        newSharedHeadersContentWithoutCheckedState !== remoteSharedHeadersString
      ) {
        // If the headers themselves have been updated, persist the update
        persistSharedHeadersToGraphVariant({
          variables: {
            graphId: graphRef.graphId,
            graphVariant: graphRef.graphVariant,
            sharedHeadersContent: newSharedHeadersContentWithoutCheckedState,
          },
        });
      }

      // Update the local per tab checked state
      setTabbedCheckStateForRemoteHeaders(
        (currentTabbedCheckStateForRemoteHeaders) => {
          const currentRemoteSharedHeaders =
            remoteSharedHeadersWithoutCheckedState.map((headerDefinition) => ({
              ...headerDefinition,
              checked:
                currentTabbedCheckStateForRemoteHeaders[
                  headerDefinition.headerName
                ] ?? true,
            }));
          const newSharedHeaders =
            typeof sharedHeadersUpdate === 'function'
              ? sharedHeadersUpdate(currentRemoteSharedHeaders)
              : sharedHeadersUpdate;

          const nextValue = Object.fromEntries(
            newSharedHeaders.map((newSharedHeader) => [
              newSharedHeader.headerName,
              newSharedHeader.checked,
            ]),
          );
          if (_.isEqual(nextValue, currentTabbedCheckStateForRemoteHeaders)) {
            return currentTabbedCheckStateForRemoteHeaders;
          } else {
            return nextValue;
          }
        },
      );
    },
    [
      persistSharedHeadersToGraphVariant,
      graphRef,
      remoteSharedHeadersWithoutCheckedState,
      remoteSharedHeadersString,
      setTabbedCheckStateForRemoteHeaders,
    ],
  );

  const [localSharedHeaders, setLocalSharedHeaderDefinitions] =
    usePerGraphIdentifierLocalStorage({
      key: 'localDefaultHeaders',
      graphIdentifier: localStorageGraphIdentifier,
      stableInitialValue: INITIAL_HEADERS,
    });

  const [
    tabbedHeaderDefinitions,
    setTabbedHeaderDefinitions,
    setTabbedHeaderDefinitionsForTabId,
  ] = usePerTabLocalStorage({
    key: 'tabbedHeaderDefinitions',
    graphIdentifier: localStorageGraphIdentifier,
    stableInitialValue: INITIAL_HEADERS,
  });

  const isSandbox = !!useRouteMatch(sandboxGraphRouteConfig.definition);

  const [environmentVariablesValue] = usePerGraphIdentifierLocalStorage({
    key: 'studioVariantEnvironmentVariables',
    graphIdentifier: localStorageGraphIdentifier,
    stableInitialValue: '',
  });
  const parsedEnvironmentVariablesValue = useMemo(
    () => jsoncParser.parse(environmentVariablesValue) ?? {},
    [environmentVariablesValue],
  );
  const interpolateEnvVariablesIntoHeaders = useCallback(
    (headers: Record<string, string>) => {
      return _.mapValues(headers, (input: string) => {
        try {
          return mustache.render(input, parsedEnvironmentVariablesValue);
        } catch (err) {
          return input;
        }
      });
    },
    [parsedEnvironmentVariablesValue],
  );

  const parsedEnvironmentVariablesValueRef = useRef<JSONObject>(
    parsedEnvironmentVariablesValue,
  );
  parsedEnvironmentVariablesValueRef.current = parsedEnvironmentVariablesValue;
  const interpolateEnvVariablesIntoHeadersWithRef = useCallback(
    (headers: Record<string, string>) => {
      return _.mapValues(headers, (input: string) => {
        try {
          return mustache.render(
            input,
            parsedEnvironmentVariablesValueRef.current,
          );
        } catch (err) {
          return input;
        }
      });
    },
    [],
  );

  return React.useMemo(() => {
    const sharedHeaderDefinitions = isSandbox
      ? localSharedHeaders
      : remoteSharedHeaders;
    const setSharedHeaderDefinitions = isSandbox
      ? setLocalSharedHeaderDefinitions
      : setRemoteSharedHeaderDefinitions;
    const checkedSharedHeaders = headersObjectFromDefinitions(
      sharedHeaderDefinitions,
    );
    return {
      checkedSharedHeaders,
      checkedHeaders: {
        ...checkedSharedHeaders,
        ...headersObjectFromDefinitions(tabbedHeaderDefinitions),
      },
      tabbedHeaderDefinitions,
      setTabbedHeaderDefinitions,
      setTabbedHeaderDefinitionsForTabId,
      sharedHeaderDefinitions,
      setSharedHeaderDefinitions,
      interpolateEnvVariablesIntoHeaders,
      interpolateEnvVariablesIntoHeadersWithRef,
      updateEnvironmentVariables: (environmentVariables: JSONObject) => {
        parsedEnvironmentVariablesValueRef.current = environmentVariables;
      },
    };
  }, [
    isSandbox,
    localSharedHeaders,
    remoteSharedHeaders,
    setLocalSharedHeaderDefinitions,
    setRemoteSharedHeaderDefinitions,
    tabbedHeaderDefinitions,
    setTabbedHeaderDefinitions,
    setTabbedHeaderDefinitionsForTabId,
    interpolateEnvVariablesIntoHeaders,
    interpolateEnvVariablesIntoHeadersWithRef,
  ]);
};

export const headersObjectFromDefinitions = (
  headerDefinitions: HeaderEntry[],
) =>
  Object.fromEntries(
    headerDefinitions
      .filter(
        ({ headerName, value, checked }) => headerName && value && checked,
      )
      .map(({ headerName, value }) => [headerName, value]),
  );

const HeadersManagerContext = createContext<ReturnType<
  typeof useHeadersManager
> | null>(null);

export const EmptyHeadersManagerContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const headersManager = React.useMemo(
    () => ({
      tabbedHeaderDefinitions: [],
      checkedHeaders: {},
      checkedSharedHeaders: {},
      setTabbedHeaderDefinitions: () => {},
      setTabbedHeaderDefinitionsForTabId: () => {},
      sharedHeaderDefinitions: [],
      setSharedHeaderDefinitions: () => {},
      interpolateEnvVariablesIntoHeaders: () => ({}),
      interpolateEnvVariablesIntoHeadersWithRef: () => ({}),
      updateEnvironmentVariables: () => {},
    }),
    [],
  );
  return (
    <HeadersManagerContext.Provider value={headersManager}>
      {children}
    </HeadersManagerContext.Provider>
  );
};

export const HeadersManagerContextProvider = ({
  graphRef,
  children,
}: {
  graphRef: GraphRef | null;
  children: React.ReactNode;
}) => (
  <HeadersManagerContext.Provider
    value={useHeadersManager({
      graphRef,
    })}
  >
    {children}
  </HeadersManagerContext.Provider>
);

export const useHeadersManagerContext = () => {
  const context = useContext(HeadersManagerContext);
  if (!context) {
    throw new Error(
      'useHeadersManagerContext must be used within a HeadersManagerContextProvider',
    );
  }
  return context;
};

export const headersStringFromArray = (
  headerDefinitions: Array<HeaderEntry>,
): string => {
  const headers = Object.fromEntries(
    headerDefinitions.map(({ headerName, value }) => [headerName, value]),
  );

  return JSON.stringify(!_.isEmpty(headers) ? headers : '');
};

export const headersArrayFromString = (
  legacyHeadersDictString: string,
): Array<HeaderEntry> => {
  try {
    return Object.entries(
      jsoncParser.parse(legacyHeadersDictString || '{}'),
    ).map(([key, value]) => ({
      key: Math.random(),
      headerName: key,
      value: typeof value === 'string' ? value : JSON.stringify(value),
      checked: true,
    }));
  } catch {
    // not much we can do for errors
    return [];
  }
};

export const headersRemoteEntryFromArray = (headers: HeaderEntry[]) =>
  headers.map((header) => ({
    __typename: 'OperationHeader' as const,
    name: header.headerName,
    value: header.value,
  }));
