import { BillingPlanKind, BillingPlanTier } from './graphqlTypes/types';

/**
 * Return mapped name for different plan tiers and kinds
 * @param name plan name stored in DB
 * @param tier BillingPlanTier
 * @param kind BillingPlanKind
 * @returns string
 */
export const planNameMapper = (
  name: string | undefined,
  tier: BillingPlanTier | null | undefined,
  kind: BillingPlanKind | null | undefined,
): string => {
  switch (tier) {
    case BillingPlanTier.ENTERPRISE:
      return kind === BillingPlanKind.DEDICATED ? (name ?? kind) : 'Enterprise';
    case BillingPlanTier.TEAM:
      return 'Team';
    case BillingPlanTier.COMMUNITY:
      return 'Legacy Free';
    case BillingPlanTier.SCALE:
      return 'Scale';
    default:
      return name ?? tier ?? '';
  }
};
