import { useToast } from '@apollo/orbit';
import { uniqueId } from 'lodash';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router-dom-v5-compat';

import { Loading } from 'src/components/common/loading/Loading';
import { useDefaultVariant } from 'src/hooks/useDefaultVariant';
import { useRouteParams } from 'src/hooks/useRouteParams';
import Config from 'src/lib/config';
import { catchAllRouteConfig } from 'src/lib/routeConfig/catchAllRoute';
import { mergeQueryParams } from 'src/lib/routing';

import { legacyGraphRouteConfig } from '../../graph/routes';

/**
 *
 * Returns a Redirect from any graph route with ?variant or without a variant in the
 * query param to a variant route config with that variant or a default
 */
export function RedirectFromGraphPathToVariantPath() {
  const { defaultVariant, loadingDefaultVariant } = useDefaultVariant();
  const {
    graphVisibilityType,
    graphId,
    variant: legacyQueryParamVariant,
  } = useRouteParams(legacyGraphRouteConfig, catchAllRouteConfig);
  const location = useLocation();

  const showToasts = useToast();
  const stableToastId = React.useMemo(() => uniqueId(), []);

  useEffect(() => {
    if (
      !legacyQueryParamVariant &&
      defaultVariant &&
      defaultVariant !== Config.service.defaultVariant
    ) {
      showToasts({
        id: stableToastId,
        title: `You have been redirected to the '${defaultVariant}' variant of this graph.`,
        status: 'info',
        description: `No default '${Config.service.defaultVariant}' variant has been set up for this graph.`,
      });
    }
  }, [legacyQueryParamVariant, defaultVariant, showToasts, stableToastId]);

  if (loadingDefaultVariant) return <Loading />;

  const redirectVariant = encodeURIComponent(
    legacyQueryParamVariant ?? defaultVariant ?? Config.service.defaultVariant,
  );

  /**
   * This is brittle, but we use it for now because I can't think of a way to do
   * a dynamic swap like this in the route while leveraging React Router. We
   * want to preserve the entire path EXCEPT for switching how the variant param
   * is placed.
   */
  const newPathname = location.pathname.replace(
    // we use a regex here to match the case of `graph/:graphId/variant` that
    // someone might write manually, in addition to `graph/:graphId` base case
    new RegExp(`^/${graphVisibilityType}/${graphId}(/variant)?`),
    `/${graphVisibilityType}/${graphId}/variant/${redirectVariant}`,
  );

  return (
    <Redirect
      push={false}
      to={{
        ...location,
        pathname: newPathname,
        search: mergeQueryParams(location.search, { variant: undefined }),
      }}
    />
  );
}
