import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
} from '@apollo/orbit';
import React from 'react';

export const ContactRequestSentModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => (
  <Modal onClose={onClose} isOpen={isOpen}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>
        <ModalTitle>Contact request sent</ModalTitle>
      </ModalHeader>
      <ModalBody>
        The Apollo team will be in touch shortly. Thank you so much for reaching
        out. {'\u{1F389}'}
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => onClose()}>Close</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
