import { gql, useMutation } from '@apollo/client';

import { GraphQLTypes } from 'src/lib/graphqlTypes';
import { PushMarketoLeadInput } from 'src/lib/graphqlTypes/types';
import { isCypressUserEmail } from 'src/lib/isCypressUser';

export const pushMarketoLeadMutation = gql`
  mutation PushMarketoLead(
    $input: PushMarketoLeadInput!
    $programId: ID!
    $source: String
  ) {
    pushMarketoLead(input: $input, programId: $programId, source: $source)
  }
`;

interface UTM {
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  referrer?: string;
}

export interface Lead {
  programId: number;
  source: 'Product' | 'Upgrade Inquiry';
  sourceDetail: string;
}

interface PushMarketingAnalyticsArgs {
  userId?: string;
  fullName: string;
  email: string;
  utm?: null | UTM;
  companyUrl?: string;
  orgId?: string;
  lead: Lead;
}

export const PROGRAM_ID_STUDIO_HIGHER_LIMIT_REQUEST = 1527;
export const GRAPHOS_FREE_V1_LEAD_DETAILS = {
  programId: 3397,
  source: 'Product' as Lead['source'],
  sourceDetail: 'Ongoing_Product_Studio Sign Up Free FY26',
};
export const ENTERPRISE_TRIAL_LEAD_DETAILS = {
  programId: 1942,
  source: 'Product' as Lead['source'],
  sourceDetail: 'Ongoing_Product_Sign Up Enterprise Trial',
};

export const getLeadDetails = (planId: string): Lead => {
  switch (planId) {
    case 'graphos-free-v1':
      return GRAPHOS_FREE_V1_LEAD_DETAILS;
    case 'sub-engine-ent-trial':
    case 'graphos-trial-v1':
      return ENTERPRISE_TRIAL_LEAD_DETAILS;
    case 'sub-engine-ent':
    case 'sub-engine-ent-stripe':
    case 'sub-engine-ent-partner':
    case 'sub-engine-ent-pilot':
    case 'sub-engine-ent-seated-y':
    case 'sub-engine-ent-seated-m':
    case 'sub-engine-ent-seated-q':
    case 'sub-engine-ent-seated-6m':
      return {
        programId: 1120,
        source: 'Product',
        sourceDetail: 'Ongoing_Product_Studio Sign Up Enterprise',
      };
    case 'sub-engine-cmm':
      return {
        programId: 1118,
        source: 'Product',
        sourceDetail: 'Ongoing_Product_Studio Sign Up Free',
      };
    case 'sub-engine-pro-seated-sales-y':
    case 'sub-engine-pro-seated-y':
    case 'sub-engine-pro-seated-sales':
    case 'sub-engine-pro-seated-monthly-from-annual':
    case 'sub-engine-pro-seated':
      return {
        programId: 1119,
        source: 'Product',
        sourceDetail: 'Ongoing_Product_Studio Sign Up Team',
      };
    case 'd63204df-fb59-4192-91f9-377d9492b2a7':
    case 'f79f600d-22cf-4747-96c9-640638eab4cc':
    case '2bd05a52-4a45-4e9c-9151-f005cfd26df0':
    case 'c64ffc90-d114-4b1e-8537-bf4f7fcd9930':
    case '0a8b1e8c-4daa-4ad0-aaad-fb7a4fd66f75':
    case 'b08b6c97-9691-412c-91c8-7aace2cd180e':
      return {
        programId: 1561,
        source: 'Product',
        sourceDetail: 'Ongoing_Product_GraphOS Serverless Sign Up',
      };
    default:
      return GRAPHOS_FREE_V1_LEAD_DETAILS;
  }
};

export const useMarketo = () => {
  const [pushMarketoLead] = useMutation<
    GraphQLTypes.PushMarketoLead,
    GraphQLTypes.PushMarketoLeadVariables
  >(pushMarketoLeadMutation);
  return async ({
    userId,
    fullName,
    email,
    utm,
    companyUrl,
    orgId,
    lead,
  }: PushMarketingAnalyticsArgs) => {
    // we don't want to return anything if Cypress is running the script,
    // or if the lead doesn't have a programId
    if (isCypressUserEmail(email) || !lead.programId) {
      return;
    }
    const [firstName, ...otherNames] = fullName.split(' ');
    // last name must be passed in order for salesforce sync to work (marketo)
    // if last name doesn't exist, we'll use the first name as the last
    // and pass an empty string for the first name
    // (this also applies to github signups)
    const lastName = otherNames.join(' ');
    const inputObj: PushMarketoLeadInput = {
      firstName: !lastName ? '' : firstName,
      lastName: !lastName ? firstName : lastName,
      email,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Lead_Source_Most_Recent__c: lead.source,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Lead_Source_Most_Recent_Detail__c: lead.sourceDetail,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      UTM_Medium__c: utm?.utmMedium,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      UTM_Source__c: utm?.utmSource,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      UTM_Campaign__c: utm?.utmCampaign,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Referrer__c: utm?.referrer,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Company_Domain__c: companyUrl,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Studio_Organization_ID__c: orgId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Studio_User_Id__c: userId,
    };
    return pushMarketoLead({
      variables: {
        programId: lead.programId.toString(),
        input: inputObj,
      },
    });
  };
};
