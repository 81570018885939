import * as myzod from 'myzod';

import { RouteConfig } from 'src/lib/routeConfig/RouteConfig';

const userSegmentTypes = myzod
  .literals(
    'prod',
    'dev',
    'invite',
    'sandbox',
    'sandbox_operation_collections',
    'sandbox_preflight_scripts',
    'sandbox_operation_scripts',
    'enterprise-trial',
    'sso',
  )
  .optional();

export type OnboardingUserSegmentType = myzod.Infer<typeof userSegmentTypes>;

/**
 * Onboarding Routes
 */
export const onboarding = new RouteConfig({
  definition: '',
  parseSearchParams: (queryStringParams) =>
    myzod
      .object({
        // if a user comes here from the landing page via /dev?endpoint,
        // we want to remember their endpoint
        // to auto-fill the sandbox url once they log in / sign in
        endpoint: myzod.string().optional(),
        graphTitle: myzod.string().optional(),
        from: myzod.string().optional(),
        ldKey: myzod.string().optional(),
      })
      .allowUnknownKeys()
      .parse(queryStringParams),
});

export const signup = onboarding.extend(
  new RouteConfig({
    owners: ['growth'],
    definition: '/signup',
    parseSearchParams: (params) =>
      myzod
        .object({
          joinToken: myzod.string().optional(),
          orgId: myzod.string().optional(),
          type: userSegmentTypes,
          ldKey: myzod.string().optional(),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

export const welcome = onboarding.extend(
  new RouteConfig({
    owners: ['growth'],
    definition: '/welcome',
    parseSearchParams: (params) =>
      myzod
        .object({
          type: userSegmentTypes,
          joinToken: myzod.string().optional(),
          orgId: myzod.string().optional(),
          from: myzod.string().optional(),
          ldKey: myzod.string().optional(),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

export const forgotPassword = onboarding.extend(
  new RouteConfig({
    owners: ['growth'],
    definition: '/forgot-password',
  }),
);

export const resetPassword = onboarding.extend(
  new RouteConfig({
    owners: ['growth'],
    definition: '/reset-password',
    parseSearchParams: (params) =>
      myzod
        .object({ token: myzod.string().optional() })
        .allowUnknownKeys()
        .parse(params),
  }),
);

export const verifyEmail = onboarding.extend(
  new RouteConfig({
    owners: ['growth'],
    definition: '/verify-email',
    parseSearchParams: (params) =>
      myzod
        .object({ token: myzod.string().optional() })
        .allowUnknownKeys()
        .parse(params),
  }),
);

export const userProfileSurvey = onboarding.extend(
  new RouteConfig({
    owners: ['growth'],
    definition: '/survey',
    parseSearchParams: (params) =>
      myzod
        .object({
          type: userSegmentTypes,
          joinToken: myzod.string().optional(),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

/**
 * Login Routes
 */
export const login = new RouteConfig({
  owners: ['growth'],
  definition: '/login',
  parseSearchParams: (queryStringParams) =>
    myzod
      .object({
        loginError: myzod.string().or(myzod.number()).optional(),
        loginErrorMessage: myzod.string().optional(),
        sessionError: myzod.string().or(myzod.number()).optional(),
        type: userSegmentTypes,
        from: myzod.string().optional(),
        joinToken: myzod.string().optional(),
        orgId: myzod.string().optional(),
        ldKey: myzod.string().optional(),
      })
      .allowUnknownKeys()
      .parse(queryStringParams),
});

export const loginWithProvider = login.extend(
  new RouteConfig({
    owners: ['growth'],
    definition: '/:provider',
    parseMatchParams: (params) =>
      myzod
        .object({ provider: myzod.string() })
        .allowUnknownKeys()
        .parse(params),
  }),
);

export const loginWithApolloSSO = new RouteConfig({
  owners: ['growth'],
  definition: '/sso/login',
  parseSearchParams: (queryStringParams) =>
    myzod
      .object({
        from: myzod.string().optional(),
      })
      .allowUnknownKeys()
      .parse(queryStringParams),
});

export const ssoConfiguration = new RouteConfig({
  owners: ['growth'],
  definition: '/sso/oidc/configuration/:key',
  parseMatchParams: (params) =>
    myzod.object({ key: myzod.string() }).allowUnknownKeys().parse(params),
});

/**
 * Other Routes
 */
export const prodGraphLanding = new RouteConfig({
  owners: ['growth'],
  definition: '/prod',
});

export const sandboxLanding = new RouteConfig({
  owners: ['growth'],
  definition: '/dev',
  parseSearchParams: (params) =>
    myzod
      .object({
        // These are only used for the Apollo Server Landing Page sandbox link
        endpoint: myzod.string().nullable().optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

export const embedAuthenticationRouteConfig = new RouteConfig({
  owners: ['growth'],
  definition: '/embed-authentication',
  parseSearchParams: (queryStringParams) =>
    myzod
      .object({
        origin: myzod.string(),
        embedSubdomain: myzod.literals('sandbox', 'explorer'),
        graphRef: myzod.string().optional(),
        type: userSegmentTypes,
        inviteToken: myzod.string().optional(),
        accountId: myzod.string().optional(),
      })
      .allowUnknownKeys()
      .parse(queryStringParams),
});

export const embedLogoutRouteConfig = new RouteConfig({
  owners: ['growth'],
  definition: '/embed-logout',
  parseSearchParams: (queryStringParams) =>
    myzod
      .object({ from: myzod.string(), graphRef: myzod.string().optional() })
      .allowUnknownKeys()
      .parse(queryStringParams),
});

export const logoutRouteConfig = new RouteConfig({
  owners: ['growth'],
  definition: '/logout',
  parseSearchParams: (params) =>
    myzod
      .object({ callbackUrl: myzod.string().optional() })
      .allowUnknownKeys()
      .parse(params),
});
