import { forwardRef, useCardStyles } from '@chakra-ui/react';
import React from 'react';

import { Heading, HeadingProps } from '../Heading/Heading';

export type CardTitleProps = Omit<
  HeadingProps<'h2'>,
  'variant' | '__orbitStyleConfig'
>;

export const CardTitle = forwardRef<CardTitleProps, 'h2'>((props, ref) => {
  const styles = useCardStyles();
  const { size = 'xl', ...restProps } = props;

  return (
    <Heading
      {...restProps}
      ref={ref}
      size={size}
      __orbitStyleConfig={styles.title}
    />
  );
});
