// If this function changes, update `getRunTelemetryLocalStorage.ts`
export const isEmbeddableSandboxRoute = (): boolean => {
  const hostname = window.location.hostname;

  return (
    // local dev - accessed via embed.apollo.local:[port]/sandbox
    (hostname === 'embed.apollo.local' &&
      window.location.pathname.includes('sandbox')) ||
    // prod
    hostname.endsWith('sandbox.embed.apollographql.com') ||
    // deploy previews
    hostname.endsWith('embeddable-sandbox.netlify.app')
  );
};
