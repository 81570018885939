import React from 'react';
import { Navigate, useLocation } from 'react-router-dom-v5-compat';

import {
  legacyChecksRouteConfig,
  legacyOperationsCheckDetailsRoute,
  recentChecksRouteConfig,
  variantSettingsRouteConfig,
} from 'src/app/graph/routes';
import { useRouteParams } from 'src/hooks/useRouteParams';
import { assertUnreachableOrReturnDefault } from 'src/lib/assertUnreachable';

export function ChecksRedirect() {
  const location = useLocation();
  const {
    schemaTagId: queryCheckId,
    tab,
    checkId: matchCheckId,
  } = useRouteParams(legacyChecksRouteConfig);

  const legacyCheckId = matchCheckId ?? queryCheckId;

  // support for legacy query params version
  // TODO: Remove me once we stop supporting the old /checks/:checkId route.
  if (legacyCheckId) {
    return (
      <Navigate
        replace={true}
        to={legacyOperationsCheckDetailsRoute.locationFrom({
          location,
          patch: { taskId: legacyCheckId },
          fromRouteConfig: legacyChecksRouteConfig,
        })}
      />
    );
  }

  const variantSetttings = variantSettingsRouteConfig.locationFrom({
    fromRouteConfig: legacyChecksRouteConfig,
    location,
    patch: { view: 'variant', tab: 'checks' },
  });
  const checksList = recentChecksRouteConfig.locationFrom({
    fromRouteConfig: legacyChecksRouteConfig,
    location,
    patch: { view: 'variant' },
  });
  return (
    <Navigate
      replace={true}
      to={
        tab === 'list'
          ? checksList
          : tab === 'check' || tab === 'config'
            ? variantSetttings
            : tab === undefined
              ? checksList
              : assertUnreachableOrReturnDefault(tab, variantSetttings)
      }
    />
  );
}
