import { useDefaultPlanID } from 'src/lib/featureFlags';

/**
 * Returns the default plan ID for a new user. Used specifically when generating
 * a new organization and an org is not found for/not explicitly assigned to the user.
 *
 * Logic:
 * - Returns the default plan based on the `growth-default-studio-plan` ID passed in LaunchDarkly.
 *   LaunchDarkly key here: https://app.launchdarkly.com/projects/default/flags/growth-default-studio-plan?env=production&env=dev-0&env=staging&selected-env=production
 * - Returns undefined if there is no value passed from the LaunchDarkly key, or if the key is turned off.
 *
 * NOTE: There should ALWAYS be a default plan passed! Deprecating this LaunchDarkly key
 * `growth-default-studio-plan` should be replaced with a query for default plans from
 * the Studio API. Ticket for this work is here: https://apollographql.atlassian.net/browse/ONB-587
 */
export const useDefaultPlan = (planID = 'graphos-free-v1') => {
  return useDefaultPlanID() || planID;
};
