import { find } from 'lodash';
import { parse } from 'query-string';
import { matchPath } from 'react-router';

import { sandboxGraphRouteConfig } from 'src/app/graph/routes';

import Config from '../config';

// if we ever add a significantly new route type, we'll need to add it here for proper analytics
const pageCategories = {
  account: /^\/org/,
  service: /^\/service/,
  graph: /^\/graph/,
  root: /^\//, // this isn't great, but ok for now - it's hard to detect 404s
};

let lastPathname = '';
const getPageCategory = (page: string) => {
  let category = null;
  find(pageCategories, (regex, key) => {
    if (regex.test(page)) {
      category = key;
      return true;
    }
    return false;
  });
  return category || '';
};

const getPageName = (pathname: string) => {
  // TODO: find a corresponding route config by pathname, have route configs define page names
  if (matchPath(pathname, sandboxGraphRouteConfig.definition)) {
    return 'Sandbox';
  }
  return 'Unnamed';
};

/*
 * A helper for all the events we send to Segment.
 *
 * The event types:
 *   identify
 *   group
 *   track
 *   page
 */
export const Analytics = {
  isAvailable() {
    return typeof analytics !== 'undefined';
  },

  // Initialize Segment analytics on page load.
  initialize(key: string) {
    if (typeof analytics === 'undefined') return;
    analytics.load(key);
  },

  // Identify users with Segment when they start a session.
  // This data percolates to Intercom, etc.
  identify(user: {
    id: string;
    fullName: string;
    email: string | null;
    avatarUrl: string | null;
    createdAt: string | number;
  }) {
    if (typeof analytics === 'undefined') return;
    const { id, fullName, email, avatarUrl, createdAt } = user;
    analytics.identify(id, {
      ...Config.analyticsPayload,
      email,
      name: fullName,
      createdAt,
      avatar: {
        type: 'avatar',
        image_url: avatarUrl, // eslint-disable-line @typescript-eslint/naming-convention
      },
    });
  },

  // Associate users with the group (organizations) they're
  // visiting. This data percolates to Intercom, etc.
  group(currentAccount: {
    id: string;
    name: string;
    avatarUrl: string | null;
    createdAt: string | number | null;
    currentPlan?: { id: string } | null;
  }) {
    if (typeof analytics === 'undefined') return;
    const currentPlan = currentAccount.currentPlan;
    if (!currentAccount.id || !currentPlan) return;
    const { id, name, avatarUrl, createdAt } = currentAccount;
    analytics.group(id, {
      ...Config.analyticsPayload,
      name,
      website: `https://www.github.com/${name}`,
      plan: currentPlan.id,
      avatar: avatarUrl,
      createdAt,
    });
  },

  // Track specific events triggered by users; eg: email updated, trial started.
  track(userId: string, event: string, properties: object) {
    if (typeof analytics === 'undefined') return;
    analytics.track(event, {
      ...Config.analyticsPayload,
      userId,
      ...properties,
    });
  },

  // Track each URL that's visited and the URL category
  page(pathname: string, title: string) {
    if (lastPathname === pathname || typeof analytics === 'undefined') return;
    lastPathname = pathname;
    // https://segment.com/docs/connections/spec/page/
    analytics.page(getPageCategory(pathname), getPageName(pathname), {
      ...Config.analyticsPayload,
      title,
      pathname,
    });
  },
};

export type EventCategory =
  // Pages
  | 'Explorer'
  | 'Sandbox Check'
  | 'Sandbox Diff'
  | 'Sandbox'
  | 'Sandbox - Local'
  | 'Sandbox - Remote'
  | 'Variant Home'
  | 'README Shortcodes'
  | 'Embeddable Explorer'
  | 'Embeddable Sandbox'
  | 'Subgraphs Page'
  | 'Cloud Router Settings'
  | 'Persisted Queries'
  // User Flows
  | 'Login Flow'
  | 'Feature Intros'
  // Features
  | 'Operation Collections'
  | 'Contracts'
  // Org Creation Flow
  | 'Org Creation Flow'
  // SSO Flow
  | 'SSO Wizard'
  // Upgrade Account
  | 'Upgrade Account'
  // Cancel Paid Subscription Flow
  | 'Cancel Paid Subscription'
  // Supergraph
  | 'Supergraph Onboarding'
  // Misc
  | 'Temporary'
  | 'Unspecified'
  | 'Service Workers'
  | 'Keyboard Shortcuts'
  | 'Schema Reference'
  | 'Fields'
  | 'Field Insights'
  | 'Insights L3 Fields List'
  | 'Insights L3 Object Fields List'
  | 'Insights L3 Input Fields List'
  | 'Insights L3 Enum Values List'
  | 'Operation List'
  | 'Operation Insights'
  | 'Operation Insights Errors'
  | 'Insights L3 Operations List'
  | 'Insights L3 List'
  | 'Graph Settings Variants'
  | 'Trace Rollup'
  | 'GraphViz'
  // 6M2S
  | '6M2S: Onboarding'
  // Linting
  | 'Lint Checks'
  // Enterprise Trial
  | 'Enterprise Trial Sign Up'
  | 'Language Server'
  | 'Webhooks';

// This is not an event. This sets a property on the user
export const trackUserIsLoggedIn = (isLoggedIn: boolean) => {
  window.gtag?.('set', 'user_properties', {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    logged_in: isLoggedIn,
  });
};

// This is not an event. This sets a property on the user & the config
// https://developers.google.com/analytics/devguides/collection/ga4/user-id?platform=websites
export const trackUserId = (userId: string | undefined) => {
  // the G- is the google measurement id - you can find it here
  // https://analytics.google.com/analytics/web/#/a74643563p263258241/admin/streams/table/2347074944
  window.gtag?.('config', 'G-0BGG5V2W2K', {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    user_id: userId,
  });
  window.gtag?.('set', 'user_properties', {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    apollo_user_id: userId,
  });
};

export const getTrackingCookie = (
  name: (typeof Config.cookies)[keyof typeof Config.cookies],
) => {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]?.trimStart();
    if (cookie?.indexOf(nameEQ) === 0)
      return cookie.substring(nameEQ.length, cookie.length);
  }
  return undefined;
};

export const setTrackingCookie = (
  param: (typeof Config.cookies)[keyof typeof Config.cookies],
  pValue: string,
) => {
  if (param && pValue) {
    document.cookie = `${param}=${pValue}; samesite=lax; path=/; max-age${
      60 * 60 * 24
    }`;
  }
};

// Google's docs for window.gtag get:
// https://developers.google.com/gtagjs/reference/api#set_examples
export function getGaClientId() {
  return new Promise<string | null>((resolve) => {
    if (window.gtag) {
      window.gtag('get', Config.gaTrackingID, 'client_id', (clientId) => {
        resolve(clientId);
        clearTimeout(timeout);
      });
      const timeout = setTimeout(() => {
        resolve(null);
      }, 2000);
    } else {
      resolve(null);
    }
  });
}

export function getTrackingCookies() {
  const {
    [Config.queryParameters.Referrer]: referrer,
    [Config.queryParameters.Campaign]: utmCampaign,
    [Config.queryParameters.Medium]: utmMedium,
    [Config.queryParameters.Source]: utmSource,
  } = parse(window.location.search);

  // handle cookie values
  // if query param exists, use it's value and set a cookie based on it
  // else, try to grab the cookie - if it doesn't exist, the func returns
  // undefined
  let sourceCookie, mediumCookie, campaignCookie, referrerCookie;
  if (typeof utmSource === 'string' && utmSource) {
    setTrackingCookie(Config.cookies.Source, utmSource);
    sourceCookie = utmSource;
  } else {
    sourceCookie = getTrackingCookie(Config.cookies.Source);
  }

  if (typeof utmMedium === 'string' && utmMedium) {
    setTrackingCookie(Config.cookies.Medium, utmMedium);
    mediumCookie = utmMedium;
  } else {
    mediumCookie = getTrackingCookie(Config.cookies.Medium);
  }

  if (typeof utmCampaign === 'string' && utmCampaign) {
    setTrackingCookie(Config.cookies.Campaign, utmCampaign);
    campaignCookie = utmCampaign;
  } else {
    campaignCookie = getTrackingCookie(Config.cookies.Campaign);
  }

  if (typeof referrer === 'string' && referrer) {
    setTrackingCookie(Config.cookies.Referrer, referrer);
    referrerCookie = referrer;
  } else {
    referrerCookie = getTrackingCookie(Config.cookies.Referrer);
  }

  return {
    sourceCookie,
    mediumCookie,
    campaignCookie,
    referrerCookie,
  };
}

declare module 'react' {
  interface DOMAttributes<T> {
    readonly ['data-analytics-category']?: EventCategory;
    readonly ['data-analytics-label']?: string;
    readonly ['data-analytics-orgId']?: string;
  }

  interface RefAttributes<T> {
    readonly ['data-analytics-category']?: EventCategory;
    readonly ['data-analytics-label']?: string;
    readonly ['data-analytics-orgId']?: string;
  }
}

declare module '@react-types/switch' {
  interface SwitchProps {
    readonly ['data-analytics-category']?: EventCategory;
    readonly ['data-analytics-label']?: string;
    readonly ['data-analytics-orgId']?: string;
  }
}

declare module 'react-router-dom' {
  interface LinkProps<S> {
    readonly ['data-analytics-category']?: EventCategory;
    readonly ['data-analytics-label']?: string;
    readonly ['data-analytics-orgId']?: string;
  }
}
