import { ExternalRouter } from 'src/lib/external-router';

import Config from '../config';

// URL for any embedded Explorer iframe
export const EMBEDDABLE_EXPLORER_URL = Config.isRunForLocalEmbedAuth
  ? 'https://embed.apollo.local:3000'
  : 'https://explorer.embed.apollographql.com';
export const EMBEDDABLE_SANDBOX_URL = Config.isRunForLocalEmbedAuth
  ? 'https://embed.apollo.local:3000/sandbox'
  : 'https://sandbox.embed.apollographql.com/sandbox';

const EmbeddedRouter = new ExternalRouter(Config.settings.studioUrl, {
  Base: '',
});

export const openStudioLinkFromEmbedInNewTab = (path: string) => {
  window.open(EmbeddedRouter.path('Base').slice(0, -1) + path, '_blank');
};

export const getStudioLinkFromEmbed = (path: string) => {
  return EmbeddedRouter.path('Base').slice(0, -1) + path;
};
