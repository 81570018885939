import { Button, Card, CardBody, Heading, Text } from '@apollo/orbit';
import React from 'react';
import { Link } from 'react-router-dom';

import * as orgRouteConfig from 'src/app/account/routes';
import { useGraphVisibility } from 'src/app/graph/hooks/useGraphVisibility';
import { Icon as RestrictedIcon } from 'src/assets/restricted.svg';
import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';

export const PermissionBlockCard = ({
  isGraphBlock,
}: {
  isGraphBlock?: boolean;
}) => {
  const [orgId] = useCurrentAccountId();
  const isPublicRoute = useGraphVisibility() === 'public';
  const homeLink = orgId ? orgRouteConfig.org.path({ orgId }) : '/';

  return (
    <Card className="m-6 h-full">
      <CardBody className="m-auto h-full max-w-xl items-center justify-center gap-3">
        <RestrictedIcon className="h-20 w-36 shrink-0" />
        <Heading size="xl">Restricted area</Heading>
        <Text className="text-center">
          The permissions of your role do not include access to this area. To
          obtain access, please contact an Org
          {isGraphBlock ? ' or Graph' : ''} Admin
          {isPublicRoute ? ' or the sender of the link' : ''}.
        </Text>
        <Button as={Link} variant="primary" to={homeLink} className="mt-3">
          Back to Home
        </Button>
      </CardBody>
    </Card>
  );
};
