import { gql, useMutation, useQuery } from '@apollo/client';
import IconEdit from '@apollo/icons/default/IconEdit.svg';
import IconPlus from '@apollo/icons/default/IconPlus.svg';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
  Textarea,
} from '@apollo/orbit';
import { Select } from '@apollo/space-kit/Select';
import * as Sentry from '@sentry/react';
import classnames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';

import { useGraphRef } from 'src/app/graph/hooks/useGraphRef';
import { Form } from 'src/components/common/form/Form';
import { Loading } from 'src/components/common/loading/Loading';
import { Tooltip } from 'src/components/common/tooltip/Tooltip';
import { ErrorMessage } from 'src/components/errorMessage/ErrorMessage';
import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';
import Config from 'src/lib/config';
import {
  BillingPlanKind,
  GraphType,
  SubmitSupportTicket,
  SubmitSupportTicketVariables,
  SupportRequestModalQuery,
  SupportRequestModalQueryVariables,
  SupportRequestModalQuery_me_User_memberships,
  SupportRequestModalQuery_me_User_memberships_account,
  TicketPriority,
  UserPermission,
} from 'src/lib/graphqlTypes/types';
import { isMinLengthArray } from 'src/lib/isMinLengthArray';
import { planNameMapper } from 'src/lib/planMappers';

const supportRequestModalCommonFragment = gql`
  fragment SupportRequestModalCommonFragment on Identity {
    id
    ... on User {
      id
      email
      memberships {
        role: permission
        account {
          id
          name
          services {
            id
            title
            graphType
          }
        }
        user {
          id
        }
      }
    }
  }
`;

const supportRequestModalQuery = gql<
  SupportRequestModalQuery,
  SupportRequestModalQueryVariables
>`
  query SupportRequestModalQuery {
    me {
      id
      ... on User {
        fullName
        memberships {
          account {
            id
            internalID
            currentPlan {
              id
              tier
              kind
              name
            }
          }
          user {
            id
          }
        }
      }
      ...SupportRequestModalCommonFragment
    }
  }
  ${supportRequestModalCommonFragment}
`;

const submitJSMTicketMutation = gql<
  SubmitSupportTicket,
  SubmitSupportTicketVariables
>`
  mutation SubmitSupportTicket($ticket: SupportTicketInput!, $userId: ID!) {
    user(id: $userId) {
      submitSupportTicket(ticket: $ticket) {
        id
      }
    }
  }
`;

const yupEmailChecker = Yup.string().required().email();
const yupCCsChecker = Yup.array(Yup.string().required().email());
const splitEmails = (emailsString: string) =>
  emailsString
    .replace(/\s+/g, '')
    .split(',')
    .filter((email) => email.length > 0);

const USER_ACCOUNT_ISSUE = '__user_account_issue__';
const ACEPHEI_ID = 'acephei-corp';
const DESCRIPTION_REQUIRED_MESSAGE = 'Description must not be empty';
const EMAIL_INVALID_MESSAGE = 'Email is invalid';
const CCS_INVALID_MESSAGE = 'One or more emails is invalid';

export const SelectWithLabel = ({
  className,
  label,
  ...props
}: {
  className?: string;
  label: React.ReactElement;
} & React.ComponentProps<typeof Select>) => {
  const [labelProps, setLabelProps] = React.useState();
  return (
    <div className={classnames(className, 'mb-2 flex flex-col')}>
      {React.cloneElement(label, labelProps)}
      <Select
        {...props}
        labelPropsCallbackRef={setLabelProps}
        className="mt-1 h-10 rounded-lg border border-primary bg-input px-2 py-4 text-base font-normal text-primary shadow-none placeholder:text-placeholder hover:shadow-none"
      />
    </div>
  );
};

const SupportRequest = ({
  isOpen,
  closeModal,
}: {
  isOpen: boolean;
  closeModal: () => void;
}) => {
  const [currentAccountId] = useCurrentAccountId();
  const graphRef = useGraphRef();

  const { data, loading } = useQuery<
    SupportRequestModalQuery,
    SupportRequestModalQueryVariables
  >(supportRequestModalQuery, {
    variables: {},
  });

  const [submitJSMTicket, { loading: isSubmittingJSM, error: errorJSM }] =
    useMutation(submitJSMTicketMutation);

  const me = data?.me?.__typename === 'User' ? data.me : undefined;

  const organizations = useMemo(() => {
    const memberships: Array<SupportRequestModalQuery_me_User_memberships> =
      me?.memberships || [];
    const acepheiMembership = memberships?.find(
      ({ account }) => account.id === ACEPHEI_ID,
    );
    const isAcepheiAdmin =
      acepheiMembership &&
      [UserPermission.GRAPH_ADMIN, UserPermission.ORG_ADMIN].includes(
        acepheiMembership.role,
      );
    return memberships
      .map(({ account }) => account)
      .filter(({ id }) => id !== ACEPHEI_ID || isAcepheiAdmin);
  }, [me]);

  const [issueAccount, setIssueAccount] = useState<
    | typeof USER_ACCOUNT_ISSUE
    | SupportRequestModalQuery_me_User_memberships_account
  >(USER_ACCOUNT_ISSUE);

  const [priority, setPriority] = useState(TicketPriority.P3);

  const [issueAbout, setIssueAbout] = useState<
    | {
        type: 'Graph';
        category: string;
      }
    | {
        type: 'Organization';
        category: 'Subscription & Billing' | 'Membership Management' | 'Other';
      }
  >();

  const [graphType, setGraphType] = useState<GraphType>();
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState<string>();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<string>();
  const [shouldShowEmail, setShouldShowEmail] = useState(false);
  const [additionalCCs, setAdditionalCCs] = useState('');
  const [additionalCCsError, setAdditionalCCsError] = useState<string>();
  const [shouldShowAdditionalCCs, setShouldShowAdditionalCCs] = useState(false);
  const [exceptionErrorMessage, setExceptionErrorMessage] = useState('');

  // when user loads: set email and selected org to first in list if user has orgs
  useEffect(() => {
    if (me?.email) setEmail(me.email);

    if (me && !yupEmailChecker.isValidSync(me.email)) {
      setEmailError(EMAIL_INVALID_MESSAGE);
      setShouldShowEmail(true);
    }

    if (isMinLengthArray(1, organizations)) {
      const currentOrg =
        (currentAccountId &&
          organizations.find((o) => o.id === currentAccountId)) ||
        null;
      setIssueAccount(currentOrg || organizations[0]);
    }
  }, [me, organizations, currentAccountId]);

  // when the org changes, reset prefs
  useEffect(() => {
    setPriority(TicketPriority.P3);
    if (issueAccount !== USER_ACCOUNT_ISSUE) {
      const defaultGraph =
        issueAccount.services.find((g) => g.id === graphRef?.graphId) ||
        issueAccount.services[0];

      setIssueAbout(
        defaultGraph
          ? {
              type: 'Graph',
              category: defaultGraph?.id,
            }
          : {
              type: 'Organization',
              category: 'Subscription & Billing',
            },
      );

      setGraphType(defaultGraph?.graphType);
    } else {
      setIssueAbout(undefined);
    }
  }, [issueAccount, graphRef]);

  const priorities = Config.jsmSupportTicketPriorities;

  const urgentPriority = TicketPriority.P1;
  const highPriority = TicketPriority.P2;

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size="2xl">
      <ModalOverlay />
      <Form
        onSubmit={async () => {
          if (isSubmittingJSM || !me) return;

          const ccEmails = splitEmails(additionalCCs);
          const emailValid = yupEmailChecker.isValidSync(email);
          const ccsValid = yupCCsChecker.isValidSync(ccEmails);
          if (!emailValid || !ccsValid || !description) {
            if (!emailValid) {
              setEmailError(EMAIL_INVALID_MESSAGE);
            }
            if (!ccsValid) {
              setAdditionalCCsError(CCS_INVALID_MESSAGE);
            }
            if (!description) {
              setDescriptionError(DESCRIPTION_REQUIRED_MESSAGE);
            }
            return;
          }
          try {
            const result = await submitJSMTicket({
              variables: {
                userId: me?.id,
                ticket: {
                  email,
                  description: `Issue about: ${
                    issueAbout ? issueAbout.category : 'User Account'
                  }\nReported from: ${window.location.href}\n\n${description}`,
                  priority,
                  summary: description.slice(0, 155),
                  graphId:
                    issueAbout?.type === 'Graph'
                      ? issueAbout?.category
                      : undefined,
                  apolloOrgId:
                    issueAccount !== USER_ACCOUNT_ISSUE
                      ? issueAccount.internalID
                      : undefined,
                  graphType:
                    issueAbout?.type === 'Graph' ? graphType : undefined,
                  displayName: me?.fullName,
                  emailUsers: ccEmails.length ? ccEmails : [],
                },
              },
            });
            if (result.data) {
              closeModal();
            }
          } catch (exception) {
            setExceptionErrorMessage(
              'There was an error submitting your support request.',
            );
            Sentry.captureException(exception);
          }
        }}
        noValidate
      >
        <ModalContent>
          <ModalHeader>
            <ModalTitle>Submit a support ticket</ModalTitle>
          </ModalHeader>
          <ModalBody>
            {loading || !data ? (
              <Loading size="lg" />
            ) : !me ? (
              <ErrorMessage>
                You must be authenticated as a user to submit a support ticket.
              </ErrorMessage>
            ) : (
              <>
                {errorJSM && <ErrorMessage error={errorJSM} />}
                {exceptionErrorMessage && (
                  <ErrorMessage>{exceptionErrorMessage}</ErrorMessage>
                )}

                {priority === urgentPriority && (
                  <ErrorMessage>
                    Submitting an <b>urgent</b> level support ticket will
                    directly page Apollo engineers.{' '}
                    <b>Please only use this option for emergencies</b>.
                  </ErrorMessage>
                )}

                {priority === highPriority && (
                  <ErrorMessage>
                    Submitting a <b>high</b> level support ticket will directly
                    page Apollo engineers.{' '}
                    <b>Please only use this option judiciously</b>.
                  </ErrorMessage>
                )}

                <div className="flex gap-4">
                  <SelectWithLabel
                    className="flex-1"
                    label={
                      <FormLabel>Which account is this ticket for?</FormLabel>
                    }
                    size="standard"
                    value={
                      issueAccount === USER_ACCOUNT_ISSUE
                        ? USER_ACCOUNT_ISSUE
                        : issueAccount.id
                    }
                    name="issueAccount"
                    id="issueAccount"
                    onChange={(e) => {
                      setIssueAccount(
                        organizations?.find((o) => o.id === e.target.value) ||
                          USER_ACCOUNT_ISSUE,
                      );
                    }}
                  >
                    <optgroup label="User">
                      <option key="no-organization" value={USER_ACCOUNT_ISSUE}>
                        My user account
                      </option>
                    </optgroup>
                    {organizations?.length && (
                      <optgroup label="Organizations">
                        {organizations.map((organization) => (
                          <option key={organization.id} value={organization.id}>
                            {organization.name}
                            <span className="text-label ml-2 font-body !text-inherit">
                              {planNameMapper(
                                organization.currentPlan?.name,
                                organization.currentPlan?.tier,
                                organization.currentPlan?.kind,
                              )}
                            </span>
                          </option>
                        ))}
                      </optgroup>
                    )}
                  </SelectWithLabel>

                  {issueAccount !== USER_ACCOUNT_ISSUE &&
                    (issueAccount.currentPlan?.kind ===
                      BillingPlanKind.ENTERPRISE_PAID ||
                      issueAccount.currentPlan?.kind ===
                        BillingPlanKind.ENTERPRISE_INTERNAL) && (
                      <SelectWithLabel
                        className="flex-1"
                        label={<FormLabel>Priority of this ticket?</FormLabel>}
                        value={priority}
                        onChange={(e) => {
                          setPriority(e.target.value as TicketPriority);
                        }}
                        renderTriggerNode={(selectedItem) =>
                          priorities[selectedItem?.value as TicketPriority]
                            .label ?? 'select a priority'
                        }
                        truncate={false}
                      >
                        {Object.values(TicketPriority)
                          .filter((priorityOption) => {
                            return priorityOption !== TicketPriority.P0;
                          })
                          .map((priorityOption) => (
                            <option
                              key={priorityOption}
                              value={priorityOption}
                              style={{
                                height: 'auto',
                              }}
                            >
                              <div>{priorities[priorityOption].label}</div>
                              <div className="opacity-50">
                                {priorities[priorityOption].description}
                              </div>
                            </option>
                          ))}
                      </SelectWithLabel>
                    )}
                </div>

                {issueAccount !== USER_ACCOUNT_ISSUE && (
                  <SelectWithLabel
                    label={<FormLabel>What is this issue about?</FormLabel>}
                    name="issueType"
                    id="issueType"
                    value={
                      issueAbout
                        ? `${issueAbout.type} / ${issueAbout.category}`
                        : 'Organization / Subscription & Billing'
                    }
                    onChange={(e) => {
                      const [type, category] = e.target.value.split(' / ');
                      if (type === 'Graph') {
                        // TODO: non null assertion added while migrating to typescript 5.0.4, we should remove it
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        setIssueAbout({ type, category: category! });
                      } else if (
                        type === 'Organization' &&
                        (category === 'Subscription & Billing' ||
                          category === 'Membership Management' ||
                          category === 'Other')
                      ) {
                        setIssueAbout({ type, category });
                      }
                    }}
                  >
                    <optgroup label={`${issueAccount.name} organization`}>
                      {[
                        'Subscription & Billing',
                        'Membership Management',
                        'Other',
                      ].map((opt: string) => (
                        <option key={opt} value={`Organization / ${opt}`}>
                          {opt}
                        </option>
                      ))}
                    </optgroup>
                    {isMinLengthArray(1, issueAccount.services) && (
                      <optgroup label="One of my graphs">
                        {issueAccount.services.map((graph) => (
                          <option key={graph.id} value={`Graph / ${graph.id}`}>
                            {graph.title}
                          </option>
                        ))}
                      </optgroup>
                    )}
                  </SelectWithLabel>
                )}
                <FormControl isRequired isInvalid={!!descriptionError}>
                  <FormLabel>Please describe your issue</FormLabel>
                  <Textarea
                    name="description"
                    size="lg"
                    value={description}
                    onFocus={() => setDescriptionError(undefined)}
                    onChange={(event) => setDescription(event.target.value)}
                    onBlur={() => {
                      if (!description) {
                        setDescriptionError(DESCRIPTION_REQUIRED_MESSAGE);
                      }
                    }}
                  />
                  <FormErrorMessage>{descriptionError}</FormErrorMessage>
                </FormControl>

                {shouldShowEmail && (
                  <FormControl isInvalid={!!emailError}>
                    <FormLabel>Email address</FormLabel>
                    <Input
                      name="email_address"
                      type="email"
                      value={email}
                      onFocus={() => setEmailError(undefined)}
                      onChange={(event) => setEmail(event.target.value)}
                      onBlur={() => {
                        if (!yupEmailChecker.isValidSync(email)) {
                          setEmailError(EMAIL_INVALID_MESSAGE);
                        }
                      }}
                    />
                    <FormErrorMessage>{emailError}</FormErrorMessage>
                  </FormControl>
                )}

                {shouldShowAdditionalCCs && (
                  <FormControl isInvalid={!!additionalCCsError}>
                    <FormLabel>CCs</FormLabel>
                    <Input
                      name="email_ccs"
                      type="text"
                      value={additionalCCs}
                      onFocus={() => setAdditionalCCsError(undefined)}
                      onChange={(event) => setAdditionalCCs(event.target.value)}
                      onBlur={() => {
                        if (
                          !yupCCsChecker.isValidSync(splitEmails(additionalCCs))
                        ) {
                          setAdditionalCCsError(CCS_INVALID_MESSAGE);
                        }
                      }}
                    />
                    <FormHelperText>
                      Add as many as you’d like, just separate them with commas
                    </FormHelperText>
                    <FormErrorMessage>{additionalCCsError}</FormErrorMessage>
                  </FormControl>
                )}

                {email && (
                  <div className="text-secondary">
                    We will follow up with you via email at{' '}
                    <span className="font-semibold text-black">{email}</span>.
                  </div>
                )}

                {(!shouldShowEmail || !shouldShowAdditionalCCs) && (
                  <div>
                    {!shouldShowEmail && (
                      <Button
                        variant="hidden"
                        onClick={() => setShouldShowEmail(true)}
                        size="sm"
                        leftIcon={<IconEdit />}
                      >
                        Change email
                      </Button>
                    )}

                    {!shouldShowAdditionalCCs && (
                      <Button
                        variant="hidden"
                        onClick={() => setShouldShowAdditionalCCs(true)}
                        size="sm"
                        leftIcon={<IconPlus />}
                      >
                        Add a CC email
                      </Button>
                    )}
                  </div>
                )}
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                variant="secondary"
                type="button"
                onClick={closeModal}
                isDisabled={isSubmittingJSM}
              >
                Cancel
              </Button>
              <Tooltip
                placement="top"
                label="Please describe your issue"
                disabled={description.length > 0}
              >
                <Button
                  variant="primary"
                  type="submit"
                  isLoading={isSubmittingJSM}
                  loadingText="Submit"
                  isDisabled={
                    !description.length ||
                    isSubmittingJSM ||
                    loading ||
                    !data ||
                    !me
                  }
                >
                  Submit
                </Button>
              </Tooltip>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Form>
    </Modal>
  );
};

export { SupportRequest };
