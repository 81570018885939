import { Link } from '@apollo/orbit';
import React from 'react';

import { PricingRouter } from 'src/lib/routers/pricing';

export const PricingLink = ({
  pathKey = 'Home',
  className,
  children = 'Pricing Page',
  onClick,
}: {
  pathKey?: Parameters<typeof PricingRouter.path>[0];
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}) => (
  <Link
    isExternal
    to={PricingRouter.path(pathKey)}
    className={className}
    onClick={onClick}
  >
    {children}
  </Link>
);
