import { useMutation } from '@apollo/client';
import { Button, ButtonProps, useToast } from '@apollo/orbit';
import { captureException } from '@sentry/react';
import React, { useEffect } from 'react';

import { pushMarketoLeadMutation } from 'src/app/onboarding/hooks/useMarketo';
import { getTrackingCookies } from 'src/lib/analytics';
import { ampli } from 'src/lib/analytics/amplitude/vendor';
import { GraphQLTypes } from 'src/lib/graphqlTypes';

type Props = Pick<ButtonProps, 'variant' | 'children' | 'size'> & {
  onSuccess?: () => void;
  accountId: string;
  internalOrgId: string;
  userId: string;
  userEmail: string;
  userFullName: string;
};

/**
 * Instantly pushes lead to marketo & shows modal on success
 * @returns
 */
export const MarketoUpgradeButton = ({
  variant,
  accountId,
  children = 'Contact us to upgrade',
  size,
  onSuccess,
  userId,
  userEmail,
  userFullName,
  internalOrgId,
}: Props) => {
  // Send user and account information to Marketo when they press the 'Contact Us' button
  const [pushMarketoLead, { loading, error }] = useMutation<
    GraphQLTypes.PushMarketoLead,
    GraphQLTypes.PushMarketoLeadVariables
  >(pushMarketoLeadMutation);
  const toast = useToast();

  useEffect(() => {
    if (error) {
      captureException(error);

      toast({
        status: 'error',
        description:
          'Something went wrong with your request. Please try again.',
      });
    }
  }, [error, toast]);

  const handleClick = async () => {
    const { referrerCookie, mediumCookie, campaignCookie, sourceCookie } =
      getTrackingCookies();
    const [firstName, ...otherNames] = userFullName?.split(' ') || '';
    // Note: a last name must be passed in order for Marketo to sync with Salesforce.
    // We split the full name into a first name and other names.
    // The last name is derived by joining all remaining parts of the name.
    // If there's no last name, it will be an empty string.
    const lastName = otherNames.join(' ');

    // Amplitude tracking event
    ampli.contactSalesRequestSubmitted({
      'Org Id': internalOrgId,
      Email: userEmail ?? '',
    });

    await pushMarketoLead({
      variables: {
        programId: '3160',
        input: {
          email: userEmail,
          firstName,
          lastName,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Studio_User_Id__c: userId,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Studio_Organization_ID__c: accountId,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Referrer__c: referrerCookie,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          UTM_Source__c: sourceCookie,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          UTM_Campaign__c: campaignCookie,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          UTM_Medium__c: mediumCookie,
        },
      },
    })
      .then((response) => {
        if (!!response.data && !response.errors) {
          onSuccess?.();
        }
      })
      .catch((err) => {
        captureException(err);
      });
  };

  return (
    <Button
      type="button"
      variant={variant}
      size={size}
      isLoading={loading}
      loadingText="Submitting request"
      onClick={handleClick}
    >
      {children}
    </Button>
  );
};
