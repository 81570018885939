import { anatomy } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  cssVar,
  defineStyle,
} from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';
import { AdmonitionBodyStyles } from '../AdmonitionBody/AdmonitionBody.style';
import { AdmonitionFooterStyles } from '../AdmonitionFooter/AdmonitionFooter.style';
import { AdmonitionHeaderStyles } from '../AdmonitionHeader/AdmonitionHeader.style';

const $borderColor = cssVar('border-color');

const admonitionAnatomy = anatomy('admonition').parts(
  'container',
  'body',
  'footer',
  'header',
  'icon',
);

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(admonitionAnatomy.keys);

const body = AdmonitionBodyStyles;
const footer = AdmonitionFooterStyles;
const header = AdmonitionHeaderStyles;
const icon = defineStyle({
  height: '1rem',
  width: '1rem',
  color: customColors.icon.primary,
  flex: 'none',
});
const container = defineStyle({
  display: 'flex',
  py: 1,
  pl: 5,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  borderLeft: '4px solid',
  borderColor: $borderColor.reference,
});

const baseStyle = definePartsStyle({
  container,
  body,
  footer,
  header,
  icon,
});

const neutral = definePartsStyle({
  container: {
    [$borderColor.variable]: customColors.border.neutral,
  },
});
const info = definePartsStyle({
  container: {
    [$borderColor.variable]: customColors.border.info,
  },
});
const success = definePartsStyle({
  container: {
    [$borderColor.variable]: customColors.border.success,
  },
});
const warning = definePartsStyle({
  container: {
    [$borderColor.variable]: customColors.border.warning,
  },
});
const error = definePartsStyle({
  container: {
    [$borderColor.variable]: customColors.border.error,
  },
});
const beta = definePartsStyle({
  container: {
    [$borderColor.variable]: customColors.border.beta,
  },
});

export const AdmonitionStyles = defineMultiStyleConfig({
  baseStyle,
  variants: {
    neutral,
    info,
    success,
    warning,
    error,
    beta,
  },
  defaultProps: {
    variant: 'neutral',
  },
});
