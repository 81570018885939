import { find } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';

import { getLastFromMinLengthArray } from 'src/lib/getLastFromMinLengthArray';
import { Timestamp } from 'src/lib/graphqlTypes/customScalarTypes';
import { isMinLengthArray } from 'src/lib/isMinLengthArray';

function formatNumber(n: number | string | null | undefined, fmt = '0') {
  const isNumber = (num: number | string | null | undefined) =>
    (typeof num === 'number' || typeof num === 'string') &&
    !isNaN(Number(num)) &&
    isFinite(Number(num));
  return isNumber(n) ? numeral(n).format(fmt) : '-';
}

function formatMs(
  ms: number | null | undefined,
  d?: number,
  allowMicros = false,
  allowNanos = true,
) {
  if (ms === 0 || typeof ms !== 'number') return '0';
  const bounds: MinLengthArray<1, number> = [
    moment.duration(1, 'hour').asMilliseconds(),
    moment.duration(1, 'minute').asMilliseconds(),
    moment.duration(1, 'second').asMilliseconds(),
    1,
    0.001,
    0.000001,
  ];
  const units = ['hr', 'min', 's', 'ms', 'μs', 'ns'];

  const makeSmallNumbersNice = (f: number) => {
    if (f >= 100) return f.toFixed(0);
    if (f >= 10) return f.toFixed(1);
    if (f === 0) return '0';
    return f.toFixed(2);
  };

  const bound =
    find(bounds, (b) => b <= ms) || getLastFromMinLengthArray(bounds);
  const boundIndex = bounds.indexOf(bound);
  const unit = boundIndex >= 0 ? units[boundIndex] : '';

  if ((unit === 'μs' || unit === 'ns') && !allowMicros) {
    return '< 1ms';
  }
  if (unit === 'ns' && !allowNanos) {
    return '< 1µs';
  }
  const value =
    typeof d !== 'undefined'
      ? (ms / bound).toFixed(d)
      : makeSmallNumbersNice(ms / bound);

  // if something is rounded to 1000 and not reduced this will catch and reduce it
  if ((value === '1000' || value === '1000.0') && boundIndex >= 1) {
    return `1${units[boundIndex - 1]}`;
  }

  return `${value}${unit}`;
}

function splitUnits(formattedNumber: string) {
  const parts = /(\d*\.?\d+)([a-z]+|%)/.exec(formattedNumber);
  return parts && isMinLengthArray(3, parts)
    ? {
        value: Number(parts[1]),
        unit:
          parts[2].includes('rpm') && parts[2].length > 3
            ? parts[2].replace('rpm', ' rpm')
            : parts[2],
      }
    : {
        value: formattedNumber,
        unit: '',
      };
}

export const format = {
  dateToReal(str: Timestamp['output']) {
    let d = new Date(str).getTime();
    if (typeof str === 'number') return d;
    const precision = /\.(\d{3})(\d+)Z$/.exec(str); // add μ
    if (isMinLengthArray(3, precision)) {
      d += Number(precision[2]) * Math.pow(10, -precision[2].length);
    }
    return d;
  },
  splitUnits,
  ms: formatMs,
  percent(d: number | null | undefined) {
    if (typeof d === 'number' && d > 0 && d < 0.0001) {
      return '< 0.01%';
    }
    return format.number(d, '0[.]00%');
  },
  /*
   * Ideally we'd like to show RPS throughout the app. Sometimes this unit doesn't make sense though
   * if the RPS is < .001. In that case, we show just the number of requests.
   */
  requests(rpm: number | null | undefined) {
    if (typeof rpm === 'number' && rpm > 1) return formatNumber(rpm, '0[.]0a');
    return formatNumber(rpm, '0[.]000a');
  },
  number: formatNumber,
  suffix(i: number) {
    const j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) return `${i}st`;
    if (j === 2 && k !== 12) return `${i}nd`;
    if (j === 3 && k !== 13) return `${i}rd`;
    return `${i}th`;
  },
  metric(count: number | null | undefined, fmt: string) {
    const { value, unit } = splitUnits(formatNumber(count, fmt));
    return `${value}${unit}`;
  },
  querySignature(signature?: string | null) {
    return signature && signature.length
      ? signature
      : '[query signature unavailable]';
  },
  pluralize(
    number: number | undefined,
    rootString: string,
    pluralization = 's',
  ) {
    return number === 1 ? rootString : `${rootString}${pluralization}`;
  },
  shortQueryId(id: string | null) {
    return id?.slice(0, 4);
  },
  bytes(bytes: number) {
    if (bytes < 1024) {
      return `${bytes}B`;
    }
    if (bytes < 1024 * 1024) {
      return `${(bytes / 1024).toFixed(1)}KB`;
    }
    if (bytes < 1024 * 1024 * 1024) {
      return `${(bytes / 1024 / 1024).toFixed(1)}MB`;
    }
    return `${(bytes / 1024 / 1024 / 1024).toFixed(1)}GB`;
  },
  duration(duration: moment.Duration) {
    // Currently `hours` are the highest unit, so make sure it contains the remainder of the duration.
    const hours = Math.floor(duration.as('hours'));
    const minutes = duration.get('minutes');
    const seconds = duration.get('seconds');

    return [
      hours > 0 ? `${hours} ${format.pluralize(hours, 'hour')}` : null,
      minutes > 0 ? `${minutes} ${format.pluralize(minutes, 'minute')}` : null,
      seconds > 0 || duration.as('minute') < 1
        ? `${seconds} ${format.pluralize(seconds, 'second')}`
        : null,
    ]
      .filter(Boolean)
      .join(' ');
  },
};
