import { Card, CardBody, Heading } from '@apollo/orbit';
import { Restricted } from '@apollo/space-kit/illustrations/Restricted';
import React from 'react';

import { useGraphVisibility } from '../hooks/useGraphVisibility';

export const NonPublicGraphArea = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const graphVisibilityType = useGraphVisibility();

  if (graphVisibilityType === 'public') {
    return (
      <Card className="m-8 pb-24 pt-12">
        <CardBody className="items-center text-center">
          <Restricted className="mb-6" />
          <Heading as="h1" size="3xl">
            Not available
          </Heading>
          <div className="w-120 text-lg leading-relaxed">
            This feature is not available on Public Variants
          </div>
        </CardBody>
      </Card>
    );
  }
  return children;
};
