import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';

import { logoutRouteConfig } from 'src/app/onboarding/routes';
import { Loading } from 'src/components/common/loading/Loading';
import { clearLocalStorage } from 'src/hooks/useLocalStorage';
import { useRouteParams } from 'src/hooks/useRouteParams';
import Config from 'src/lib/config';
import { BackendRouter } from 'src/lib/routers';

export const LogoutPage = () => {
  const { callbackUrl = Config.absoluteUrl } =
    useRouteParams(logoutRouteConfig);

  useEffect(() => {
    clearLocalStorage();
    Sentry.configureScope((scope) => scope.clear());
    datadogRum.clearUser();
    datadogRum.clearAccount();

    BackendRouter.go('Logout', { callbackUrl });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loading />;
};
