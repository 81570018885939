import { ApolloError } from '@apollo/client';
import { GraphQLFormattedError } from 'graphql';

export const SSO_ERROR_MESSAGE =
  'Enterprise SSO users cannot join additional organizations.';

export const INVALID_TOKEN_ERROR_MESSAGE =
  'Looks like the invite link you’re using is invalid. Reach out to the sender and ask for a new invite.';

export const DEFAULT_MAX_SEAT_MET_ERROR_MESSAGE =
  'This organization has reached its maximum number of permitted seats. Contact your Org Admin(s) to upgrade.';

export const EXPIRED_LINK_MESSAGE = `Looks like the invite link you’re using has expired. Reach out to the sender and ask for a new invite.`;

/**
 * Check error code to see if it matches expected SSO join error code
 *
 * @param error GraphQLFormattedError
 * @returns boolean
 */
export function isSSOJoinError(error: GraphQLFormattedError): boolean {
  return error.extensions?.code === 'SSO_USERS_CANNOT_JOIN_ORGANIZATIONS';
}

/**
 * Check error code to see if it matches expected max seat met error code
 *
 * @param error GraphQLFormattedError
 * @returns boolean
 */
export function isMaxGraphOSMetError(error: GraphQLFormattedError): boolean {
  return error.extensions?.code === 'MAX_GRAPHOS_SEAT_COUNT_MET';
}

/**
 * Check error message to see if includes "invalid token" (is case insensitive)
 *
 * @param error GraphQLFormattedError
 * @returns boolean
 */
export function isInvalidTokenError(error: GraphQLFormattedError): boolean {
  return error.message.toLowerCase().includes('invalid token');
}

/**
 * Check error message to see if includes "expired" (is case insensitive)
 *
 * @param error GraphQLFormattedError
 * @returns boolean
 */
export function isExpiredInviteError(error: GraphQLFormattedError): boolean {
  return error.message.toLowerCase().includes('expired');
}

/**
 * Takes an ApolloError and searches the graphQLErrors to see if specific error messages are included.
 * Returns an array of strings for all special cases or an array including the error's base message when
 * no special cases are returned.
 *
 * @param error ApolloError
 * @returns string[]
 */
export function inviteErrorMapper(error: ApolloError): string[] {
  const errorMessages: string[] = [];

  // Check to see if an SSO error was returned
  if (error.graphQLErrors.some(isSSOJoinError))
    errorMessages.push(SSO_ERROR_MESSAGE);

  // Check to see if a max graphOS seat error was returned
  // Use the message returned from the BE with the error or default message if not set
  if (error.graphQLErrors.some(isMaxGraphOSMetError)) {
    const graphQLError: GraphQLFormattedError | undefined =
      error.graphQLErrors.find(isMaxGraphOSMetError);
    errorMessages.push(
      graphQLError?.message || DEFAULT_MAX_SEAT_MET_ERROR_MESSAGE,
    );
  }

  // Check if invalid token is included in error message or nested graphQLErrors
  if (
    isInvalidTokenError(error) ||
    error.graphQLErrors.some(isInvalidTokenError)
  )
    errorMessages.push(INVALID_TOKEN_ERROR_MESSAGE);

  // Check if expired is included in error message or nested graphQLErrors
  if (
    isExpiredInviteError(error) ||
    error.graphQLErrors.some(isExpiredInviteError)
  )
    errorMessages.push(EXPIRED_LINK_MESSAGE);

  return errorMessages.length
    ? errorMessages.map((message) =>
        // Make sure we strip the BE exception messaging
        message?.replace(/Exception while fetching data \(.*?\)[:\s]*/i, ''),
      )
    : [error.message];
}
